import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation'
import Card from 'react-bootstrap/Card';
import MonsterGenerator from "./MonsterGenerator";
import Nav from 'react-bootstrap/Nav';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { JsxElement } from 'typescript';
import {ConvertResults, DEFAULT_RESULT_ARRAY, ResultComponent} from "../utility/resultcomponents/ResultComponent";
import TemplateGenerator from "../utility/TemplateGenerator";
import {Meta} from "../utility/MetaWrapper";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";
import {Historical} from "../simple/HistoryGenerator";
import ItemDisplay, {DEFAULT_ITEM} from "./ItemDisplay";
import axios from "axios";

function ItemGenerator() {
    const [itemNames, setItemNames] = useState(['']);
    const [selectedItemOptions, setSelectedItemOptions] = useState(['Random']);
    const [selectedItem, setSelectedItem] = useState('Random');
    const [generatedItem, setGeneratedItem] = useState(DEFAULT_ITEM);

    useEffect(() => {
        UpdateSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemOptions]);

    function GetSelectedItem() {
        return selectedItemOptions[Math.floor(Math.random() * selectedItemOptions.length)];
    }

    function UpdateSelected() {
        setSelectedItem(GetSelectedItem()!);
    }

    function UpdateItemList() {
        fetch(URL+"/GetItemTypes",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let items: Array<string> = data;

            setItemNames(items);

        });
    }

    function GenerateItem(data: any) {
        setGeneratedItem(data.data);
    }

    function SaveItemToProfile(){

        const saveTemplate = async () => {
            const response = await axios.put(URL + '/SaveItem', { item: generatedItem }, {withCredentials: true }).catch((e) => {
                console.log(e);
            });
        }

        saveTemplate().catch((e) => {console.log(e);});
    }

    useEffect(() => {
        UpdateItemList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div>
            <Meta pageName="Item Generator" desc="A TTRPG Item Generator that generates randomized objects and item for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/itemgenerator" />
            <TemplateGenerator onGenerateButtonClicked={UpdateSelected} name="Item" url="GetItem" customSaveData={SaveItemToProfile} additionalUrl={"&item=" + selectedItem} customDataHandling={GenerateItem} componentReplacement={
                <ItemDisplay item={generatedItem} />
            } settingsComponent={
                <div>
                    <h4>Item Type</h4>
                    <AdvancedDropdown options={ConvertForDropdown(itemNames)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedItemOptions} />
                </div>
            } />
        </div>);
}

export default ItemGenerator;
