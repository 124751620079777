import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation'
import Card from 'react-bootstrap/Card';
import MonsterGenerator from "../complex/MonsterGenerator";
import Nav from 'react-bootstrap/Nav';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { JsxElement } from 'typescript';
import {ConvertResults, DEFAULT_RESULT_ARRAY} from "../utility/resultcomponents/ResultComponent";
import TemplateGenerator from "../utility/TemplateGenerator";
import {Meta} from "../utility/MetaWrapper";

function DesireGenerator() {

    return(
        <div>
            <Meta pageName="Desire Generator" desc="A TTRPG Desire Generator that generates a player or NPCs desire for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/desiregenerator" />
            <TemplateGenerator name="Desire" url="GetDesire" multiple />
        </div>);
}

export default DesireGenerator;
