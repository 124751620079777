import React, {useRef} from 'react';
import { Button } from 'react-bootstrap';
import URL from './BackendLocation';
import {Helmet} from "react-helmet";
import {Meta} from "./dnd/utility/MetaWrapper";
import Changelog from "./Changelog";

function Home() {
    return(
        <div>
            <Meta pageName="" desc="Free Online TTRPG Tools!" address="https://7tools.dev" />
            <h1>Welcome to 7Tools!</h1>
            <p>There are at least more than 7 tools on this website. All of them are TTRPG related. Check them out or whatever.</p>
            <p>Lots of tools here. Kinda. Feel free to use them, link them around. Credit is appreciated, not required. You can find all sorts of different tools on the left side there. Select an option to drop down your options.</p>
            <p>Made by Cory Koseck, also known as 7ark</p>
            <h4>Recent Changes</h4>
            <Changelog onlyShow={3} hideTitle={true} />
        </div>);
}

export default Home;
