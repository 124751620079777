import Table from "react-bootstrap/Table";
import React from "react";

export interface StatBlockScores {
    Stat_Strength: number;
    Stat_Dexterity: number;
    Stat_Constitution: number;
    Stat_Intelligence: number;
    Stat_Wisdom: number;
    Stat_Charisma: number;
}

function GetModifer(stat: number): number {

    if (stat % 2 === 1) {
        stat -= 1;
    }

    return -5 + (stat / 2);
};

export function GetModifierTextDisplay(stat: number): string {
    let mod: number = GetModifer(stat);

    let result = '';

    if (mod >= 0) {
        result += '+';
    }

    result += mod;

    return result;
}

export function DisplayStatblock(props: { Stats: StatBlockScores }) {
    return (
        <Table size="sm">
            <thead>
            <tr>
                <th>STR</th>
                <th>DEX</th>
                <th>CON</th>
                <th>INT</th>
                <th>WIS</th>
                <th>CHA</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{props.Stats.Stat_Strength} (<span data-attr="STR">{GetModifierTextDisplay(props.Stats.Stat_Strength)}</span>)</td>
                <td>{props.Stats.Stat_Dexterity} (<span data-attr="DEX">{GetModifierTextDisplay(props.Stats.Stat_Dexterity)}</span>)</td>
                <td>{props.Stats.Stat_Constitution} (<span data-attr="CON">{GetModifierTextDisplay(props.Stats.Stat_Constitution)}</span>)</td>
                <td>{props.Stats.Stat_Intelligence} (<span data-attr="INT">{GetModifierTextDisplay(props.Stats.Stat_Intelligence)}</span>)</td>
                <td>{props.Stats.Stat_Wisdom} (<span data-attr="WIS">{GetModifierTextDisplay(props.Stats.Stat_Wisdom)}</span>)</td>
                <td>{props.Stats.Stat_Charisma} (<span data-attr="CHA">{GetModifierTextDisplay(props.Stats.Stat_Charisma)}</span>)</td>
            </tr>
            </tbody>
        </Table>);
}