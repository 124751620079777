import React, {useEffect, useRef, useState} from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation'
import {Meta} from "../utility/MetaWrapper";
import {Col, Container, Row} from "react-bootstrap";
import {ScreenSizeRatio, useScreenSizes} from "../../hooks/loginHook";

function FantasyLanguageTranslator() {
    const [languageOptions, setLanguageOptions] = useState(["Select Language"]);
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState('');
    const [inputLanguage, setInputLanguage] = useState('english');
    const [outputLanguage, setOutputLanguage] = useState('');
    const timeoutRef = useRef<number | null>(null);
    const screenSizeRatio = useScreenSizes();

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputText(event.target.value);
    };

    const handleOutputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setOutputText(event.target.value);
    };

    const handleInputLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setInputLanguage(event.target.value);
    };

    const handleOutputLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setOutputLanguage(event.target.value);
    };

    const handleSwapButtonClick = () => {
        if(outputLanguage !== "") {
            setInputLanguage(outputLanguage);
            setOutputLanguage(inputLanguage);
            setInputText(outputText);
        }
    };

    function UpdateLanguageList() {
        fetch(URL+"/GetTranslationLanguages",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let languages: Array<string> = data;

            setLanguageOptions(languages);
        });
    }

    useEffect(() => {
        UpdateLanguageList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function StartUpdatingText() {
        setOutputText("...")
        if (timeoutRef.current != null) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = window.setTimeout(() => {
            // Call function here
            UpdateText();
            timeoutRef.current = null;
        }, 500);
    }

    useEffect(() => {
        StartUpdatingText();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputText, outputLanguage, inputLanguage]);

    function UpdateText() {
        if(outputLanguage === "") {
            setOutputText("");
        }
        else {
            let isEnglish = (inputLanguage !== "english");
            if(inputText.trim() !== "") {
                fetch(URL + "/Translate?language=" + (isEnglish ? inputLanguage : outputLanguage) + "&text=" + inputText + "&english=" + isEnglish,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        credentials: 'include'
                    })
                    .then(res => res.json()).then((data) => {
                    setOutputText(data.data);
                });
            }
            else {
                setOutputText("");
            }
        }
    }

    let languages = languageOptions.map(lang => <option value={lang}>{lang}</option>);

    return(
        <div>
            <Meta pageName="Fantasy Language Translator" desc="A TTRPG Fantasy Language Translator that translates from english to other fantasy languages used for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/fantasylanguagetranslator" />
            <h1>Fantasy Language Translator</h1>

            {
                screenSizeRatio === ScreenSizeRatio.Full
                    ?
                    <>
                        <Container>
                            <Row>
                                <Col>
                                    <select className="translateDropdown" value={inputLanguage} onChange={handleInputLanguageChange}>
                                        <option value="english">English</option>
                                        {languages}
                                    </select>
                                    <br />
                                    <br />
                                    <textarea
                                        maxLength={1800}
                                        className="form-control"
                                        value={inputText}
                                        onChangeCapture={handleInputChange}
                                        rows={7}
                                        style={{
                                            fontSize: "24px"
                                        }}
                                    />
                                </Col>
                                <Col xs={1}  style={{display: 'flex'}} className="align-items-start justify-content-center">
                                    <Button variant="secondary" onClick={handleSwapButtonClick} className="mx-auto">
                                        <svg width={20} height={25} viewBox="0 0 512 512"><path d="M32 96l320 0V32c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l96 96c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-96 96c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V160L32 160c-17.7 0-32-14.3-32-32s14.3-32 32-32zM480 352c17.7 0 32 14.3 32 32s-14.3 32-32 32H160v64c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-96-96c-6-6-9.4-14.1-9.4-22.6s3.4-16.6 9.4-22.6l96-96c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 64H480z"/></svg>
                                    </Button>
                                </Col>
                                <Col>
                                    <select disabled={inputLanguage !== "english"} className="translateDropdown"
                                            value={inputLanguage !== "english" ? "english" : outputLanguage}
                                            onChange={handleOutputLanguageChange}>
                                        <option value={""}>Select a Language</option>
                                        {languages}
                                        <option value={"english"}>English</option>
                                    </select>
                                    <br />
                                    <br />
                                    <textarea
                                        disabled
                                        className="form-control"
                                        value={outputText}
                                        onChange={handleOutputChange}
                                        rows={7}
                                        style={{
                                            fontSize: "24px"
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <br />
                    </>
                :
                <>
                    <Container>
                        <Row>
                            <Col>
                                <select className="translateDropdownMobile" value={inputLanguage} onChange={handleInputLanguageChange}>
                                    <option value="english">English</option>
                                    {languages}
                                </select>
                            </Col>
                            <Col xs={1}  style={{display: 'flex'}} className="align-items-start justify-content-center">
                                <Button variant="secondary" onClick={handleSwapButtonClick} className="mx-auto">
                                    <svg width={20} height={25} viewBox="0 0 512 512"><path d="M32 96l320 0V32c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l96 96c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-96 96c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V160L32 160c-17.7 0-32-14.3-32-32s14.3-32 32-32zM480 352c17.7 0 32 14.3 32 32s-14.3 32-32 32H160v64c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-96-96c-6-6-9.4-14.1-9.4-22.6s3.4-16.6 9.4-22.6l96-96c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 64H480z"/></svg>
                                </Button>
                            </Col>
                            <Col>
                                <select disabled={inputLanguage !== "english"} className="translateDropdownMobile"
                                        value={inputLanguage !== "english" ? "english" : outputLanguage}
                                        onChange={handleOutputLanguageChange}>
                                    <option value={""}>Select a Language</option>
                                    {languages}
                                    <option value={"english"}>English</option>
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <br />
                                <textarea
                                    maxLength={1800}
                                    className="form-control"
                                    value={inputText}
                                    onChangeCapture={handleInputChange}
                                    rows={4}
                                    style={{
                                        fontSize: "24px"
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <br />
                                <textarea
                                    disabled
                                    className="form-control"
                                    value={outputText}
                                    onChange={handleOutputChange}
                                    rows={4}
                                    style={{
                                        fontSize: "24px"
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <br />
                </>
            }
        </div>
    );
}

export default FantasyLanguageTranslator;
