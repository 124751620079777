import React, {useEffect} from "react";
import {DisplayStatblock, StatBlockScores} from "../utility/StatBlock"
import AppearanceDisplay, {Appearance, DEFAULT_APPEARANCE} from "./AppearanceDisplay";
import {ResultComponent} from "../../../../dndnode_ass/OptionBuilderPieces/OptionBuilderTools";
import {ConvertResults, ConvertResultsDisplay} from "../utility/resultcomponents/ResultComponent";
import {CopyNPCToLegendKeeper, LegendKeeperButton} from "../utility/LegendKeeperCopy";
import HistoryDisplay, {Historical} from "../simple/HistoryGenerator";
import {GetObject, GrabObject, ReferenceObject, ReferenceType} from "../utility/architecture/ReferenceIds";
import {BuildingReferenceType} from "../../../../dndnode_ass/complex_generators/buildinggenerator";
import {GroupReferenceType} from "../../../../dndnode_ass/complex_generators/organizationgenerator";
import {Col, Container, Row} from "react-bootstrap";

export type NPCReferenceType = string;

export interface FamilyData {
    Parents: Array<Array<ResultComponent>>;
    Siblings: Array<Array<ResultComponent>>;
    Children: Array<Array<ResultComponent>>;
}

export interface NPC extends Historical, ReferenceObject {
    Name: string;
    NamePronunciation: string;
    RaceName: string;
    Gender: string;

    Size: string;
    Type: string;
    Alignment: string;

    Family: FamilyData;

    Languages: string;
    Job: Array<ResultComponent>;
    JobType: number;
    EmployedAt?: BuildingReferenceType;
    Residence?: BuildingReferenceType;

    MemberOf?: GroupReferenceType;

    Stats: StatBlockScores;

    Personality: Array<ResultComponent>;
    Voice: Array<ResultComponent>;
    Goal: Array<ResultComponent>;
    Secret: Array<ResultComponent>;
    Fear: Array<ResultComponent>;
    Flaw: Array<ResultComponent>;
    Rumor: Array<ResultComponent>;
    Desire: Array<ResultComponent>;

    VisualAppearance: Appearance;
}

export const DEFAULT_NPC_REF: NPCReferenceType = 'blank';

export const DEFAULT_NPC: NPC = {
    ReferenceData: {Set: true},
    Name: '',
    NamePronunciation: '',
    RaceName: '',
    Gender: '',

    Size: '',
    Type: '',
    Alignment: '',

    Family: {
        Parents: [],
        Siblings: [],
        Children: []
    },

    Languages: '',

    Stats: {
        Stat_Strength: 10,
        Stat_Dexterity: 10,
        Stat_Constitution: 10,
        Stat_Intelligence: 10,
        Stat_Wisdom: 10,
        Stat_Charisma: 10
    },

    Job: [],
    JobType: 0,

    Personality: [],
    Voice: [],
    Goal: [],
    Secret: [],
    Fear: [],
    Flaw: [],
    Rumor: [],
    Desire: [],

    VisualAppearance: DEFAULT_APPEARANCE,
    History: []
};

function NPCDisplay(props: {currentNPC: NPCReferenceType}) {
    const [, updateState] = React.useState();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const currentNPC = GetObject(ReferenceType.NPC, props.currentNPC) as NPC;

    useEffect(() => {
        if(props.currentNPC !== 'blank' && !currentNPC.ReferenceData.Set) {
            GrabObject(ReferenceType.NPC, props.currentNPC, forceUpdate);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let personalityDisplay = currentNPC.Personality === undefined ? <></> : ConvertResults(currentNPC.Personality);
    let voiceDisplay = ConvertResults(currentNPC.Voice);
    let goalDisplay = ConvertResults(currentNPC.Goal);
    let secretDisplay = ConvertResults(currentNPC.Secret);
    let fearDisplay = ConvertResults(currentNPC.Fear);
    let rumorDisplay = currentNPC.Rumor === undefined ? <></> : ConvertResults(currentNPC.Rumor);
    let flawDisplay = currentNPC.Flaw === undefined ? <></> : ConvertResults(currentNPC.Flaw);
    let jobDisplay = currentNPC.Job === undefined ? <></> : ConvertResults(currentNPC.Job);
    let desireDisplay = currentNPC.Desire === undefined ? <></> : ConvertResults(currentNPC.Desire);
    let employedAtLocationDisplay = ConvertResults([{
        text: currentNPC.EmployedAt === undefined ? "lol" : GetObject(ReferenceType.Building, currentNPC.EmployedAt).locationName[0]!.text,
        afterText: "", beforeText: "", children: [], type: currentNPC.EmployedAt!,
    }]);
    let residenceDisplay = ConvertResults([{
        text: currentNPC.Residence === undefined ? "lol" : GetObject(ReferenceType.Building, currentNPC.Residence).locationName[0]!.text,
        afterText: "", beforeText: "", children: [], type: currentNPC.Residence!,
    }]);

    //buildingEmployedAt!.locationName[0]!.text!
    let memberOf = ConvertResults([{
        text: currentNPC.MemberOf === undefined ? "lol" : GetObject(ReferenceType.Group, currentNPC.MemberOf).OrgName[0]!.text,
        afterText: "", beforeText: "", children: [], type: currentNPC.MemberOf!,
    }]);
    let memberLeader = currentNPC.MemberOf === undefined ? false : GetObject(ReferenceType.Group, currentNPC.MemberOf).Leader === props.currentNPC;
    let parentsDisplay = currentNPC.Family === undefined ? <></> : currentNPC.Family.Parents.map(parent => {

        return <><ConvertResultsDisplay data={parent} />, </>;
    });

    return(<div>
            <h1>{currentNPC.Name}</h1>
        {
            currentNPC.NamePronunciation !== undefined ?
                <h6 style={{
                    marginTop: '-7px',
                    color: 'grey'
                }}>({currentNPC.NamePronunciation})</h6>
                :
                <></>
        }
            <p>{currentNPC.Size} {currentNPC.Type} ({currentNPC.RaceName}), {currentNPC.Alignment}</p>
            <hr />
            <DisplayStatblock Stats={currentNPC.Stats} />
        {
            currentNPC.Personality === undefined ?
                <></>
                :
                <p><b>Personality</b> {personalityDisplay}</p>
        }
        <Container>
            <Row>
                <Col>
                    <p><b>Languages</b> {currentNPC.Languages}</p>
                    {
                        currentNPC.Residence === undefined ?
                            <></>
                            :
                            <p><b>Residence:</b> {residenceDisplay}</p>
                    }
                    {
                        currentNPC.Job === undefined ?
                            <></>
                            :
                            <p><b>Job</b> {jobDisplay}{
                                currentNPC.EmployedAt !== undefined ?
                                    <>, at {employedAtLocationDisplay}</> : <></>
                            }</p>
                    }
                    {
                        currentNPC.MemberOf === undefined ?
                            <></>
                            :
                            <p><b>Group</b> {memberLeader ? <>Leader</> : <>Member</>} of {memberOf}</p>
                    }
                    {
                        currentNPC.Family === undefined ?
                            <></>
                            :
                            <p><b>Family</b><br /><b>Parents: </b> {parentsDisplay}</p>
                    }
                </Col>
                <Col>
                    {
                        currentNPC.Desire === undefined ?
                            <></>
                            :
                            <p><b>Desire</b> {desireDisplay}</p>
                    }
                    {
                        currentNPC.Flaw === undefined ?
                            <></>
                            :
                            <p><b>Flaw</b> {flawDisplay}</p>
                    }
                    <p><b>Fear</b> {fearDisplay}</p>
                    <p><b>Secret</b> {secretDisplay}</p>
                    <p><b>Goal</b> {goalDisplay}</p>
                </Col>
            </Row>
        </Container>
        {
            currentNPC.Rumor === undefined ?
                <></>
                :
                <p><b>Related Rumor</b> {rumorDisplay}</p>
        }

            <h3>Appearance</h3>
        <AppearanceDisplay currentAppearance={currentNPC.VisualAppearance} race={currentNPC.RaceName} />
        <h3>Voice</h3>
        {voiceDisplay}
        <hr />
        <HistoryDisplay history={currentNPC} />
        <LegendKeeperButton activate={() => { CopyNPCToLegendKeeper(currentNPC); }} />
        </div>);
}

export default NPCDisplay;
