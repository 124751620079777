import React, {useContext, useEffect, useState} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import {PopupContext} from "../utility/TemplateGenerator";
import {ConvertResults, ConvertResultsDisplay} from "../utility/resultcomponents/ResultComponent";
import {NPCReferenceType} from "./NPCDisplay";
import {ResultComponent} from "../../../../dndnode_ass/OptionBuilderPieces/OptionBuilderTools";
import {GetObject, ReferenceObject, ReferenceType} from "../utility/architecture/ReferenceIds";
import {Col, Container, Row} from "react-bootstrap";
import {
    Bell,
    Bookmark,
    Box, Bucket, Bug, Clock, Clouds, CoinStack, Compass,
    Corners, Diamond, Dice1, Dice2, Dice3, Dice4, Dice5, Dice6,
    DownArrow, Droplet, Ear, Egg, Eye, Fan, FilledCircle, Fingerprint, Fire,
    Gear,
    Heart, HeartOutline, Hexagon, House, Hurricane, Lightning, Lungs, Moon, Mug,
    Paintbrush, Palette, Person, Pill,
    RisingSun, Scroll,
    Shield,
    Star,
    Sun, Target, Triangle, Umbrella, Wrench, YinYang
} from "../utility/SvgData";

export enum OrganizationType {
    CauseBased, //Stopping crime, doing crime, etc
    NobleHouse,
    Religious, //cult, or just like temple
    Pirates,
    Guild,
    Militaristic, //guards, mercenaries
    Political, //Govts?
    AdventuringParty
}
export enum OrgCause {
    GainingPower, //stealing, collecting wealth, government
    Ideals, //give drugs, healing, music, change opinions, give food
    Destruction,
    PreserveEnvironment,
}

export enum GuildType {
    Merchants,
    Thieves,
    Fighters,
    Mages,
    Bards,
    Adventurers,
    Alchemists,
    Smiths,
    Brewers,
    Scribes,
    Carpenters,
    Cartographers,
    Cobblers,
    Cooks,
    Glassblowers,
    Jewelers,
    Leatherworkers,
    Masons,
    Painters,
    Potters,
    Shipwrights,
    Tinkers,
    Weavers,
    Couriers,
}

export enum AlignmentMorals { good, neutral, evil }

enum SigilOutlineType { Shield, Gear, Circle, Triangle, Heart, Scroll }
enum SigilSymbolType { Star, Heart, Corners , Arrow, Bell,
Bookmark, Box, Sun, RisingSun, Paintbrush,
Bucket, Bug, Target, Pill, Circle,
Clock, Clouds, Compass, Mug, CoinStack, Diamond,
Dice1, Dice2, Dice3, Dice4, Dice5, Dice6,
Droplet, Ear, Egg, Eye, Fan, Fingerprint,
Fire, Hexagon, House, Hurricane, Lightning, Lungs,
Moon, Palette, Person, Umbrella, Wrench, YinYang
}
export interface GroupSVG {
    Outline: SigilOutlineType,
    OutlineColor: string,
    Symbol: SigilSymbolType,
    SymbolColor: string,
}

export interface Group extends ReferenceObject
{
    Type: string,
    Alignment: string,

    OrgName: Array<ResultComponent>;
    Leader: NPCReferenceType;
    Members: Array<NPCReferenceType>;

    Goals: Array<Array<ResultComponent>>;
    Secrets: Array<Array<ResultComponent>>;

    Conflicts: Array<ResultComponent>;
    Ideals: Array<ResultComponent>;
    Rumor: Array<ResultComponent>;

    GroupKnown: Array<ResultComponent>;
    MembersKnown: Array<ResultComponent>;
    //Hierarchy??

    Location: Array<ResultComponent>;

    TypeValue: OrganizationType;
    AlignValue: AlignmentMorals;
    Cause: OrgCause;
    GuildType: GuildType;
    SVG: GroupSVG;
}

export const DEFAULT_GROUP_REF = 'blank';

export const DEFAULT_GROUP: Group = {
    ReferenceData: {Set:true},
    Type: "",
    Alignment: "",

    OrgName: [],
    Leader: 'blank',
    Members: [],

    Goals: [],
    Secrets: [],

    Conflicts: [],
    Ideals: [],
    Rumor: [],

    GroupKnown: [],
    MembersKnown: [],

    Location: [],

    TypeValue: 0,
    AlignValue: 0,
    Cause: 0,
    GuildType: 0,
    SVG: {
        Outline: 0,
        OutlineColor: "",
        Symbol: 0,
        SymbolColor: "",
    }
}

function OrganizationDisplay(props: {
    org: string;
    popup: boolean;
    setColorSettingDefault?: (color: string) => void;
    colorSettingDefault?: string;
}) {
    const [pngSet, setPng] = useState(false);
    const [sigilColorSetting, setSigilColorSetting] = useState(props.colorSettingDefault === undefined ? 'Colorful' : props.colorSettingDefault);

    let colorSchemeSimple = sigilColorSetting === "Simple";
    let mapNodeId = props.popup ? "sigil_node" : "sigil_node_popup";
    let pngHolderId = props.popup ? "sigil_png" : "sigil_png_popup";

    let org = GetObject(ReferenceType.Group, props.org) as Group;
    const displayTypes = useContext(PopupContext);

    function ShowNPC(npcId: NPCReferenceType) {
        let types = [...displayTypes!.types!];
        types.push({
            type: npcId,
            data: null
        });
        displayTypes!.setTypes!(types);
    }

    let nameDisplay = ConvertResults(org.OrgName);
    let locationDisplay = ConvertResults(org.Location);
    let idealsDisplay = ConvertResults(org.Ideals);
    let rumorDisplay = org.Rumor === undefined ? <></> : ConvertResults(org.Rumor);
    let conflictDisplay = org.Conflicts === undefined ? <></> : ConvertResults(org.Conflicts);
    let goalsDisplay = org.Goals.map(goal => {
        return <>
            <li><ConvertResultsDisplay data={goal} /></li>
        </>
    });
    let secretsDisplay = org.Secrets.map(secret => {
        return <>
            <li><ConvertResultsDisplay data={secret} /></li>
        </>
    });

    let groupKnownDisplay = org.GroupKnown === undefined ? <></> : ConvertResults(org.GroupKnown);
    let membersKnownDisplay = org.MembersKnown === undefined ? <></> : ConvertResults(org.MembersKnown);

    if(org.Members === undefined) {
        org.Members = [];
    }

    let membersDisplay = org.Members.map(member => {
        return <>
            <li> <ConvertResultsDisplay data={[{
                text: GetObject(ReferenceType.NPC,  member).Name,
                afterText: "", beforeText: "", children: [], type: member,
            }]} /> </li>
        </>
    });

    let outline: SigilOutlineType = org.SVG.Outline;
    let symbol: SigilSymbolType = org.SVG.Symbol;

    function SigilOutline() {
        let fillColor = colorSchemeSimple ? "none" : org.SVG.OutlineColor;

        switch (outline) {
            case SigilOutlineType.Shield:
                return Shield(0, 0, 300, fillColor);
            case SigilOutlineType.Gear:
                return Gear(0, 0, 300, fillColor);
            case SigilOutlineType.Circle:
                return <circle cx="150" cy="150" r="140" stroke="black" stroke-width="10" fill={fillColor} />;
            case SigilOutlineType.Triangle:
                return Triangle(0, 0, 300, fillColor)
            case SigilOutlineType.Heart:
                return HeartOutline(0, 0, 300, fillColor)
            case SigilOutlineType.Scroll:
                return Scroll(0, 0, 300, fillColor)
        }

        return <></>;
    }

    function SigilSymbol() {
        let x = 0;
        let y = 0;
        let size = 0;
        let fillColor = colorSchemeSimple ? "black" : org.SVG.SymbolColor;
        switch (outline) {
            case SigilOutlineType.Shield:
                x = 50;
                y = 30;
                size = 200;
                break;
            case SigilOutlineType.Gear:
                x = 75;
                y = 67;
                size = 150;
                break;
            case SigilOutlineType.Circle:
                x = 0;
                y = -10;
                size = 300;
                break;
            case SigilOutlineType.Triangle:
                x = 87.5;
                y = 115;
                size = 125;
                break;
            case SigilOutlineType.Heart:
                x = 75;
                y = 75;
                size = 150;
                break;
            case SigilOutlineType.Scroll:
                x = 85;
                y = 35;
                size = 150;
                break;
        }

        switch (symbol) {
            case SigilSymbolType.Umbrella:
                return Umbrella(x, y, size, fillColor);
            case SigilSymbolType.Wrench:
                return Wrench(x, y, size, fillColor);
            case SigilSymbolType.YinYang:
                return YinYang(x, y, size, fillColor);
            case SigilSymbolType.Moon:
                return Moon(x, y, size, fillColor);
            case SigilSymbolType.Palette:
                return Palette(x, y, size, fillColor);
            case SigilSymbolType.Person:
                return Person(x, y, size, fillColor);
            case SigilSymbolType.Hurricane:
                return Hurricane(x, y, size, fillColor);
            case SigilSymbolType.Lightning:
                return Lightning(x, y, size, fillColor);
            case SigilSymbolType.Lungs:
                return Lungs(x, y, size, fillColor);
            case SigilSymbolType.Fire:
                return Fire(x, y, size, fillColor);
            case SigilSymbolType.Hexagon:
                return Hexagon(x, y, size, fillColor);
            case SigilSymbolType.House:
                return House(x, y, size, fillColor);
            case SigilSymbolType.Eye:
                return Eye(x, y, size, fillColor);
            case SigilSymbolType.Fan:
                return Fan(x, y, size, fillColor);
            case SigilSymbolType.Fingerprint:
                return Fingerprint(x, y, size, fillColor);
            case SigilSymbolType.Droplet:
                return Droplet(x, y, size, fillColor);
            case SigilSymbolType.Ear:
                return Ear(x, y, size, fillColor);
            case SigilSymbolType.Egg:
                return Egg(x, y, size, fillColor);
            case SigilSymbolType.Mug:
                return Mug(x, y, size, fillColor);
            case SigilSymbolType.CoinStack:
                return CoinStack(x, y, size, fillColor);
            case SigilSymbolType.Diamond:
                return Diamond(x, y, size, fillColor);
            case SigilSymbolType.Dice1:
                return Dice1(x, y, size, fillColor);
            case SigilSymbolType.Dice2:
                return Dice2(x, y, size, fillColor);
            case SigilSymbolType.Dice3:
                return Dice3(x, y, size, fillColor);
            case SigilSymbolType.Dice4:
                return Dice4(x, y, size, fillColor);
            case SigilSymbolType.Dice5:
                return Dice5(x, y, size, fillColor);
            case SigilSymbolType.Dice6:
                return Dice6(x, y, size, fillColor);
            case SigilSymbolType.Clock:
                return Clock(x, y, size, fillColor);
            case SigilSymbolType.Clouds:
                return Clouds(x, y, size, fillColor);
            case SigilSymbolType.Compass:
                return Compass(x, y, size, fillColor);
            case SigilSymbolType.Target:
                return Target(x, y, size, fillColor);
            case SigilSymbolType.Pill:
                return Pill(x, y, size, fillColor);
            case SigilSymbolType.Circle:
                return FilledCircle(x, y, size, fillColor);
            case SigilSymbolType.Paintbrush:
                return Paintbrush(x, y, size, fillColor);
            case SigilSymbolType.Bucket:
                return Bucket(x, y, size, fillColor);
            case SigilSymbolType.Bug:
                return Bug(x, y, size, fillColor);
            case SigilSymbolType.Sun:
                return Sun(x, y, size, fillColor);
            case SigilSymbolType.RisingSun:
                return RisingSun(x, y, size, fillColor);
            case SigilSymbolType.Box:
                return Box(x, y, size, fillColor);
            case SigilSymbolType.Bookmark:
                return Bookmark(x, y, size, fillColor);
            case SigilSymbolType.Bell:
                return Bell(x, y, size, fillColor);
            case SigilSymbolType.Arrow:
                return DownArrow(x, y, size, fillColor);
            case SigilSymbolType.Heart:
                return Heart(x, y, size, fillColor);
            case SigilSymbolType.Star:
                return Star(x, y, size, fillColor);
            case SigilSymbolType.Corners:
                return Corners(x, y, size, fillColor);
        }


        return <></>;
    }


    const destroyChildren = ($element: any) => {
        while ($element.firstChild) {
            const $lastChild = $element.lastChild ?? false
            if ($lastChild) $element.removeChild($lastChild)
        }
    }

    function RefreshImage() {
        const map = document.getElementById(mapNodeId)!;

        if(map == null){
            return;
        }

        const $svg = map.querySelector('svg')!
        const $holder = document.getElementById(pngHolderId)!

        const loadImage = async (url: string) => {
            const $img = document.createElement('img')
            $img.src = url
            return new Promise((resolve, reject) => {
                $img.onload = () => resolve($img)
                $img.onerror = reject
            })
        }
        const convertSVGtoImg = async () => {
            // const $btn = e.target
            const format = 'png'


            const svgAsXML = (new XMLSerializer()).serializeToString($svg)
            const svgData = `data:image/svg+xml,${encodeURIComponent(svgAsXML)}`

            const img: any = await loadImage(svgData)

            const $canvas = document.createElement('canvas')
            $canvas.width = $svg.clientWidth
            $canvas.height = $svg.clientHeight
            $canvas.getContext('2d')!.drawImage(img, 0, 0, $svg.clientWidth, $svg.clientHeight)

            const dataURL: string = await $canvas.toDataURL(`image/${format}`, 1.0);

            if(dataURL === "data:,") {
                return;
            }
            else {
                setPng(true)
                // setShouldShowSvg(false);
            }
            destroyChildren($holder)

            const $img = document.createElement('img')
            $img.src = dataURL
            $holder.appendChild($img)
        }


        const timer = setTimeout(() => {
            (async () => {
                await convertSVGtoImg();
            })();

            // setShouldShowSvg(false);
        }, 500);

        return () => {

        };
    }

    useEffect(() => {
        if(!pngSet) {
            RefreshImage();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pngSet]);

    function SetSigilColorText(selected: React.ChangeEvent<HTMLSelectElement>) {
        setSigilColorSetting(selected.target.value);
        const $holder = document.getElementById(pngHolderId)!
        destroyChildren($holder)
        setPng(false);

        if(props.setColorSettingDefault !== undefined) {
            props.setColorSettingDefault(selected.target.value)
        }
    }

    return(
        <div>
            <h2>{nameDisplay}</h2>
            <p>{org.Type} | {org.Alignment}</p>

            <Container>
                <Row>
                    <Col>
                        <p><b>Ideals:</b>{' '}{idealsDisplay}</p>
                        <p><b>Leader:</b>{' '}
                            <a onClick={() => {
                                ShowNPC(org.Leader);
                            }} className="fakelink">{GetObject(ReferenceType.NPC,  org.Leader) === undefined ? "" : GetObject(ReferenceType.NPC,  org.Leader)!.Name}</a >
                        </p>
                        <p><b>Location:</b>{' '}{locationDisplay}</p>
                        <p>{groupKnownDisplay}</p>
                        <p>{membersKnownDisplay}</p>
                    </Col>
                    <Col>
                        <div id={pngHolderId}>
                        </div>
                        {
                            !pngSet ?
                                <div id={mapNodeId}>
                                    {
                                        org.SVG !== undefined ?
                                            <svg height="300" width="300" viewBox="0 0 300 300" >
                                                <SigilOutline />

                                                <SigilSymbol />
                                            </svg>
                                            :
                                            <></>
                                    }
                                </div>
                                :
                                <></>
                        }
                        <p><b>Sigil Colors</b>
                            {' '}
                        <select defaultValue={props.colorSettingDefault} onChange={SetSigilColorText}>
                            <option>Simple</option>
                            <option>Colorful</option>
                        </select></p>
                    </Col>
                </Row>
            </Container>
            {
                org.Rumor === undefined ?
                    <></>
                    :
                    <p><b>Related Rumor:</b>{' '}{rumorDisplay}</p>
            }
            {
                org.Conflicts === undefined ?
                    <></>
                    :
                    <p><b>Group Conflict:</b>{' '}{conflictDisplay}</p>
            }

            <Container>
                <Row>
                    <Col>
                        <h3>Goals</h3>
                        {goalsDisplay}
                    </Col>
                    <Col>
                        <h3>Secrets</h3>
                        {secretsDisplay}
                    </Col>
                </Row>
            </Container>
            <h2>Notable Members</h2>
            {membersDisplay}
        </div>);
}

export default OrganizationDisplay;
