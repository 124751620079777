import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation'
import Card from 'react-bootstrap/Card';
import MonsterGenerator from "../complex/MonsterGenerator";
import Nav from 'react-bootstrap/Nav';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { JsxElement } from 'typescript';
import {ConvertResults, DEFAULT_RESULT_ARRAY} from "../utility/resultcomponents/ResultComponent";
import TemplateGenerator from "../utility/TemplateGenerator";
import {Meta} from "../utility/MetaWrapper";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";

function WeatherGenerator() {
    const [environmentTypes, setEnvironmentTypes] = useState(['']);
    const [selectedEnvironmentOptions, setSelectedEnvironmentOptions] = useState(['Random']);

    function UpdateEnvironmentList() {
        fetch(URL+"/GetEnvironmentTypes",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let items: Array<string> = data;

            setEnvironmentTypes(items);

        });
    }

    useEffect(() => {
        UpdateEnvironmentList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div>
            <Meta pageName="Weather Generator" desc="A TTRPG Weather Generator to generate weather and natural disasters for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/weathergenerator" />
            <TemplateGenerator name="Weather" url="GetWeather" multiple additionalUrl={"&environments=" + selectedEnvironmentOptions} extraDataForResultsDisplay={selectedEnvironmentOptions.toString()} settingsComponent={
                <div>
                    <h4>Environment Type</h4>
                    <AdvancedDropdown options={ConvertForDropdown(environmentTypes)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedEnvironmentOptions} />
                </div>
            } />
        </div>);
}

export default WeatherGenerator;
