import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import TemplateGenerator from "../utility/TemplateGenerator";
import {Meta} from "../utility/MetaWrapper";

function HistoricalEventGenerator() {

    return(
        <div>
            <Meta pageName="Historical Event Generator" desc="A TTRPG Historical Event Generator that generates a historical event that can be used for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/historicaleventgenerator" />
            <TemplateGenerator name="Historical Event" url="GetHistoricalEvent"  isResultComponentCollection />
        </div>
    );
}

export default HistoricalEventGenerator;
