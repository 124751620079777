import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation';
import {Meta} from "../utility/MetaWrapper";
import TemplateGenerator from "../utility/TemplateGenerator";
import {
    ConvertResultsDisplay,
    DEFAULT_RESULT_ARRAY,
    ResultComponent
} from "../utility/resultcomponents/ResultComponent";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";

function NameGenerator() {
    const [nameData, setNameData] = useState<Array<{ nameResult: Array<ResultComponent>, race: string, pronunciation: string}>>([{
        nameResult: [],
        race: "",
        pronunciation: ""
    }]);

    const [raceNames, setRaceNames] = useState(['']);
    const [selectedRacesOptions, setSelectedRacesOptions] = useState(['Random']);
    const [selectedRace, setSelectedRace] = useState('Random');
    const [gender, setGender] = useState('Any');
    const [count, setCount] = useState(1);

    function GetSelectedRace() {
        return selectedRacesOptions[Math.floor(Math.random() * selectedRacesOptions.length)]
    }

    function UpdateSelected() {
        setSelectedRace(GetSelectedRace()!);
    }

    useEffect(() => {
        UpdateSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRacesOptions]);

    function updateRaceList() {
        fetch(URL+"/Races",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

                let names: Array<string> = data;

                setRaceNames(names);

            });
    }


    function ChangeName(data: any) {
        setNameData(data.data);
    }

    function setGenderInput(selected: React.ChangeEvent<HTMLSelectElement>) {
        setGender(selected.target.value);
    }

    useEffect(() => {
        updateRaceList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let nameSaveDisplay: Array<ResultComponent> = [];
    nameData.forEach(data => nameSaveDisplay = nameSaveDisplay.concat(data.nameResult).concat({
        text: ", ",
        beforeText: "",
        afterText: "",
        children: [],
        type: "text"
    }));

    let namesDisplay = nameData.map((data) => {
        return <li><b><ConvertResultsDisplay data={data.nameResult} /></b> ({data.pronunciation}) - Race: {data.race}</li>
    });

    return(
        <div>
            <Meta pageName="Name Generator" desc="A TTRPG Name Generator that generates names that can be used by players or NPCs used for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/namegenerator" />
            <TemplateGenerator onGenerateButtonClicked={UpdateSelected} hideAdjectivesOption={true} multiple name="Name" url="GetName" customSaveDisplay={nameSaveDisplay} additionalUrl={"&race=" + selectedRace + "&gender=" + gender} customDataHandling={ChangeName} settingsComponent={
                <div>
                    <h4>Race</h4>
                    <AdvancedDropdown options={ConvertForDropdown(raceNames)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedRacesOptions} />
                    <h4>Gender</h4>
                    <select onChange={setGenderInput}>
                        <option value="Any">Any</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                </div>
            } extraComponent={
                nameData.length === 1 ?
                    <p>Race: {nameData[0]!.race}</p> : undefined
            } componentReplacement={
                nameData.length === 1 ?
                <>
                    <h5><ConvertResultsDisplay data={nameData[0]!.nameResult} /></h5>
                    <h6 style={{ color: 'grey' }}>({nameData[0]!.pronunciation})</h6>
                </> :
                    <ol>
                        {namesDisplay}
                    </ol>
            }
            />
        </div>);
}

export default NameGenerator;
