import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation';
import {Meta} from "../utility/MetaWrapper";
import TemplateGenerator from "../utility/TemplateGenerator";
import {ConvertResultsDisplay, StringToResultComponent} from "../utility/resultcomponents/ResultComponent";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";

function BuildingNameGenerator() {

    const [name, setName] = useState('bob');
    const [locationNames, setLocationNames] = useState(['']);
    const [generatedLocationType, setGeneratedLocationType] = useState('Random');
    const [selectedLocation, setSelectedLocation] = useState('Random');
    const [selectedLocationOptions, setSelectedLocationOptions] = useState(['Random']);

    useEffect(() => {
        UpdateSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLocationOptions]);

    function GetSelectedLocation() {
        return selectedLocationOptions[Math.floor(Math.random() * selectedLocationOptions.length)];
    }

    function UpdateSelected() {
        setSelectedLocation(GetSelectedLocation()!);
    }

    function updateLocationList() {
        fetch(URL+"/GetLocations",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

                let locations: Array<string> = data;

                setLocationNames(locations);

            });
    }


    function ChangeName(data: any) {
        setName(data.location);
        setGeneratedLocationType(data.locationTypeName);
    }

    useEffect(() => {
        updateLocationList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div>
            <Meta pageName="Location Name Generator" desc="A TTRPG Location Name Generator to generate potential names for any location for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/locationnamegenerator" />
            <TemplateGenerator onGenerateButtonClicked={UpdateSelected} hideAdjectivesOption={true} name="Location Name" url="GetLocationName" customSaveDisplay={StringToResultComponent(name)} additionalUrl={"&location=" + selectedLocation} customDataHandling={ChangeName} settingsComponent={
                <div>
                    <h4>Location Type</h4>
                    <AdvancedDropdown options={ConvertForDropdown(locationNames)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedLocationOptions} />
                </div>
            } extraComponent={
                <p>Location Type: {generatedLocationType}</p>
            } componentReplacement={
                <p>{name}</p>
            }
            />
        </div>);
}

export default BuildingNameGenerator;
