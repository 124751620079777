import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";

export function ConvertForDropdown(stringArray: Array<string>): Array<{value: string, displayText: string, defaultValue: boolean}> {
    return stringArray.map(string => { return {value: string, displayText: string, defaultValue: true}});
}

function AdvancedDropdown(props: {
    defaultSelectedValue?: string,
    randomOption?:{value: string, displayText: string},
    options: Array<{value: string, displayText: string, defaultValue: boolean}>,
    forceSetOptions?:Array<string>,
    onChangeValue: (value: Array<string>) => void,
    lockOpen?: boolean,
    disabled?: boolean,
    defaultOpensAsAdvanced?: boolean
}) {
    const [displayAsBasic, setDisplayAsBasic] = useState(true);
    const [currentChecked, setCurrentChecked] = useState<Map<string, boolean>>(new Map<string, boolean>());


    function CheckAll() {
        let tempVal = new Map<string, boolean>();
        currentChecked.forEach((key, val) => tempVal.set(val, true));

        setCurrentChecked(tempVal);

        let enabledList: Array<string> = [];
        tempVal.forEach((key, val) => {
            if(key) {
                enabledList.push(val);
            }
        });

        props.onChangeValue(enabledList)
    }

    function UnCheckAll() {
        let tempVal = new Map<string, boolean>();
        currentChecked.forEach((key, val) => tempVal.set(val, false));

        setCurrentChecked(tempVal);

        let enabledList: Array<string> = [];
        tempVal.forEach((key, val) => {
            if(key) {
                enabledList.push(val);
            }
        });

        props.onChangeValue(enabledList)
    }

    useEffect(() => {

        let checkedVals = new Map<string, boolean>();

        for(let i = 0; i < props.options.length; i++) {
            let checked = props.options[i]!.defaultValue;
            if(props.forceSetOptions !== undefined) {
                checked = props.forceSetOptions.includes(props.options[i]!.value);
            }
            checkedVals.set(props.options[i]!.value, checked);
        }


        if(currentChecked.size < checkedVals.size || props.forceSetOptions !== undefined) {
            setCurrentChecked(checkedVals);
        }

        if(props.defaultOpensAsAdvanced) {
            setDisplayAsBasic(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.options]);

    useEffect(() => {

        if(!displayAsBasic) {
            props.onChangeValue(SelectFromAdvanced()!);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentChecked]);

    useEffect(() => {

        if(!displayAsBasic) {
            props.onChangeValue(SelectFromAdvanced()!);
        }
        else {
            if(props.defaultSelectedValue !== undefined) {
                props.onChangeValue([props.defaultSelectedValue]);
            }
            else {
                props.onChangeValue([props.options[0]!.value]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayAsBasic]);

    function SelectFromAdvanced() {
        let listOfViable: Array<string> = [];

        currentChecked.forEach((checked, val) =>
        {
           if(checked) {
               listOfViable.push(val);
           }
        });

        if(listOfViable.length === 0) {
            return ["Random"];
        }

        return listOfViable;
    }


    const optionList = props.options.map(option =>
        {
            if(props.defaultSelectedValue !== undefined && props.defaultSelectedValue === option.value) {
                return <option key={option.value} value={option.value}>{option.displayText}</option>;
            }
            else {
                return <option key={option.value} value={option.value}>{option.displayText}</option>;
            }
        }
    )

    const advancedOptionList = props.options.map(option =>
    {
        let isChecked = currentChecked.get(option.value);
        if(props.forceSetOptions !== undefined) {
            isChecked = props.forceSetOptions.includes(option.value);
        }

        return <>
            {option.displayText}
            <input disabled={props.disabled}
                   className="checkbox"
                   name={option.value}
                   type="checkbox"
                   checked={isChecked}
                   onChange={changeCheckboxSelected}
            />
            <br />
        </>
    }
    );

    function setValue(selected: React.ChangeEvent<HTMLSelectElement>) {
        props.onChangeValue([selected.target.value])
    }

    function changeCheckboxSelected(selected: React.ChangeEvent<HTMLInputElement>) {
        let tempVal = new Map<string, boolean>();
        currentChecked.forEach((key, val) => tempVal.set(val, key));
        tempVal.set(selected.target.name, !tempVal.get(selected.target.name)!);

        setCurrentChecked(tempVal);

        let enabledList: Array<string> = [];
        tempVal.forEach((key, val) => {
            if(key) {
                enabledList.push(val);
            }
        });

        props.onChangeValue(enabledList)
    }

    return (
        <>
            {
                displayAsBasic && !props.lockOpen ?
                    <>
                        <select disabled={props.disabled} onChange={setValue}>
                            {
                                props.randomOption !== undefined ?
                                    <>
                                    {
                                        props.defaultSelectedValue === props.randomOption.value ?
                                            <option key={props.randomOption.value} value={props.randomOption.value}>{props.randomOption.displayText}</option>
                                            :
                                            <option key={props.randomOption.value} value={props.randomOption.value}>{props.randomOption.displayText}</option>
                                    }
                                    </>
                                    :
                                    <></>
                            }
                            {optionList}
                        </select>
                        {'   '}
                        <Button disabled={props.disabled} onClick={() => { setDisplayAsBasic(false); }} size="sm" >+</Button>
                    </>
                    :
                    <>
                        {props.lockOpen ? <></> : <h5> Advanced{'   '}<Button onClick={() => { setDisplayAsBasic(true); }} size="sm" >-</Button></h5>}
                        {advancedOptionList}
                        <Button disabled={props.disabled} onClick={CheckAll}>Select All</Button>{'  '}<Button disabled={props.disabled} onClick={UnCheckAll}>Deselect All</Button>
                        <br /><br />
                    </>
            }
        </>
    );
}

export default AdvancedDropdown;
