import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation';
import Modal from 'react-bootstrap/Modal'
import {useIsLoggedIn} from "../../hooks/loginHook";
import axios from "axios";
import MonsterDisplay, {DEFAULT_MONSTER, Monster} from "./MonsterDisplay";
import NPCDisplay, {DEFAULT_NPC, DEFAULT_NPC_REF, NPC, NPCReferenceType} from "./NPCDisplay";
import {Meta} from "../utility/MetaWrapper";
import TemplateGenerator from "../utility/TemplateGenerator";
import {ConvertResultsDisplay} from "../utility/resultcomponents/ResultComponent";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";

function NPCGenerator() {

    const [, updateState] = React.useState();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [currentNPC, setCurrentNPC] = useState(DEFAULT_NPC_REF);
    const [raceGenerated, setRaceGenerated] = useState('race');
    const [gender, setGender] = useState('Any');

    function GenerateNPC(data: any) {
        setRaceGenerated(data.race);
        setCurrentNPC(data.npc);
        forceUpdate();
    }

    function setGenderInput(selected: React.ChangeEvent<HTMLSelectElement>) {
        setGender(selected.target.value);
    }

    function SaveNPCToProfile() {
        const saveNPC = async () => {
            const response = await axios.put(URL + '/SaveNPC', { npc: currentNPC }, {withCredentials: true }).catch((e) => {
                console.log(e);
            });
        }

        saveNPC().catch((e) => {console.log(e);});
    }

    return(
        <div>
            <Meta pageName="NPC Generator" desc="A TTRPG NPC Generator that creates a unique person including a statblock, goals, motivations, secrets, fears and more for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/npcgenerator" />
            <TemplateGenerator name="NPC" url="GetNPC" showRaceSetting additionalUrl={"&gender=" + gender} customDataHandling={GenerateNPC} settingsComponent={
                <div>
                    <h4>Gender</h4>
                    <select onChange={setGenderInput}>
                        <option value="Any">Any</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                </div>
            } componentReplacement={
                <NPCDisplay currentNPC={currentNPC} />
            } customSaveData={SaveNPCToProfile}
            />
        </div>);
}

export default NPCGenerator;
