import TemplateGenerator from "./TemplateGenerator";
import React, {useState, useEffect, useRef, useMemo} from 'react';
import * as BS from "react-bootstrap";
import {
    genericRC,
    GetDisplayText, GetObject, ReferenceObject,
    referencePackage,
    ReferenceType,
    useReferencePackage
} from "./architecture/ReferenceIds";
import {ConvertResultsDisplay, ResultComponent} from "./resultcomponents/ResultComponent";
import {NPC, NPCReferenceType} from "../complex/NPCDisplay";
import {Group} from "../complex/OrganizationDisplay";
import {Building, BuildingReferenceType} from "../complex/BuildingDisplay";
import {GroupReferenceType} from "../../../../dndnode_ass/complex_generators/organizationgenerator";
import {LocationReferenceType, Location} from "../complex/LocationDisplay";
import {Settlement} from "../complex/SettlementDisplay";

function WikiDisplay() {
    const [, updateState] = React.useState();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const refPackUpdater = useReferencePackage();
    const types = Object.values(ReferenceType).filter((v) => !isNaN(Number(v)));

    function EvalRC(rc: Array<ResultComponent>): Array<string> {
        let results: Array<string> = [];

        for (let i = 0; i < rc.length; i++) {
            let included = false;

            types.forEach((value, index) => {
                let type = ReferenceType[index]!.toString().toLowerCase() + "-";
                if(rc[i]!.type.includes(type)) {
                    included = true;
                }
            });

            if(included) {
                results.push(rc[i]!.type);
            }
            results = results.concat(EvalRC(rc[i]!.children));
        }

        return results;
    }

    function AddTo(array: Array<string>, rcs: Array<Array<ResultComponent>>) {
        for (let i = 0; i < rcs.length; i++) {
            let results = EvalRC(rcs[i]!);
            for (let j = 0; j < results.length; j++) {
                if(!array.includes(results[j]!)) {
                    array.push(results[j]!);
                }
            }
        }
    }

    let objectsSet: {[key: number]: Array<string>} = {};
    types.forEach((value, index) => {
        objectsSet[index] = referencePackage.References[index]!.Keys.filter(id => {
            let obj = GetObject(index, id) as ReferenceObject;

            return obj.ReferenceData.Set;
        });
    });

    let objectsSeen: {[key: number]: Array<string> } = {};
    types.forEach((value, index) => {
        let indexType: ReferenceType = index;
        let seen: Array<string> = [];

        for (let i = 0; i < genericRC.length; i++) {
            AddTo(seen, [
                genericRC[i]!
            ]);
        }

        for (let i = 0; i < objectsSet[index]!.length; i++) {
            let obj = GetObject(index, objectsSet[index]![i]!);

            switch (indexType) {
                case ReferenceType.NPC:
                    let npc = obj as NPC;
                    if(npc.MemberOf !== undefined) {
                        seen.push(npc.MemberOf);
                    }
                    if(npc.EmployedAt !== undefined){
                        seen.push(npc.EmployedAt);
                    }
                    if(npc.Residence !== undefined){
                        seen.push(npc.Residence);
                    }
                    AddTo(seen, [
                        npc.Job,
                        npc.Fear,
                        npc.Flaw,
                        npc.Goal,
                        npc.Secret,
                        npc.Voice,
                        npc.Rumor,
                        npc.History,
                    ]);
                    break;
                case ReferenceType.Building:
                    let building = obj as Building;
                    seen.push(building.buildingOwner);
                    for (let j = 0; j < building.employees.length; j++) {
                        seen.push(building.employees[j]!);
                    }
                    for (let j = 0; j < building.regularCustomers.length; j++) {
                        seen.push(building.regularCustomers[j]!);
                    }

                    AddTo(seen, [
                        building.knownFor,
                        building.appearance.exterior,
                        building.appearance.interior,
                        building.rumor,
                    ]);
                    break;
                case ReferenceType.Group:
                    let group = obj as Group;
                    seen.push(group.Leader);
                    for (let i = 0; i < group.Members.length; i++) {
                        seen.push(group.Members[i]!);
                    }

                    let groupRcs = [
                        group.Location,
                        group.Ideals,
                        group.GroupKnown,
                        group.MembersKnown,
                        group.Rumor,
                        group.Conflicts
                    ];
                    for (let i = 0; i < group.Goals.length; i++) {
                        groupRcs.push(group.Goals[i]!);
                    }
                    for (let i = 0; i < group.Secrets.length; i++) {
                        groupRcs.push(group.Secrets[i]!);
                    }
                    AddTo(seen, groupRcs);
                    break;
                case ReferenceType.Location:
                    let location = obj as Location;
                    let locationRcs = [
                        location.Name,
                        location.Desc,
                        location.History
                    ];
                    AddTo(seen, locationRcs);
                    break;
                case ReferenceType.Settlement:
                    let settlement = obj as Settlement;
                    let settlementRcs = [
                        settlement.Name,
                        settlement.Leadership,
                        settlement.Exports,
                        settlement.Imports,
                        settlement.Geography,
                        settlement.Popular,
                    ];
                    for (let i = 0; i < settlement.Districts.length; i++) {
                        for (let j = 0; j < settlement.Districts[i]!.Locations.length; j++) {
                            seen.push(settlement.Districts[i]!.Locations[j]!);
                        }
                    }

                    AddTo(seen, settlementRcs);
                    break;

            }
        }

        objectsSeen[index] = seen;
    });

    let sortedObjects: {[key: number]: Array<string>} = {};
    types.forEach((value, index) => {
        sortedObjects[index] = referencePackage.References[index]!.Keys.sort((id1, id2) => {
            let obj1 = GetObject(index, id1) as ReferenceObject;
            let obj2 = GetObject(index, id2) as ReferenceObject;

            if(obj1.ReferenceData.Set && !obj2.ReferenceData.Set) {
                return -1;
            }
            else if(!obj1.ReferenceData.Set && obj2.ReferenceData.Set) {
                return 1;
            }
            else {
                return 0;
            }
        });
    });

    let objectsDisplay: {[key: number]: Array<any>} = {};
    types.forEach((value, index) => {
        objectsDisplay[index] = sortedObjects[index]!.map(id => {
            let obj = GetObject(index, id) as ReferenceObject;
            let includes = false;
            if(obj.ReferenceData.Set) {
                includes = true;
            }
            else {
                types.forEach((value, index) => {
                    if(objectsSeen[index]!.includes(id)) {
                        includes = true;
                    }
                });
            }

            if(!includes) {
                return <></>;
            }

            let linkType = obj.ReferenceData.Set ? "fakelink" : "fakelinkDark";


            return (<dd key={id}> <ConvertResultsDisplay data={[{
                text: GetDisplayText(index, id),
                afterText: '',
                beforeText: '',
                children: [],
                type: id
            }]} linkType={linkType} /> </dd>)
        });
    });

    let finalDisplay = Object.values(objectsDisplay).map((value, index) => {

        let collapsibleId = "wiki-collapsible" + index;
        return (
            <div key={index} className="wrap-collabsible">
                <input id={collapsibleId} className="toggle inputCheckNone" type="checkbox" defaultChecked />
                <label htmlFor={collapsibleId} className="lbl-toggle">{ReferenceType[index]}s</label>
                <div className="collapsible-content">
                    <div className="content-inner">
                        {value}
                        <br />
                    </div>
                </div>
            </div>
        );
    });

    return (
        <dl className="wiki-align">
            {finalDisplay}
        </dl>
                );
}

export default WikiDisplay;
