import React, {useContext, useEffect, useState} from "react";
import {PopupContext} from "../TemplateGenerator";
import {ReferenceType} from "../architecture/ReferenceIds";

export interface ResultComponentCollection {
    Title: Array<ResultComponent>;
    ResultComponent: Array<ResultComponent>;
}

export interface ResultComponent {
    beforeText: string;
    text: string;
    afterText: string;
    type: string;
    children: Array<ResultComponent>;
    optionalData?: any;
}

export const DEFAULT_RESULT_ARRAY: Array<ResultComponent> = [{
    beforeText: '',
    text: '',
    afterText: '',
    type: '',
    children: []
}];

export const DEFAULT_RESULT_COMPONENT_COLLECTION: ResultComponentCollection = {
    Title: [],
    ResultComponent: []
}


export function StringToResultComponent(text: string): Array<ResultComponent> {
    return [{beforeText: "", text: text, afterText: "", type: "text", children: []}];
}

export function ConvertResultsToString(data: Array<ResultComponent>) {

    let results = "";

    function HandleResultComponentParsingString(resultComponent: ResultComponent) {
        results += resultComponent?.beforeText;
        results += resultComponent?.text;

        for(let i = 0; i < resultComponent?.children.length; i++) {
            let childResultComponent = resultComponent?.children[i];

            HandleResultComponentParsingString(childResultComponent!);
        }

        results += resultComponent?.afterText;
    }

    for(let i = 0; i < data.length; i++) {
        let resultComponent = data[i];
        HandleResultComponentParsingString(resultComponent!);
    }

    let final = "";
    let last = "";
    for (let i = 0; i < results.length; i++) {
        if(last !== " " || results[i] !== " ") {
            final += results[i];
        }

        last = results[i]!;
    }

    return final;
}

export function GetObjectsFromResultComponent(refType: ReferenceType, resultComponents: Array<ResultComponent>): Array<string> {
    let resultList: Array<string> = [];

    function Traverse(rc: ResultComponent) {
        let type = rc.type;
        switch (refType) {
            case ReferenceType.NPC:
                if(type.includes("npc")) {
                    resultList.push(type);
                }
                break;
            case ReferenceType.Building:
                if(type.includes("building")) {
                    resultList.push(type);
                }
                break;
            case ReferenceType.Group:
                if(type.includes("group")) {
                    resultList.push(type);
                }
                break;
            case ReferenceType.Location:
                if(type.includes("location")) {
                    resultList.push(type);
                }
                break;
            case ReferenceType.Settlement:
                if(type.includes("settlement")) {
                    resultList.push(type);
                }
                break;
        }

        for (let i = 0; i < rc.children.length; i++) {
            Traverse(rc.children[i]!);
        }
    }

    for (let i = 0; i < resultComponents.length; i++) {
        Traverse(resultComponents[i]!);
    }

    return resultList;
}

export function ResultComponentCollectionDisplay(props: {data: ResultComponentCollection}) {
    let name = ConvertResults(props.data.Title);
    let desc = ConvertResults(props.data.ResultComponent);

    return (
        <div>
            <h2>{name}</h2>
            {desc}
        </div>
    )
}

export function ConvertResultsDisplay(props: {data: Array<ResultComponent>, linkType?: string, extraData?: string}) {
    let val = ConvertResults(props.data, false, props.extraData, props.linkType === undefined ? "fakelink" : props.linkType);

    return (
        <>{val}</>
    );
}

export function ConvertResults(data: Array<ResultComponent>, debugDisplay?: boolean, extraData?: string, linkType = "fakelink") {

    let results: Array<JSX.Element> = [];

    function HandleResultComponentParsing(resultComponent: ResultComponent, last: boolean)
    {
        let typeString: string = resultComponent?.type || '';
        let split: Array<string> = typeString.split('_');
        let typeName: string = split[0] || '';

        const displayTypes = useContext(PopupContext);

        let beforeText = resultComponent?.beforeText;
        let text = resultComponent?.text;
        let afterText = resultComponent?.afterText;

        if(debugDisplay)
        {
            results.push(<>(</>);
        }
        results.push(<>{beforeText}</ >);

        if(typeName.includes('npc-') ||
            typeName.includes('building-') ||
            typeName.includes('group-') ||
            typeName.includes('location-') ||
            typeName.includes('settlement-') ||
            typeName === 'monster' ||
            typeName === 'landmark' ||
            typeName === 'item'
        ) {
            let primaryType: string = split[1] || 'Random';
            let secondaryType: string = split[2] || 'Any';

            let extraData: any = null;
            if(typeName === 'monster') {
                extraData = primaryType;
                if(secondaryType !== "Any") {
                    extraData += "," + secondaryType;
                }
            }
            else {
                extraData = resultComponent.optionalData;
            }

            results.push(
                <a onClick={() => {
                    let types = [...displayTypes!.types!];
                    types.push({
                        type: typeName,
                        data: extraData
                    });
                    displayTypes!.setTypes!(types);
                }} className={linkType}>{text}</a >
            );
        }
        else if(typeName === 'srdmonster') {
            let monsterLink: string = split[1] || '';

            results.push(
                <a target="_blank" href={"https://www.dndbeyond.com/monsters/" + monsterLink} className={linkType}>{text}</a >
            );
        }
        else if(typeName === 'resultcomponentcollection') {
            results.push(
                <a onClick={() => {
                    let types = [...displayTypes!.types!];
                    types.push({
                        type: typeName,
                        data: resultComponent.optionalData
                    });
                    displayTypes!.setTypes!(types);
                }} className={linkType}>{text}</a >
            );
        }
        else {
            if(text?.includes("[NL]")) {
                results.push(<br />);
            }
            else if(text?.includes("[B]")) {
                text = text?.replace("[B]", "");
                results.push(<b>{text}</b >);
            }
            else{
                results.push(<>{text}</ >);
            }
        }

        for (let i = 0; i < resultComponent?.children.length; i++)
        {
            let childResultComponent = resultComponent?.children[i];
            HandleResultComponentParsing(childResultComponent!, last);
        }

        results.push(<>{afterText}</ >);

        if(debugDisplay)
        {
            results.push(<>)</>);
        }
    }

    for(let i = 0; i < data.length; i++) {
        let resultComponent = data[i];
        HandleResultComponentParsing(resultComponent!, i === data.length - 1);
    }

    return results;
}
