import React, {useEffect, useState} from "react";
import {RandomBetweenGeneric} from "./dnd/utility/Utils";

export function LoadingDisplay() {
    const [loadingText, setLoadingText] = useState("");

    useEffect(() => {
        let loadingTextOptions = [
            "Finding a gamemaster",
            "Figuring out a goal for a very stubborn NPC",
            "Stealing an idea from a lich",
            "Counting how many generators this site has",
            "Trying to figure out whose initiative it was",
            "Making sure a group isn't too evil",
            "Generating a better name... the first one was dumb",
            "Creating problems...",
            "Forgot how to code, going back to school",
            "Still working on a spell generator",
            "Thanking my patron(s)",
            "Making the weather mean"
        ];

        setLoadingText(RandomBetweenGeneric(loadingTextOptions));
    }, []);

    return <><img
        src="/logo512.png"
        width="100"
        height="100"
        className="d-inline-block align-top"
        alt=""
    />
        <h2>Loading your content... Please wait!</h2>
        <h3>{loadingText}</h3>
    </>;
}
