import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation';
import AppearanceDisplay, {Appearance, AppearanceToString, DEFAULT_APPEARANCE} from "./AppearanceDisplay";
import {Meta} from "../utility/MetaWrapper";
import TemplateGenerator from "../utility/TemplateGenerator";
import {StringToResultComponent} from "../utility/resultcomponents/ResultComponent";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";



function AppearanceGenerator() {

    const [appearance, setAppearance] = useState<Appearance>(DEFAULT_APPEARANCE);

    const [raceNames, setRaceNames] = useState(['']);
    const [selectedRace, setSelectedRace] = useState('Random');
    const [selectedRaceOptions, setSelectedRaceOptions] = useState(['Random']);
    const [raceGenerated, setRaceGenerated] = useState('race');
    const [gender, setGender] = useState('Any');

    function GetSelectedRace() {
        return selectedRaceOptions[Math.floor(Math.random() * selectedRaceOptions.length)]
    }

    function UpdateSelected() {
        setSelectedRace(GetSelectedRace()!);
    }

    useEffect(() => {
        UpdateSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRaceOptions]);

    function updateRaceList() {
        fetch(URL+"/Races",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

                let names: Array<string> = data;

                setRaceNames(names);

            });
    }


    function GenerateNewAppearance(data: any) {
        setRaceGenerated(data.data.race);
        setAppearance(data.data.appearance);
    }

    function setSelectedRaceInput(selected: React.ChangeEvent<HTMLSelectElement>) {
        setSelectedRace(selected.target.value)
    }

    function setGenderInput(selected: React.ChangeEvent<HTMLSelectElement>) {
        setGender(selected.target.value);
    }

    useEffect(() => {
        updateRaceList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const raceList = raceNames.map(name =>
        <option key={name} value={name}>{name}</option>
        )

    return(
        <div>
            <Meta pageName="Appearance Generator" desc="A TTRPG Appearance Generator that generates a random appearance for a variety of chosen races or heritages for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/appearancegenerator" />
            <TemplateGenerator onGenerateButtonClicked={UpdateSelected} hideAdjectivesOption={true} name="Appearance" url="GetAppearance" additionalUrl={"&race=" + selectedRace} customDataHandling={GenerateNewAppearance} settingsComponent={
                <div>
                    <h4>Race</h4>
                    <AdvancedDropdown options={ConvertForDropdown(raceNames)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedRaceOptions} />
                </div>
            } extraComponent={
                <p>Race: {raceGenerated}</p>
            } componentReplacement={
                <AppearanceDisplay currentAppearance={appearance} race={raceGenerated} />
            } customSaveDisplay={ StringToResultComponent(AppearanceToString({currentAppearance: appearance, race: raceGenerated})) }
            />
        </div>);
}

export default AppearanceGenerator;
