import {Historical} from "../simple/HistoryGenerator";
import {GetObject, GrabObject, ReferenceObject, ReferenceType} from "../utility/architecture/ReferenceIds";
import {ConvertResults, ResultComponent} from "../utility/resultcomponents/ResultComponent";
import React, {useEffect} from "react";

export interface Location extends Historical, ReferenceObject {
    Name: Array<ResultComponent>;
    Desc: Array<ResultComponent>;
    Type: number;
}

export const DEFAULT_LOCATION: Location = {
    ReferenceData: {Set:true},
    Name: [],
    Desc: [],
    History: [],
    Type: 0
}

export type LocationReferenceType = string;
export const DEFAULT_LOCATION_REF: LocationReferenceType = 'blank';

export function LocationDisplay(props: {location: LocationReferenceType}) {
    const [, updateState] = React.useState();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const forceUpdate = React.useCallback(() => updateState({}), []);

    let location = GetObject(ReferenceType.Location, props.location) as Location;
    useEffect(() => {
        if(!location.ReferenceData.Set) {
            GrabObject(ReferenceType.Location, props.location, forceUpdate);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let locationNameDisplay = ConvertResults(location.Name);
    let locationDescDisplay = ConvertResults(location.Desc);

    return (
        <div>
            <h1>{locationNameDisplay}</h1>
            {locationDescDisplay}
        </div>
    );
}
