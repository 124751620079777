import {Button, Overlay, Tooltip} from "react-bootstrap";
import React, {useRef, useState} from "react";
import {GetModifierTextDisplay} from "./StatBlock";
import {AppearanceToString} from "../complex/AppearanceDisplay";
import {ConvertResults, ConvertResultsDisplay, ConvertResultsToString} from "./resultcomponents/ResultComponent";
import {NPC} from "../complex/NPCDisplay";
import {Building} from "../complex/BuildingDisplay";
import {ResultComponentItemList} from "./resultcomponents/ResultComponentItemList";
import Collapse from "react-bootstrap/Collapse";
import {GetObject, ReferenceType} from "./architecture/ReferenceIds";


export function CopyNPCToLegendKeeper(currentNPC: NPC)
{
    const listener = function(ev: any) {
        ev.preventDefault();
        ev.clipboardData.setData('text/html',
            "<div data-layout-section=\"true\" data-pm-slice=\"0 0 []\">" +
            "<div data-layout-column=\"true\" style=\"flex-basis: 66.66%\" data-column-width=\"66.66\">" +
            "<div data-layout-content=\"true\">" +
            "<section data-panel-type=\"info\" data-menu=\"true\"><div><h2>Statblock</h2></div></section>" +
            "<Table size=\"sm\">\n" +
            "            <thead>\n" +
            "            <tr>\n" +
            "                <th>STR</th>\n" +
            "                <th>DEX</th>\n" +
            "                <th>CON</th>\n" +
            "                <th>INT</th>\n" +
            "                <th>WIS</th>\n" +
            "                <th>CHA</th>\n" +
            "            </tr>\n" +
            "            </thead>\n" +
            "            <tbody>\n" +
            "            <tr>\n" +
            "                <td>"+ currentNPC.Stats.Stat_Strength +" (" + GetModifierTextDisplay(currentNPC.Stats.Stat_Strength) + ")</td>\n" +
            "                <td>"+ currentNPC.Stats.Stat_Dexterity +" (" + GetModifierTextDisplay(currentNPC.Stats.Stat_Dexterity) + ")</td>\n" +
            "                <td>"+ currentNPC.Stats.Stat_Constitution +" (" + GetModifierTextDisplay(currentNPC.Stats.Stat_Constitution) + ")</td>\n" +
            "                <td>"+ currentNPC.Stats.Stat_Intelligence +" (" + GetModifierTextDisplay(currentNPC.Stats.Stat_Intelligence) + ")</td>\n" +
            "                <td>"+ currentNPC.Stats.Stat_Wisdom +" (" + GetModifierTextDisplay(currentNPC.Stats.Stat_Wisdom) + ")</td>\n" +
            "                <td>"+ currentNPC.Stats.Stat_Charisma +" (" + GetModifierTextDisplay(currentNPC.Stats.Stat_Charisma) + ")</td>\n" +
            "            </tr>\n" +
            "            </tbody>\n" +
            "        </Table>" +
            "<section data-panel-type=\"warning\" data-menu=\"true\"><div><h2>Appearance</h2></div></section>" +
            AppearanceToString({currentAppearance: currentNPC.VisualAppearance, race: currentNPC.RaceName}) +
            "<section data-panel-type=\"error\" data-menu=\"true\"><div><h2>Voice</h2></div></section>" +
            ConvertResultsToString(currentNPC.Voice) +
            "<section data-panel-type=\"info\" data-menu=\"true\"><div><h2>Extra Details</h2></div></section>" +
            "" +
            "</div>" +
            "</div>" +
            "<div data-layout-column=\"true\" style=\"flex-basis: 33.33%\" data-column-width=\"33.33\">" +
            "<div data-layout-content=\"true\">" +
            "<section data-panel-type=\"info\" data-menu=\"true\"><div><h3>Basic Information</h3></div></section>" +
            currentNPC.Size + " " + currentNPC.Type + " (" + currentNPC.RaceName + "), " + currentNPC.Alignment +
            "<br/ ><br/ >" +
            "Languages: " + currentNPC.Languages +
            "<section data-panel-type=\"note\" data-menu=\"true\"><div><h3>Personality</h3></div></section>" +
            ConvertResultsToString(currentNPC.Personality) +
            "<section data-panel-type=\"info\" data-menu=\"true\"><div><h3>Job</h3></div></section>" +
            ConvertResultsToString(currentNPC.Job) +
            "<section data-panel-type=\"success\" data-menu=\"true\"><div><h3>Goal</h3></div></section>" +
            ConvertResultsToString(currentNPC.Goal) +
            "<section data-panel-type=\"warning\" data-menu=\"true\"><div><h3>Flaw</h3></div></section>" +
            ConvertResultsToString(currentNPC.Flaw) +
            "<section data-panel-type=\"note\" data-menu=\"true\"><div><h3>Secret</h3></div></section>" +
            ConvertResultsToString(currentNPC.Secret) +
            "<section data-panel-type=\"error\" data-menu=\"true\"><div><h3>Fear</h3></div></section>" +
            ConvertResultsToString(currentNPC.Fear) +
            "<section data-panel-type=\"error\" data-menu=\"true\"><div><h3>Related Rumor</h3></div></section>" +
            ConvertResultsToString(currentNPC.Rumor) +
            "</div>" +
            "</div>"
        );
        ev.clipboardData.setData('text/plain', currentNPC.Name);
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
}

export function CopyBuildingToLegendKeeper(currentBuilding: Building)
{

    const listener = function(ev: any) {
        ev.preventDefault();

        let personTable = "";

        function AddToPersonTable(role: string, personName: string, desc: string)
        {
            personTable +=
                "            <tr>\n" +
                "                <th>" + role + "</th>\n" +
                "                <th>" + personName + "</th>\n" +
                "                <th>" + desc + "</th>\n" +
                "            </tr>\n";
        }

        let buildingOwner = GetObject(ReferenceType.NPC,  currentBuilding.buildingOwner)!;

        AddToPersonTable("Owner", buildingOwner.Name, AppearanceToString({currentAppearance: buildingOwner.VisualAppearance, race: buildingOwner.RaceName}));
        for (let i = 0; i < currentBuilding.employees.length; i++)
        {
            let employee = GetObject(ReferenceType.NPC,  currentBuilding.employees[i]!)!;
            AddToPersonTable("Staff", employee.Name, AppearanceToString({currentAppearance: employee.VisualAppearance, race: employee.RaceName}));
        }
        for (let i = 0; i < currentBuilding.regularCustomers.length; i++)
        {
            let regular = GetObject(ReferenceType.NPC,  currentBuilding.regularCustomers[i]!)!;
            AddToPersonTable("Staff", regular.Name, AppearanceToString({currentAppearance: regular.VisualAppearance, race: regular.RaceName}));
        }

        let servicesTables = "";

        const displayedInventory = currentBuilding.inventory.List.length === 0 ?
            ["None!"] :
            currentBuilding.inventory.List.map(itemList =>
            {
                let subList = itemList.result.map(specificItem =>
                    ConvertResultsToString(specificItem)
                );

                let typeDisplay = ConvertResultsToString(itemList.type);

                let result = "";
                result +="<li><p>" + typeDisplay + " (x" + subList.length + ")</p>";
                result += "<ul>";
                for (let i = 0; i < subList.length; i++){
                    result += "<li><p>" + subList[i] + "</p></li>";
                }
                result += "</li></ul>";

                return result;
            });

        servicesTables += "<ul>";
        for (let i = 0; i < displayedInventory.length; i++)
        {
            servicesTables += displayedInventory[i];
        }
        servicesTables += "</ul>";

        ev.clipboardData.setData('text/html',
            "<div data-layout-section=\"true\" data-pm-slice=\"0 0 []\">" +
            "<div data-layout-column=\"true\" style=\"flex-basis: 50%\" data-column-width=\"50\">" +
            "<div data-layout-content=\"true\">" +
            "<section data-panel-type=\"info\" data-menu=\"true\"><div><h2>Notable Figures</h2></div></section>" +
            "<Table size=\"sm\">\n" +
            personTable +
            "        </Table>" +
            "</div>" +
            "</div>" +
            "<div data-layout-column=\"true\" style=\"flex-basis: 50%\" data-column-width=\"50\">" +
            "<div data-layout-content=\"true\">" +
            "<section data-panel-type=\"note\" data-menu=\"true\"><div><h3>Building Appearance</h3></div></section>" +
            "<h2>" + ConvertResultsToString(currentBuilding.locationType) + "</h2>" +
            ConvertResultsToString(currentBuilding.knownFor) +
            "<h3>Related Rumor</h3>" +
            ConvertResultsToString(currentBuilding.rumor) +
            "<h3>Exterior</h3>" +
            ConvertResultsToString(currentBuilding.appearance.exterior) +
            "<h3>Interior</h3>" +
            ConvertResultsToString(currentBuilding.appearance.interior) +
            "<section data-panel-type=\"warning\" data-menu=\"true\"><div><h2>Building Services</h2></div></section>" +
            ConvertResultsToString(currentBuilding.markup) +
            servicesTables +
            // AppearanceToString({currentAppearance: currentNPC.VisualAppearance, race: currentNPC.RaceName}) +
            "<br/ ><br/ >" +
            "</div>" +
            "</div>" +
            "</div>"
        );
        ev.clipboardData.setData('text/plain', ConvertResultsToString(currentBuilding.locationName));
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
}

export function LegendKeeperButton(props: { activate: (() => void) })
{
    const [show, setShow] = useState(false);
    const target = useRef(null);

    function Clicked() {
        props.activate();
        setShow(true);

        setTimeout(function () {
            setShow(false);
        }, 3000);
    }

    return (
        <>
            {
                show ? <div>Legendkeeper format copied to clipboard!<br /></div>
                    : <div><br /></div>
            }
            <br />
            <Button variant="dark" onClick={Clicked}>Copy to LegendKeeper</Button>
            <br />
            <a target="_blank" className="fakelink tinyText" href="https://www.legendkeeper.com">What is LegendKeeper?</a>
        </>
    );
};
