import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation'
import Card from 'react-bootstrap/Card';
import MonsterGenerator from "../complex/MonsterGenerator";
import Nav from 'react-bootstrap/Nav';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { JsxElement } from 'typescript';
import {ConvertResults, DEFAULT_RESULT_ARRAY} from "../utility/resultcomponents/ResultComponent";
import TemplateGenerator from "../utility/TemplateGenerator";
import {Meta} from "../utility/MetaWrapper";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";

function MiscGenerator() {
    const [generatorNames, setGeneratorNames] = useState(['']);
    const [selectedGeneratorOptions, setSelectedGeneratorOptions] = useState(['Random']);
    const [selectedGenerator, setSelectedGenerator] = useState('Random');

    useEffect(() => {
        UpdateSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGeneratorOptions]);

    function GetSelectedGenerator() {
        return selectedGeneratorOptions[Math.floor(Math.random() * selectedGeneratorOptions.length)];
    }

    function UpdateSelected() {
        setSelectedGenerator(GetSelectedGenerator()!);
    }

    function UpdateGeneratorList() {
        fetch(URL+"/GetMiscGeneratorTypes",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let gens: Array<string> = data;

            setGeneratorNames(gens);

        });
    }

    useEffect(() => {
        UpdateGeneratorList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div>
            <Meta pageName="Misc Generators" desc="TTRPG Misc Generator" address="https://7tools.dev/miscgenerator" />
            <TemplateGenerator onGenerateButtonClicked={UpdateSelected} name="Misc" url="GetMiscGenerator" multiple additionalUrl={"&generator=" + selectedGenerator} settingsComponent={
                <div>
                    <h4>Generator Type</h4>
                    <AdvancedDropdown options={ConvertForDropdown(generatorNames)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedGeneratorOptions} />
                </div>
            } />
        </div>);
}

export default MiscGenerator;
