import {Helmet} from "react-helmet";
import React from "react";


export function Meta(props: { pageName: string, desc: string, address: string })
{
    let name = props.pageName === "" ? "7Tools" : "7Tools - " + props.pageName;

    return (
        <Helmet>
            <html lang="en" />
            <title>{name}</title>
            <meta name="description" content={props.desc} />
            {/*Google*/}
            <meta itemProp="name" content={name} />
            <meta itemProp="description" content={props.desc} />
            {/*Facebook*/}
            <meta property="og:title" content={name} />
            <meta property="og:description" content={props.desc} />
            <meta property="og:url" content={props.address} />
            <meta property="og:type" content="website" />
            {/*Twitter*/}
            <meta name="twitter:title" content={name} />
            <meta name="twitter:description" content={props.desc} />
        </Helmet>
    );
}
