import React, { useState, useEffect }  from 'react';
import {Button, Col, Container, ProgressBar, Row, Table} from "react-bootstrap";
import URL from "../../BackendLocation";
import Modal from "react-bootstrap/Modal";
import {Meta} from "../utility/MetaWrapper";
import axios from "axios";
import {useIsLoggedIn} from "../../hooks/loginHook";

export function InitiativeTracker()
{
    interface InitField {
        id: number,
        init: number,
        subInit: number,
        name: string,
        ac: number,
        hp: number,
        maxHp: number,
        info: string,
        parent: number,
    }

    const DEFAULT_INIT_FIELD: InitField = {
        id: 0,
        init: 0,
        subInit: 0,
        name: '',
        ac: 10,
        hp: 1,
        maxHp: 1,
        info: '',
        parent: -1,
    }

    const [selectedLoadOption, setSelectedLoadOption] = useState('');
    const [loadOptions, setLoadOptions] = useState<Array<string>>(new Array<string>());
    const [loadOptionsMapped, setLoadOptionsMapped] = useState<Map<string, Array<InitField>>>(new Map<string, Array<InitField>>());
    const [saveConfigName, setSavedConfigName] = useState('');
    const [savePromptOpen, setSavePromptOpen] = useState(false);
    const handleShowPrompt = () => setSavePromptOpen(true);
    const handleHidePrompt = () => { setSavedConfigName(''); setSavePromptOpen(false); }

    const [currId, setCurrId] = useState(1);
    const [rows, setRows] = useState([DEFAULT_INIT_FIELD]);
    const [healHurt, setHealHurt] = useState(0);
    const [selectTarget, setSelectTarget] = useState(false);
    const [currentTurnId, setCurrentTurnId] = useState(-1);
    const [currentTurn, setCurrentTurn] = useState(-1);
    const [hoveringRows, setHoveringRows] = useState(new Map<number, boolean>([
        [0, false]
    ]));
    const updateHoverMap = (key: number, value: boolean) => {
        setHoveringRows(map => new Map(map.set(key, value)));
    }

    useEffect(() => {
        UpdateFields();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows]);

    useEffect(() => {
        UpdateLoadOptions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function UpdateLoadOptions() {
        const getSavedCreation = async () => {
            const response = await axios.get(URL + '/GetSavedGenerics', { withCredentials: true, params: { typename: 'init' } }).catch((e) => {
                console.log(e);
            });

            if(response && response.data){
                let dataMap = new Map<string, Array<InitField>>();
                let list: Array<string> = [];
                for(let i = 0; i < response.data.components.length; i++) {
                    let val = JSON.parse(response.data.components[i]);
                    list.push(val.configName);
                    dataMap.set(val.configName, val.data);
                }

                setSelectedLoadOption(list[0]!);

                setLoadOptions(list);
                setLoadOptionsMapped(dataMap);
            }
        }

        getSavedCreation().catch((e) => {console.log(e);});
    }

    function UpdateFields() {
        rows.forEach(row => {
            // console.log(row);
            (document.getElementById("init_" + row.id)! as HTMLInputElement).value = row.init.toString();
            (document.getElementById("name_" + row.id)! as HTMLInputElement).value = row.name.toString();
            (document.getElementById("ac_" + row.id)! as HTMLInputElement).value = row.ac.toString();
            (document.getElementById("hp_" + row.id)! as HTMLInputElement).value = row.hp.toString();
            (document.getElementById("maxhp_" + row.id)! as HTMLInputElement).value = row.maxHp.toString();
            (document.getElementById("info_" + row.id)! as HTMLInputElement).value = row.info.toString();
        });
    }

    const updateFieldChanged = (id: number, newData: InitField) => {
        let newArr = [...rows];

        newData.hp = Math.min(Math.max(newData.hp, 0), newData.maxHp);

        for(let i = 0; i < newArr.length; i++)
        {
            if(newArr[i]!.id === id) {
                if(newArr[i]!.init !== newData.init){
                    let lowestSub = 9999999;
                    for(let i = 0; i < rows.length; i++) {
                        if(rows[i]!.init === newData.init) {
                            if(rows[i]!.subInit < lowestSub) {
                                lowestSub = rows[i]!.subInit;
                            }
                        }
                    }

                    newData.subInit = lowestSub - 1;
                }
                newArr[i] = newData;
            }
        }

        for(let i = 0; i < newArr.length; i++) {
            if(newArr[i]!.parent === newData.id) {
                newArr[i]!.init = newData.init;
                newArr[i]!.subInit = newData.subInit;
            }
        }

        setRows(newArr);
    }

    function ResetHealHurt()
    {
        setHealHurt(0);

        let ele: HTMLInputElement = document.getElementById("healhurtfield")! as HTMLInputElement;
        ele!.value = "0"
    }

    function FocusNone(id: string) {
        document.getElementById(id)!.blur();
    }

    function lerp (start: number, end: number, amt: number){
        return (1-amt)*start+amt*end
    }

    function RemoveRow(id: number) {
        let newRows = [...rows];

        for(let i = 0; i < newRows.length; i++) {
            if(newRows[i]!.id === id) {
                newRows.splice(i, 1);
                break;
            }
        }

        for(let i = newRows.length - 1; i >= 0; i--) {
            if(newRows[i]!.parent === id) {
                newRows.splice(i, 1);
            }
        }

        setRows(newRows);
    }

    function AddNewRow(parent = -1) {
        let newRows = [...rows];
        let newVal = DEFAULT_INIT_FIELD;
        newVal.id = currId;
        newVal.parent = parent;
        if(parent !== -1)
        {
            let numberOfAlready = 0;
            for(let i = 0; i < rows.length; i++) {
                if(rows[i]!.parent === parent) {
                    numberOfAlready++;
                }
            }

            let par = null;
            for(let i = 0; i < rows.length; i++) {
                if(rows[i]!.id === parent) {
                    par = rows[i];
                    break;
                }
            }

            newVal.init = par!.init;
            newVal.maxHp = par!.maxHp;
            newVal.hp = newVal.maxHp;
            newVal.ac = par!.ac;
            newVal.name = par!.name + " #" + (numberOfAlready + 1);
            newVal.subInit = par!.subInit;
        }

        if(newVal.parent === -1) {
            let lowestSub = 9999999;
            for(let i = 0; i < rows.length; i++) {
                if(rows[i]!.init === newVal.init) {
                    if(rows[i]!.subInit < lowestSub) {
                        lowestSub = rows[i]!.subInit;
                    }
                }
            }

            newVal.subInit = lowestSub - 1;
        }

        setCurrId(currId + 1);
        newRows.push(newVal);
        updateHoverMap(newVal.id, false);

        setRows(newRows);
    }

    let rowsToSort = [...rows];

    // for(let i = 0; i < rowsToSort.length; i++) {
    //
    //     let hasChildren = false;
    //     for(let j = 0; j < rowsToSort.length; j++) {
    //         if(rowsToSort[j]!.parent === rowsToSort[i]!.id) {
    //             hasChildren = true;
    //             break;
    //         }
    //     }
    //
    //     if(hasChildren) {
    //         rowsToSort[i]!.subInit += 0.1;
    //     }
    // }

    let rowOrdered = rowsToSort.sort((x, y) => {
        let initDiff = y.init - x.init;

        if(initDiff === 0)
        {
                let hasChildren = false;
                for(let j = 0; j < rowsToSort.length; j++) {
                    if(rowsToSort[j]!.parent === x.id) {
                        hasChildren = true;
                        break;
                    }
                }

                let extra = hasChildren ? 0.1 : 0;

            return y.subInit - x.subInit + extra;
        }

        return initDiff;
    });

    const rowsDisplay = rowOrdered.map((val, index) =>
    {
        let ratio = val.hp / val.maxHp;
        let progressColor = 'rgba(' + lerp(220, 4, ratio) + ',' + lerp(4, 101, ratio)  + ',' + lerp(29, 2, ratio)  + ',0.4)';

        let hasChildren = false;
        for(let i = 0; i < rowOrdered.length; i++) {
            if(rowOrdered[i]!.parent === val.id) {
                hasChildren = true;
                break;
            }
        }

        let sharesInitOrder = false;
        for(let i = 0; i < rowOrdered.length; i++) {
            if(rowOrdered[i]!.parent !== val.id && rowOrdered[i]!.id !== val.id && rowOrdered[i]!.init === val.init) {
                sharesInitOrder = true;
                break;
            }
        }

        let ignoreColorHealth = false;
        let cellColor = "transparent";

        if(currentTurnId === val.id) {
            cellColor = "khaki";
            ignoreColorHealth = true;
        }

        if(val.parent !== -1) {
            cellColor = "lightgray";
            ignoreColorHealth = true;
        }

        if(val.parent !== -1 && currentTurnId === val.parent) {
            cellColor = "khaki";
            ignoreColorHealth = true;
        }

        if(val.hp <= 0) {
            ignoreColorHealth = false;
            cellColor = "#EEBBBB"
        }

        let isHovering = hoveringRows.get(val.id);

        function Swap(moveUp: boolean) {
            let validIndex = moveUp ? index - 1 : index + 1;

            let valid = false;
            if(validIndex >= 0 && validIndex < rowOrdered.length && rowOrdered[validIndex]!.init === val.init)
            {
                while (rowOrdered[validIndex]!.parent !== -1) {
                    if(moveUp) {
                        validIndex--;
                    }
                    else {
                        validIndex++;
                    }
                }

                if(validIndex >= 0 && validIndex < rowOrdered.length && rowOrdered[validIndex]!.init === val.init)
                {
                    valid = true;
                }
            }

            if(valid) {
                let swap = val.subInit;
                val.subInit = rowOrdered[validIndex]!.subInit;
                rowOrdered[validIndex]!.subInit = swap;
                updateFieldChanged(val.id, val);
                updateFieldChanged(rowOrdered[validIndex]!.id, rowOrdered[validIndex]!);
            }
        }

        return (
            <div style={{
                width: val.parent === -1 ? "100%" : "98%"
            }} className="it_wrapper" onMouseOver={() => { updateHoverMap(val.id, true); }} onMouseOut={() => {updateHoverMap(val.id, false);}}>
                <div style={{backgroundColor: cellColor}} className={"it_cell it_cell_border_sides it_cell_border_top" + (index === rowOrdered.length - 1 ? " it_cell_border_bottom it_bottom_left" : "")}>
                    {
                        hasChildren ?
                            <></>
                            :
                            <Button className="fill" onClick={() => { val.hp += healHurt; updateFieldChanged(val.id, val); setSelectTarget(false); ResetHealHurt();}} disabled={!selectTarget}>
                                <p className="buttonTextBig">{'>'}</p>
                            </Button>
                    }
                </div>
                <div style={{backgroundColor: cellColor}} className={"it_cell it_cell_border_sides it_cell_border_top" + (index === rowOrdered.length - 1 ? " it_cell_border_bottom" : "")}>
                    <input disabled={val.parent !== -1} id={"init_" + val.id} type="number" onKeyDown={event => { if(event.key === 'Enter') FocusNone(event.currentTarget.id);}} onBlur={data => { if(data.target.value === '' || data.target.value == null) { return; } val.init = parseInt(data.target.value); updateFieldChanged(val.id, val); } }
                           className="inputNone noborder fill transparentbg" defaultValue={val.init} />
                    {/*<p style={{*/}
                    {/*    border: "1px solid darkgrey",*/}
                    {/*    position: "absolute",*/}
                    {/*    right: "0",*/}
                    {/*    top: "0",*/}
                    {/*    width: "20px",*/}
                    {/*    height: "20px",*/}
                    {/*    fontSize: "15px",*/}
                    {/*    textAlign: "center",*/}
                    {/*    margin: "0",*/}
                    {/*    padding: "0",*/}
                    {/*}}>{val.subInit}</p>*/}
                    {
                        sharesInitOrder && val.parent === -1 ?
                            <>
                                <button style={{
                                    border: "1px solid darkgrey",
                                    position: "absolute",
                                    left: "0",
                                    top: "0",
                                    width: "20px",
                                    height: "20px",
                                    fontSize: "15px",
                                    textAlign: "center",
                                    margin: "0",
                                    padding: "0",
                                }} onClick={() => {
                                    Swap(true);
                                }
                                }>^</button>
                                <button style={{
                                    border: "1px solid darkgrey",
                                    position: "absolute",
                                    left: "0",
                                    bottom: "0",
                                    width: "20px",
                                    height: "20px",
                                    fontSize: "15px",
                                    textAlign: "center",
                                    margin: "0",
                                    padding: "0",
                                }} onClick={() => {
                                    Swap(false);
                                }
                                }>˅</button>
                            </>
                            :
                            <></>
                    }
                </div>
                <div style={{backgroundColor: cellColor}} className={"it_cell it_cell_border_sides it_cell_border_top" + (index === rowOrdered.length - 1 ? " it_cell_border_bottom" : "")}>
                    <input autoComplete="off" id={"name_" + val.id} onKeyDown={event => { if(event.key === 'Enter') FocusNone(event.currentTarget.id);}} onBlur={data => { if(data.target.value == null) { return; } val.name = data.target.value; updateFieldChanged(val.id, val); } }
                           className="inputNone noborder fill transparentbg" defaultValue={val.name} />
                </div>
                <div style={{backgroundColor: cellColor}} className={"it_cell it_cell_border_sides it_cell_border_top" + (index === rowOrdered.length - 1 ? " it_cell_border_bottom" : "")}>
                    <input autoComplete="off" id={"ac_" + val.id} type="number" onKeyDown={event => { if(event.key === 'Enter') FocusNone(event.currentTarget.id);}} onBlur={data => { if(data.target.value === '' || data.target.value == null) { return; } val.ac = parseInt(data.target.value); updateFieldChanged(val.id, val); } }
                           className="inputNone noborder fill transparentbg" defaultValue={val.ac} />
                </div>
                <div style={{backgroundColor: ignoreColorHealth ? "transparent" : cellColor}} className={"it_cell it_cell_border_sides it_cell_border_top" + (index === rowOrdered.length - 1 ? " it_cell_border_bottom" : "")}>
                    <div className="healthbar" style={{
                        width: ((val.hp / val.maxHp) * 100) + '%',
                        backgroundColor: progressColor
                    }} />
                    <input disabled={hasChildren} id={"hp_" + val.id} onKeyDown={event => { if(event.key === 'Enter') FocusNone(event.currentTarget.id);}} type="number" onBlur={data => { if(data.target.value === '' || data.target.value == null) { return; } val.hp = parseInt(data.target.value); updateFieldChanged(val.id, val); } }
                           className="inputNone noborder halffill transparentbg" defaultValue={val.hp} />
                    /
                    <input id={"maxhp_" + val.id} onKeyDown={event => { if(event.key === 'Enter') FocusNone(event.currentTarget.id);}} type="number" onBlur={data =>
                    {
                        if(data.target.value === '' || data.target.value == null) { return; }
                        let originalMax = val.maxHp;
                        let ratio = val.hp / originalMax;
                        val.maxHp = parseInt(data.target.value);
                        val.hp = Math.floor(val.maxHp * ratio);
                        updateFieldChanged(val.id, val);

                    } }className="inputNone noborder halffill transparentbg" defaultValue={val.maxHp} />
                </div>
                <div style={{backgroundColor: cellColor}} className={"it_cell it_cell_border_sides it_cell_border_top" + (index === rowOrdered.length - 1 ? " it_cell_border_bottom it_bottom_right" : "")}>
                    <input autoComplete="off" id={"info_" + val.id} onKeyDown={event => { if(event.key === 'Enter') FocusNone(event.currentTarget.id);}} onBlur={data => { if(data.target.value == null) { return; } val.info = data.target.value; updateFieldChanged(val.id, val); } }
                           className="inputNone noborder fill transparentbg" defaultValue={val.info} />
                </div>
                <div style={{
                }} className={"it_cell"}>
                    <Button style={{
                    backgroundColor: isHovering ? "#0d6efd" : "transparent",
                    color: isHovering ? "white" : "transparent",
                    border: 0,
                    transition: "all 0.25s ease-in-out"
                }} className="halffill" onClick={(mouseEvent) => { if(isHovering) { RemoveRow(val.id); mouseEvent.currentTarget.blur(); } }}><p className="buttonTextBig">-</p></Button>
                    {
                        val.parent === -1 ?
                            <>
                                {' '}
                                <Button style={{
                                    backgroundColor: isHovering ? "#0d6efd" : "transparent",
                                    color: isHovering ? "white" : "transparent",
                                    border: 0,
                                    transition: "all 0.25s ease-in-out"
                                }}  className="halffill" onClick={(mouseEvent) => { if(isHovering) { AddNewRow(val.id); mouseEvent.currentTarget.blur(); } }}><p className="buttonTextBig">+</p></Button>
                            </>
                            :
                            <></>
                    }
                </div>
            </div>
        );
    });

    function FixSubInitSetup(fixes: Array<number>) {
        let newArr = [...rowOrdered];

        for(let f = 0; f < fixes.length; f++) {
            let curr = 0;
            for(let i = 0; i < newArr.length; i++) {
                if(newArr[i]!.init === fixes[f]) {
                    newArr[i]!.subInit = curr;
                    curr--;
                }
            }
        }

        for(let f = 0; f < fixes.length; f++) {
            let curr = 0;
            for(let i = 0; i < newArr.length; i++) {
                if(newArr[i]!.init === fixes[f]) {
                    newArr[i]!.subInit += curr;
                }
            }
        }

        for(let i = 0; i < newArr.length; i++) {
            if(newArr[i]!.parent !== -1) {
                for(let j = 0; j < newArr.length; j++) {
                    if(newArr[j]!.id !== newArr[i]!.parent) {
                        newArr[i]!.subInit = newArr[j]!.subInit;
                        break;
                    }
                }
            }
        }

        setRows(newArr);
    }

    let needFixSubInit: Array<number> = [];
    let checkingInit = -1;
    let checkingSubInit: Array<number> = [];
    for(let i = 0; i < rowOrdered.length; i++) {
        if(rowOrdered[i]!.parent !== -1) {continue;}
        let currInit = rowOrdered[i]!.init;
        if(currInit !== checkingInit) {
            checkingInit = currInit;
            checkingSubInit = [];
        }

        if(!checkingSubInit.includes(rowOrdered[i]!.subInit)) {
            checkingSubInit.push(rowOrdered[i]!.subInit);
        }
        else {
            if(!needFixSubInit.includes(currInit)) {
                needFixSubInit.push(currInit);
            }
        }
    }

    if(needFixSubInit.length > 0) {
        FixSubInitSetup(needFixSubInit);
    }

    function StartInit() {
        setCurrentTurnId(rowOrdered[0]!.id);
        setCurrentTurn(0);
    }

    function ResetInit() {
        setCurrentTurnId(-1);
        setCurrentTurn(-1);
    }

    function NextInitTurn() {
        for(let i = 0; i < rowOrdered.length; i++) {
            if(currentTurnId === rowOrdered[i]!.id) {
                let refIndex = i;
                let nextIndex = refIndex + 1;
                if(nextIndex === rowOrdered.length) {
                    nextIndex = 0;
                    setCurrentTurn(currentTurn + 1);
                }
                let nextOption = rowOrdered[nextIndex];
                while (nextOption!.hp <= 0 || nextOption!.parent !== -1) {
                    nextIndex++;
                    if(nextIndex === rowOrdered.length) {
                        nextIndex = 0;
                        setCurrentTurn(currentTurn + 1);
                    }
                    nextOption = rowOrdered[nextIndex];
                }

                setCurrentTurnId(nextOption!.id);
                break;
            }
        }
    }

    function setLoadValue(selected: React.ChangeEvent<HTMLSelectElement>) {
        setSelectedLoadOption(selected.target.value);
    }

    const loadOptionsList = loadOptions.map(option =>
        {
            return <option key={option} value={option}>{option}</option>;
        }
    )

    function SetSavedConfigNameInput(props: React.ChangeEvent<HTMLInputElement>) {
        setSavedConfigName(props.target.value);
    }

    function SaveCurrentConfigToProfile(){
        if(saveConfigName === '') {
            return;
        }

        const saveConfig = async () => {
            const response = await axios.put(URL + '/SaveGeneric?typename=init', { genericdata: {configName: saveConfigName, data: rows} }, {withCredentials: true }).catch((e) => {
                console.log(e);
            });

            if(response && response.data){

            }
        }

        saveConfig().catch((e) => {console.log(e);});

        handleHidePrompt();
        const timeout = setTimeout(() => {
            UpdateLoadOptions();
        }, 100);
    }

    function LoadCurrentConfig() {
        let newRows = loadOptionsMapped.get(selectedLoadOption);
        let newHover = new Map<number, boolean>([
        ]);

        for(let i = 0; i < newRows!.length; i++) {
            newHover.set(newRows![i]!.id, false);
        }

        setCurrId(newRows!.length + 1);
        setRows(newRows!);
        setHoveringRows(newHover);
    }

    function DeleteCurrentConfig() {
        const deleteConfig = async () => {
            const response = await axios.put(URL + '/DeleteSavedGeneric?typename=init', { genericdata: {configName: selectedLoadOption, data: loadOptionsMapped.get(selectedLoadOption)} }, {withCredentials: true }).catch((e) => {
                console.log(e);
            });

            if(response && response.data){

            }
        }

        deleteConfig().catch((e) => {console.log(e);});
        const timeout = setTimeout(() => {
            UpdateLoadOptions();
        }, 100);
    }

    let disabledButton = healHurt === 0 || selectTarget;
    const loggedIn = useIsLoggedIn();

    return (
        <>
            <Meta pageName="Initiative Tracker" desc="A TTRPG Initiative Tracker useful for combat scenarios to track player and monster positions in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/initiativetracker" />
            <Modal show={savePromptOpen} onHide={handleHidePrompt}>
                <Modal.Header closeButton>
                    <Modal.Title>Save Initiative Configuration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Insert Configuration Name&nbsp;&nbsp;
                    <input onChange={SetSavedConfigNameInput} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleHidePrompt}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={SaveCurrentConfigToProfile}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <h1>Initiative Tracker</h1>
            {
                loggedIn ?
                    <>
                        <Button onClick={handleShowPrompt} >Save Configuration</Button>
                        <br />
                        <br />
                        {
                            loadOptions.length > 0 ?
                                <>
                                    <select onChange={setLoadValue}>
                                        {loadOptionsList}
                                    </select>
                                    {' '}
                                    <Button onClick={LoadCurrentConfig}>Load Config</Button>
                                    {' '}
                                    <Button variant="danger" onClick={DeleteCurrentConfig}>Delete Config</Button>
                                    <br />
                                    <br />
                                </>
                                :
                                <></>
                        }
                    </>
                    :
                    <>
                        <Button disabled={true}>Login to save Configuration</Button>
                        <br />
                        <br />
                    </>
            }
            {
                currentTurn !== -1 ?
                    <>
                        <h2>Turn: {currentTurn + 1}</h2>
                    </>
                    : <></>
            }
            <div className="it_wrapper" style={{
                gridTemplateRows: "repeat(" + (1) + ", 60px)" // + rowsDisplay.length
            }}>
                <div className="it_cell it_top_left it_cell_border_sides it_cell_border_top">Act</div>
                <div className="it_cell it_cell_border_sides it_cell_border_top">Init</div>
                <div className="it_cell it_cell_border_sides it_cell_border_top">Name</div>
                <div className="it_cell it_cell_border_sides it_cell_border_top">AC</div>
                <div className="it_cell it_cell_border_sides it_cell_border_top">HP</div>
                <div className="it_cell it_cell_border_sides it_cell_border_top it_top_right">Info</div>
                {/*<div className="it_cell it_top_right it_cell_border_sides it_cell_border_top">Edit</div>*/}
            </div>
            {rowsDisplay}
            <br />
            <Button onClick={() => {
                AddNewRow();
            }} >+</Button>
            <br />
            <br />
            <Button variant={healHurt >= 0 ? "success" : "danger"} onClick={() => setSelectTarget(true)} disabled={disabledButton}>Heal/Hurt</Button>{' '}
            <input id="healhurtfield" disabled={selectTarget} type="number" onKeyDown={event => { if(event.key === 'Enter') FocusNone(event.currentTarget.id);}} onBlur={data => setHealHurt(parseInt(data.target.value))} defaultValue={0} className="short centertext" />
            {
                selectTarget ?
                    <>
                        <br />
                        <br />
                        <Button onClick={() => setSelectTarget(false)} variant="secondary">Cancel {healHurt > 0 ? "heal" : "damage"}</Button>
                    </>
                    :
                    <></>
            }
            <br />
            <br />
            {
                currentTurnId === -1 ?
                    <Button variant="warning" onClick={StartInit} disabled={rowOrdered.length < 2}>Start Initiative</Button>
                    :
                    <>
                        <Button variant="success" onClick={NextInitTurn}>Next Turn</Button>
                        <br />
                        <br />
                        <Button variant="danger" onClick={ResetInit} disabled={rowOrdered.length < 2}>Reset Initiative</Button>
                    </>
            }
        </>
    );
}
