import React, {useState, useEffect, useContext} from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation';
import {Meta} from "../utility/MetaWrapper";
import {ResultComponent, ResultComponentList} from "../../../../dndnode_ass/OptionBuilderPieces/OptionBuilderTools";
import NPCDisplay, {DEFAULT_NPC, DEFAULT_NPC_REF, NPC, NPCReferenceType} from "./NPCDisplay";
import TemplateGenerator, {PopupContext} from "../utility/TemplateGenerator";
import {ConvertResults} from "../utility/resultcomponents/ResultComponent";
import {Modal} from "react-bootstrap";
import {ResultComponentItemDisplay, ResultComponentItemList} from "../utility/resultcomponents/ResultComponentItemList";
import axios from "axios";
import {CopyBuildingToLegendKeeper, CopyNPCToLegendKeeper, LegendKeeperButton} from "../utility/LegendKeeperCopy";
import {
    GetObject, GrabObject,
    ReferenceObject,
    referencePackage, ReferenceType,
    SetReferencePackage
} from "../utility/architecture/ReferenceIds";


export interface LocationAppearance {
    exterior: Array<ResultComponent>;
    interior: Array<ResultComponent>;
}

const DEFAULT_LOCATION_APPEARANCE: LocationAppearance = {
    exterior: [],
    interior: []
}

export type BuildingReferenceType = string;

export const DEFAULT_BUILDING_REF: BuildingReferenceType = 'blank';

export interface Building extends ReferenceObject {
    locationType: Array<ResultComponent>;
    locationName: Array<ResultComponent>;
    locatedAt?: Array<ResultComponent>;
    isShop: boolean;
    markup: Array<ResultComponent>;
    buildingOwner: NPCReferenceType;
    employees: Array<NPCReferenceType>;
    regularCustomers: Array<NPCReferenceType>;
    knownFor: Array<ResultComponent>;
    rumor: Array<ResultComponent>;
    appearance: LocationAppearance;
    inventory: ResultComponentList;
}

export const DEFAULT_BUILDING: Building = {
    ReferenceData: {Set:true},
    locationType: [],
    locationName: [],
    isShop: false,
    markup: [],
    buildingOwner: 'blank',
    employees: [],
    regularCustomers: [],
    knownFor: [],
    rumor: [],
    appearance: DEFAULT_LOCATION_APPEARANCE,
    inventory: {
        List: []
    }
}

function BuildingDisplay(props: {building: BuildingReferenceType}) {
    const [, updateState] = React.useState();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const forceUpdate = React.useCallback(() => updateState({}), []);

    let building = GetObject(ReferenceType.Building, props.building) as Building;
    useEffect(() => {
        if(!building.ReferenceData.Set) {
            GrabObject(ReferenceType.Building, props.building, forceUpdate);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const displayTypes = useContext(PopupContext);

    let buildingNameDisplay = ConvertResults(building.locationName);
    let buildingTypeDisplay = ConvertResults(building.locationType);
    let buildingAppearanceExteriorDisplay = ConvertResults(building.appearance.exterior);
    let buildingAppearanceInteriorDisplay = ConvertResults(building.appearance.interior);

    function ShowNPC(npcId: NPCReferenceType) {
        let types = [...displayTypes!.types!];
        types.push({
            type: npcId,
            data: null
        });
        displayTypes!.setTypes!(types);
    }

    const displayedInventory = building.inventory.List.length === 0 ?
        <>
            <p>None!</p>
        </> :
        building.inventory.List.map(itemList =>
    {
        return (
            <ul className="alignLeft">
                <ResultComponentItemDisplay itemList={itemList} />
            </ul>
        );
    });

        const employeeList = building.employees.map(employee =>
            <>
                <a onClick={() => {
                    ShowNPC(employee);
            }} className="fakelink">{GetObject(ReferenceType.NPC,  employee)!.Name}</a >{', '}
            </>
        );

    const regularList = building.regularCustomers.map(regular =>
        <>
            <a onClick={() => {
                ShowNPC(regular);
            }} className="fakelink">{GetObject(ReferenceType.NPC,  regular)!.Name}</a >{', '}
        </>
    );

    const knownFor = ConvertResults(building.knownFor);
    const locatedAt = building.locatedAt === undefined ? <></> : ConvertResults(building.locatedAt);
    const rumor = building.rumor === undefined ? <></> : ConvertResults(building.rumor);
    const markup = building.markup === undefined ? <></> : ConvertResults(building.markup);

    let runByText = "Run by";

    if(building.locationType.length > 0) {
        if(building.locationType[0]!.text.includes("Residence")) {
            runByText = "Resident:"
        }
        else if(building.locationType[0]!.text.includes("Graveyard") ||
                building.locationType[0]!.text.includes("Docks") ||
                building.locationType[0]!.text.includes("Market") ||
                building.locationType[0]!.text.includes("Park") ||
                building.locationType[0]!.text.includes("TownSquare")
        ) {
            runByText = "Tended to by"
        }
    }

    return(
        <>
            <h1>{buildingNameDisplay}</h1>
            <h5>{buildingTypeDisplay}</h5>
            <p><b>{runByText}</b>{' '}
                <a onClick={() => {
                    ShowNPC(building.buildingOwner);
                }} className="fakelink">{GetObject(ReferenceType.NPC,  building.buildingOwner) === undefined ? "" : GetObject(ReferenceType.NPC,  building.buildingOwner)!.Name}</a >
            </p>
            {
                building.employees.length > 0 ?
                    <>
                        <b>Employees include:</b> {employeeList}
                        <br />
                        <br />
                    </>
                    : <></>
            }
            {
                building.regularCustomers.length > 0 ?
                    <>
                        <b>Regulars include:</b> {regularList}
                        <br />
                        <br />
                    </>
                    : <></>
            }
            {
                building.locatedAt === undefined ?
                    <></>
                    :
                    <p><b>Location: </b> {locatedAt}</p>
            }
            {
                building.rumor === undefined ?
                    <></>
                    :
                    <p><b>Related Rumor: </b> {rumor}</p>
            }
            {knownFor}
            {
                knownFor.length > 1 ?
                    <>
                        <br /><br />
                    </>
                    : <></>
            }
            {markup}
            {
                building.markup[0]?.text !== '' ?
                    <>
                        <br /><br />
                    </>
                    : <></>
            }
            <h3>Building Appearance</h3>
            <h4>Exterior</h4>
            {buildingAppearanceExteriorDisplay}
            <br />
            <br />
            {
                buildingAppearanceInteriorDisplay.length > 1 ?
                    <>
                        <h4>Interior</h4>
                        {buildingAppearanceInteriorDisplay}
                        <br />
                        <br />
                    </>
                    : <></>
            }
            <h3>Building Services</h3>
            {displayedInventory}

            <LegendKeeperButton activate={() => { CopyBuildingToLegendKeeper(building); }} />
        </>
    );
}

export default BuildingDisplay;
