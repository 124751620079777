import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation';
import {Meta} from "../utility/MetaWrapper";
import {ResultComponent} from "../../../../dndnode_ass/OptionBuilderPieces/OptionBuilderTools";
import NPCDisplay, {DEFAULT_NPC, NPC, NPCReferenceType} from "./NPCDisplay";
import TemplateGenerator from "../utility/TemplateGenerator";
import {ConvertResults} from "../utility/resultcomponents/ResultComponent";
import {Modal} from "react-bootstrap";
import {ResultComponentItemList} from "../utility/resultcomponents/ResultComponentItemList";
import axios from "axios";
import BuildingDisplay, {Building, DEFAULT_BUILDING, DEFAULT_BUILDING_REF} from "./BuildingDisplay";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";


function BuildingGenerator() {

    const [, updateState] = React.useState();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [building, setBuilding] = useState(DEFAULT_BUILDING_REF);
    const [npcIds, setNpcIds] = useState(new Map<NPCReferenceType, NPC>());
    const [locationNames, setLocationNames] = useState(['']);
    const [selectedLocation, setSelectedLocation] = useState('Random');
    const [selectedLocationOptions, setSelectedLocationOptions] = useState(['Random']);
    const [generatedLocationType, setGeneratedLocationType] = useState('Random');

    useEffect(() => {
        UpdateSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLocationOptions]);

    function GetSelectedLocation() {
        return selectedLocationOptions[Math.floor(Math.random() * selectedLocationOptions.length)];
    }

    function UpdateSelected() {
        setSelectedLocation(GetSelectedLocation()!);
    }

    function updateLocationList() {
        fetch(URL+"/GetLocations",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

                let locations: Array<string> = data;

                setLocationNames(locations);

            });
    }

    function SetData(data: any) {
        setBuilding(data.building);
        forceUpdate();
    }

    function setSelectedLocationInput(selected: React.ChangeEvent<HTMLSelectElement>) {
        setSelectedLocation(selected.target.value)
    }

    useEffect(() => {
        updateLocationList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const locationList = locationNames.map(name =>
        <option key={name} value={name}>{name}</option>
        )

    function SaveBuildingToProfile(){

        const saveTemplate = async () => {
            const response = await axios.put(URL + '/SaveBuilding', { building: building }, {withCredentials: true }).catch((e) => {
                console.log(e);
            });
        }

        saveTemplate().catch((e) => {console.log(e);});
    }

    return(
        <div>
            <Meta pageName="Building Generator" desc="A TTRPG Building Generator that generates various buildings and city/town locations for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/buildinggenerator" />
            <TemplateGenerator onGenerateButtonClicked={UpdateSelected} name="Building" url="GetBuilding"
                               customDataHandling={SetData}
                               customSaveData={SaveBuildingToProfile}
                               componentReplacement={
                                   <>
                                       <hr />
                                       <BuildingDisplay building={building} />
                                   </>
                               }
                               additionalUrl={"&location=" + selectedLocation} settingsComponent={
                <div>
                    <h4>Building Types</h4>
                    <AdvancedDropdown options={ConvertForDropdown(locationNames)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedLocationOptions} />
                </div>
            } />
        </div>);
}

export default BuildingGenerator;
