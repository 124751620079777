import React, { useState, useEffect }  from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import URL from './BackendLocation';
import {Meta} from "./dnd/utility/MetaWrapper";

function PlannedFeatures() {

    const plannedFeatureList = [
        'Travel tool | Tells you how long it will take to travel a distance, includes breaks, rests, can generate encounters and landmarks along the way',
        'A hero option that gives NPCs class levels',
        'Spell generator',
        'Regional effect generator',
        'Lair action generator',
        'Tower generator',
        'Mystery generator?',
        'Trap generator? | Hooks into encounter generator',
        'Job board generator, has jobs needing done and rumors',
        'Plant/Herb generator',
        'Add small generated histories for buildings, groups',
        'Nature scene generator',
        'Add option to encounter generator for toggling whether it should be on a road',
        'Tragedy generator?',
        'Add more to quest gen',
        'Setup ability to select specific guilds with group generator',
        'Era generator (ex. \'Age of Shattered Glass\')',
        'Timeline generator',
        'Let you specify an exact loot amount for the loot generator',
        'Add families to npcs and multiple npcs in a residence',
        'Prophecy generator?',
        'Settlement Gen: Add toggle for generating maps',
        'Settlement Gen: Add walls',
        'Enhance global settings options for more customization',
        'Add more art to other gens',
        'Add reroll system allowing you to regenerate any given item in a generator or set of text, given its a reasonable thing to regenerate',
        'Riddle generator',
        'Add more languages to language translator',
        '"How We Met" generator, different ways you may have met a character, whether its through work, saving their life, being childhood friends, etc',
        'Add more generators that support generating multiple. Namely the weird ones that arent in a standard format. Maybe I should fix that too. TODO: group name, building name, item?, appearance, maybe the more complex ones but in a new format?',
        'Full scale adventure generator that interconnects?'
    ];

    const values = plannedFeatureList.map(val => {
        return (<ListGroup.Item>
                <p>{val}</p>
                </ ListGroup.Item>);
    })

    return(
        <div>
            <Meta pageName="Planned Features" desc="Features that are upcoming on 7Tools" address="https://7tools.dev/plannedfeatures" />
            <h1>Planned Future Features</h1>
            <hr />
            <ListGroup>
            {values}
            </ListGroup>
        </div>);
}

export default PlannedFeatures;
