import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import {ResultComponent, ResultComponentList} from "../../../../dndnode_ass/OptionBuilderPieces/OptionBuilderTools";
import {
    ConvertResults,
    ConvertResultsDisplay,
    ConvertResultsToString
} from "../utility/resultcomponents/ResultComponent";
import {
    GetObject,
    GrabObject,
    ReferenceObject,
    referencePackage,
    ReferenceType
} from "../utility/architecture/ReferenceIds";
import {Building} from "./BuildingDisplay";
import {ResultComponentItemDisplay} from "../utility/resultcomponents/ResultComponentItemList";
import {PopupContext} from "../utility/TemplateGenerator";
import {Col, Container, Row} from "react-bootstrap";
import {RGB_Linear_Shade, SvgBuilding, SvgHouse, SvgLandmark, SvgShop, SvgTree} from "../utility/SvgData";
import {ScreenSizeRatio, useScreenSizes} from "../../hooks/loginHook";

export interface SettlementSVG {
    BG: Array<string>;
    Roads: Array<string>;
    RoadSpots: Array<string>;
    Landmarks: Array<string>;
    Props: Array<string>;
}
export enum EnvironmentType {
    Arctic,
    Coastal,
    Desert,
    Forest,
    Grassland,
    Hill,
    Mountain,
    Swamp,
    Underground,
    Underwater,
    Urban
}

export interface Settlement extends ReferenceObject {
    Name: Array<ResultComponent>;
    Size: SettlementSize;
    Environment: EnvironmentType;
    Purpose: Array<ResultComponent>;
    Geography: Array<ResultComponent>;
    Popular: Array<ResultComponent>;
    Leadership: Array<ResultComponent>;
    PopulationMakeup: Array<ResultComponent>;
    Districts: Array<District>;
    Factions: ResultComponentList;
    MundaneEvents: ResultComponentList;
    Exports: Array<ResultComponent>;
    Imports: Array<ResultComponent>;
    Art: SettlementSVG;
    Interactive: Array<DistrictInteractiveDisplay>;
}

export enum SettlementSize {
    Outpost,
    Hamlet, //~25
    Village, //~100
    Town, //~500
    City, //~5000
    Metropolis, //~20k
}

export interface District {
    Name: Array<ResultComponent>;
    Size: DistrictSize;
    Age: Array<ResultComponent>;
    PopulationMakeup: Array<ResultComponent>;
    Type: DistrictType;
    Locations: Array<string>;
}

export enum DistrictSize {
    Small,
    Medium,
    Large
}

export enum DistrictType {
    Central,
    Residential,
    Religious,
    Commercial,
    Administrative, //govt
    Militaristic,
    Academic,
    Theater,
    Tourism,
    Industrial, //Guilds, warehouses, etc
    Port,
}

interface DistrictInteractiveDisplay {
    index: number,
    polys: Array<string>,
    center: string,
    color: string,
    buildings: Array<string>
}

export const DEFAULT_SETTLEMENT_REF = 'blank';

export const DEFAULT_SETTLEMENT: Settlement = {
    ReferenceData: {Set:true},
    Name: [],
    PopulationMakeup: [],
    Geography: [],
    Purpose: [],
    Popular: [],
    Leadership: [],
    Factions: { List: [] },
    MundaneEvents: { List: [] },
    Size: SettlementSize.Town,
    Environment: EnvironmentType.Swamp,
    Districts: [],
    Exports: [],
    Imports: [],
    Art: {
        BG: [],
        Roads: [],
        RoadSpots: [],
        Landmarks: [],
        Props: [],
    },
    Interactive: []
}

const DEFAULT_DISTRICT_DISPLAY: DistrictInteractiveDisplay = {
    index: -1,
    polys: [],
    center: "",
    color: "",
    buildings: []
}

function SettlementDisplay(props: {settlement: string, popup: boolean}) {
    const [, updateState] = React.useState();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [districtHover, setDistrictHover] = useState<DistrictInteractiveDisplay>(DEFAULT_DISTRICT_DISPLAY);
    const [viewingDistrict, setViewingDistrict] = useState<DistrictInteractiveDisplay>(DEFAULT_DISTRICT_DISPLAY);
    const [showDistrictOverlays, setShowDistrictOverlays] = useState(false);
    const [hoveringBuilding, setHoveringBuilding] = useState("");
    const [textDisplay, setTextDisplay] = useState("");
    const [currentName, setCurrentName] = useState<string>("");
    const [pngSet, setPng] = useState(false);
    const svgRef = useRef(null);

    const screenSizeRatio = useScreenSizes();

    let mapNodeId = props.popup ? "map_node" : "map_node_popup";
    let pngHolderId = props.popup ? "map_png" : "map_png_popup";

    function SetDistrictHover(district: DistrictInteractiveDisplay) {
        setDistrictHover(district);
        if(district.index === -1) {
            setTextDisplay("");
        }
        else {
            let districtName = ConvertResultsToString(settlement.Districts[district.index]!.Name);
            if(districtName === "") {
                districtName = ConvertResultsToString(settlement.Name)
            }
            setTextDisplay(districtName + " - " + DistrictType[settlement.Districts[district.index]!.Type] + " District");
        }
    }

    const displayTypes = useContext(PopupContext);


    let settlement: Settlement = useMemo(() => GetObject(ReferenceType.Settlement, props.settlement), [referencePackage]);

    useEffect(() => {
        if(settlement.Name[0] !== undefined) {
            if(settlement.Name[0]!.text === currentName) {
                return;
            }
            else {
                setCurrentName(settlement.Name[0]!.text);
            }
        }

        setPng(false);
        SetDistrictHover(DEFAULT_DISTRICT_DISPLAY);
        setViewingDistrict(DEFAULT_DISTRICT_DISPLAY);
        setShowDistrictOverlays(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settlement]);

    useEffect(() => {
        if(!settlement.ReferenceData.Set) {
            GrabObject(ReferenceType.Settlement, props.settlement, forceUpdate);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!pngSet) {
            RefreshImage();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pngSet]);

    let settlementNameDisplay = ConvertResults(settlement.Name);

    function Districts(props: {districts: Array<District>}) {
        let val =
            props.districts.map((district, index) => {
                if(viewingDistrict.index !== -1 && viewingDistrict.index !== index) {
                    return <></>;
                }

                let shopsCount = 0;
                let shopDisplay = district.Locations.map(buildingId => {
                    let building: Building = GetObject(ReferenceType.Building, buildingId);
                    let isResidence =building.locationType[0]!.text.includes("Residence");
                    if(isResidence || !building.isShop) {
                        return <></>;
                    }

                    shopsCount++;

                    return <li>
                        <ConvertResultsDisplay data={[
                            {
                                text: building.locationName[0]!.text,
                                afterText: "",
                                beforeText: "",
                                type: buildingId,
                                children: []
                            }
                        ]} /> - <ConvertResultsDisplay data={building.locationType} />
                    </li>
                });

                let notablesCount = 0;
                let notablesDisplay = district.Locations.map(buildingId => {
                    let building: Building = GetObject(ReferenceType.Building, buildingId);
                    let isResidence =building.locationType[0]!.text.includes("Residence");
                    if(isResidence || building.isShop) {
                        return <></>;
                    }

                    notablesCount++;

                    return <li>
                        <ConvertResultsDisplay data={[
                            {
                                text: building.locationName[0]!.text,
                                afterText: "",
                                beforeText: "",
                                type: buildingId,
                                children: []
                            }
                        ]} /> - <ConvertResultsDisplay data={building.locationType} />
                    </li>
                });

                let residenceCount = 0;
                let residenceDisplay = district.Locations.map(buildingId => {
                    let building: Building = GetObject(ReferenceType.Building, buildingId);
                    let isResidence =building.locationType[0]!.text.includes("Residence");
                    if(!isResidence) {
                        return <></>;
                    }

                    residenceCount++;

                    return <li>
                        <ConvertResultsDisplay data={[
                            {
                                text: building.locationName[0]!.text,
                                afterText: "",
                                beforeText: "",
                                type: buildingId,
                                children: []
                            }
                        ]} />
                    </li>
                });

                let nameDisplay = settlement.Size === SettlementSize.Outpost || settlement.Size === SettlementSize.Hamlet || settlement.Size === SettlementSize.Village ? <>{SettlementSize[settlement.Size]}{' '}Info</> : <ConvertResultsDisplay data={district.Name} />;

                let dropDisplays = 4;
                let collapsibleId = "collapsible" + (index * dropDisplays);

                let populationDisplay = <ConvertResultsDisplay data={district.PopulationMakeup} />;
                let ageDisplay = <ConvertResultsDisplay data={district.Age} />;

                return <>
                    <div className="wrap-collabsible">
                        <input id={collapsibleId} className="toggle inputCheckNone" type="checkbox" defaultChecked />
                        <label htmlFor={collapsibleId} className="lbl-toggle-deco">{nameDisplay}</label>
                        <div className="collapsible-content-deco">
                            <div className="content-inner-deco">
                                <h2>{DistrictType[district.Type]} District</h2>
                                <a onClick={() => {
                                    for (let i = 0; i < settlement.Interactive.length; i++) {
                                        if(settlement.Interactive[i]!.index === index) {
                                            setViewingDistrict(settlement.Interactive[i]!);
                                            window.scrollTo(0, 600)
                                            break;
                                        }
                                    }
                                }} className={"fakelink"}>(Click to zoom in on map)</a >
                                <p>{ageDisplay}</p>
                                <p><b>Population: </b>{populationDisplay}</p>

                                {
                                    shopsCount > 0 ?
                                        <>
                                            <h3>Shops</h3>
                                            {shopDisplay}
                                        </>:<></>
                                }
                                {
                                    notablesCount > 0 ?
                                        <>
                                            <h3>Notable Buildings</h3>
                                            {notablesDisplay}
                                        </>:<></>
                                }
                                {
                                    residenceCount > 0 ?
                                        <>
                                            <h3>Residences</h3>
                                            {residenceDisplay}
                                        </>:<></>
                                }
                                <br />
                            </div>
                        </div>
                    </div>
                </>
            });
        return <>
            {val}
        </>
    }

    let exportsDisplay = <ConvertResultsDisplay data={settlement.Exports} />;
    let importsDisplay = <ConvertResultsDisplay data={settlement.Imports} />;

    let purposeDisplay = <ConvertResultsDisplay data={settlement.Purpose} />;
    let populationDisplay = <ConvertResultsDisplay data={settlement.PopulationMakeup} />;
    let geographyDisplay = <ConvertResultsDisplay data={settlement.Geography} />;
    let popularDisplay = <ConvertResultsDisplay data={settlement.Popular} />;
    let governmentDisplay = <ConvertResultsDisplay data={settlement.Leadership} />;

    enum IconType {
        House,
        Shop,
        Landmark
    }

    function Icon(x: number, y: number, onClick: () => any, iconType: IconType, displayOnHover: string) {
        let hoverDisplay = x + "," + y;
        let scale = 1;
        if(hoverDisplay === hoveringBuilding) {
            scale = 1.2;
        }

        let size = 20;
        let sizeDivX = 2;
        let sizeDivY = 2;
        switch (settlement.Size) {
            case SettlementSize.Outpost:
                size = 50;
                break;
            case SettlementSize.Hamlet:
                size = 50;
                break;
            case SettlementSize.Village:
                size = 30;
                sizeDivX = 1.7;
                sizeDivY = 1.7;
                break;
            case SettlementSize.Town:
                size = 20;
                break;
            case SettlementSize.City:
                size = 10;
                sizeDivX = 4;
                sizeDivY = 1.5;
                break;
            case SettlementSize.Metropolis:
                size = 8;
                sizeDivX = 1.5;
                sizeDivY = 1;
                break;

        }

        let viewBox = "0 0 224.633 224.633";
        let visual: JSX.Element = <></>;
        switch (iconType) {
            case IconType.House:
                viewBox = "0 0 224.633 224.633";
                visual = SvgHouse(scale);
                break;
            case IconType.Shop:
                size /= 2;
                viewBox = "0 0 325.313 325.313";
                visual = SvgShop(scale);
                break;
            case IconType.Landmark:
                size /= 2;
                viewBox = "0 0 321.188 350.188";
                visual = SvgLandmark(scale);
                break;
        }



        return <svg onMouseDown={onClick} onMouseEnter={() => {
            setHoveringBuilding(hoverDisplay);
            setTextDisplay(displayOnHover);
        }
        } onMouseLeave={() => {
            setHoveringBuilding("");
            setTextDisplay("")
        }
        } filter="drop-shadow(5px 5px 15px rgb(0 0 0 / 0.8))" style={{
            fill: "white"
        }} x={ (x - size/sizeDivX) +"px"} y={(y - (size/sizeDivY)) + "px"} width={size + "px"} height={size + "px"}
                    viewBox={viewBox} >
            {visual}
        </svg>;
    }


    function Tree(x: number, y: number, rotation: number, scalar: number, colorVariance: number, treeColor: string) {
        let baseSize = 100;
        switch (settlement.Size) {
            case SettlementSize.Outpost:
            case SettlementSize.Hamlet:
                baseSize = 500;
                break;
            case SettlementSize.Village:
                baseSize = 400;
                break;
            case SettlementSize.Town:
                baseSize = 250;
                break;
            case SettlementSize.City:
                baseSize = 150;
                break;
            case SettlementSize.Metropolis:
                baseSize = 100;
                break;
        }

        let size = baseSize * scalar;

        x -= size/4;
        y -= size/4;

        let translateX = 70;
        let translateY = 60;

        return SvgTree(x, y, size, translateX, translateY, rotation, colorVariance, treeColor);
    }

    function Building(x: number, y: number, rotation = 0, scalarX = 1, scalarY = 1, lightShade = 0, darkShade = 0, colorBase = "115,73,34") {
        let width = 75;
        let height = 40;

        let scale = 0.7;
        switch (settlement.Size) {
            case SettlementSize.Outpost:
                scale = 0.9;
                break;
            case SettlementSize.Hamlet:
                scale = 0.7;
                break;
            case SettlementSize.Village:
                scale = 0.5;
                break;
            case SettlementSize.Town:
                scale = 0.35;
                break;
            case SettlementSize.City:
                scale = 0.2;
                break;
            case SettlementSize.Metropolis:
                scale = 0.1;
                break;

        }

        width *= scale * scalarX;
        height *= scale * scalarY;

        switch (settlement.Size) {
            case SettlementSize.Outpost:
            case SettlementSize.Hamlet:
            case SettlementSize.Village:
                x -= (width/2.5);
                y -= (height/2.5);
                break;
            case SettlementSize.Town:
                x -= (width/4);
                y -= (height/4);
                break;
            case SettlementSize.City:
                break;
            case SettlementSize.Metropolis:
                break;

        }


        let translateX = (width/2) + x;
        let translateY = (height/2) + y;

        let id = "building-" + x + "-" + y;


        let strokeWidth = 3;
        let shadowFilter = "";
        switch (settlement.Size) {
            case SettlementSize.Outpost:
            case SettlementSize.Hamlet:
            case SettlementSize.Village:
                strokeWidth = 3;
                shadowFilter = "drop-shadow(3px 3px 6px rgb(0 0 0 / 0.8))";
                break;
            case SettlementSize.Town:
                strokeWidth = 2;
                shadowFilter = "drop-shadow(3px 3px 3px rgb(0 0 0 / 0.6))";
                break;
            case SettlementSize.City:
                strokeWidth = 0.5;
                shadowFilter = "drop-shadow(3px 3px 3px rgb(0 0 0 / 0.6))";
                break;
            case SettlementSize.Metropolis:
                strokeWidth = 0.5;
                shadowFilter = "drop-shadow(3px 3px 3px rgb(0 0 0 / 0.6))";
                break;

        }
        return SvgBuilding(x, y, width, height, translateX, translateY, rotation, strokeWidth, shadowFilter, lightShade, darkShade, colorBase, id);
    }

    function Factions(props: {factions: ResultComponentList}) {
        let factions = props.factions;
        const displayedFactions = factions.List.length === 0 || factions.List[0]!.result.length === 0 ?
            <>
            </> :
            factions.List.map(faction =>
            {
                return (
                    <ul className="alignLeft">
                        <ResultComponentItemDisplay itemList={faction} />
                    </ul>
                );
            });

        return <>
            {displayedFactions}
        </>
    }

    function MundaneEvents(props: {events: ResultComponentList}) {
        let events = props.events;

        if(events === undefined) {
            return <></>
        }

        const displayedEvents = events.List.length === 0 || events.List[0]!.result.length === 0 ?
            <>
            </> :
            events.List.map(event =>
            {
                return (
                    <ul className="alignLeft">
                        <ResultComponentItemDisplay itemList={event} />
                    </ul>
                );
            });

        return <>
            {displayedEvents}
        </>
    }


    let lines = "";
    for (let i = 0; i < settlement.Art.Roads.length; i++) {
        lines += settlement.Art.Roads[i]!;
    }

    let landmarkDisplay = settlement.Art.Landmarks.map((landmark, index) =>
    {
        let landmarkSplit = landmark.split(',');

        let x = parseInt(landmarkSplit[0]!) - 5;
        let y = parseInt(landmarkSplit[1]!) - 5;
        let rotation = parseInt(landmarkSplit[2]!);
        let scaleX = parseFloat(landmarkSplit[3]!);
        let scaleY = parseFloat(landmarkSplit[4]!);
        let lightShade = parseFloat(landmarkSplit[5]!);
        let darkShade = parseFloat(landmarkSplit[6]!);
        let color = parseInt(landmarkSplit[7]!) + "," + parseInt(landmarkSplit[8]!) + "," + parseInt(landmarkSplit[9]!);

        return Building(x, y, rotation, scaleX, scaleY, lightShade, darkShade, color);
    });

    let roadWidth = 40;

    switch (settlement.Size) {
        case SettlementSize.Outpost:
        case SettlementSize.Hamlet:
            roadWidth = 40;
            break;
        case SettlementSize.Village:
            roadWidth = 30;
            break;
        case SettlementSize.Town:
            roadWidth = 10;
            break;
        case SettlementSize.City:
            roadWidth = 10;
            break;
        case SettlementSize.Metropolis:
            roadWidth = 4;
            break;

    }

    let roadColor = "rgb(199,191,177)";//stone: "rgb(80,80,80)"
    if(settlement.Size === SettlementSize.City || settlement.Size === SettlementSize.Metropolis) {
        roadColor = "rgb(150,150,150)";
    }

    let roadsDisplay = settlement.Art.Roads.map((road, index) => {
        let path = road;
        return <path filter="drop-shadow(1px 1px 5px rgb(0 0 0 / 0.4)) drop-shadow(-1px -1px 2px rgb(0 0 0 / 0.4))" d={path} stroke={roadColor} stroke-width={roadWidth} fill={"none"} />;
    })
    let roadCornerDisplay = settlement.Art.RoadSpots.map((spot, index) =>
    {
        let spotSplit = spot.split(',');

        let scale = parseFloat(spotSplit[2]!);
        let variance = parseFloat(spotSplit[3]!);
        let col = RGB_Linear_Shade(variance, roadColor.replace("rgb(", "").replace(")", ""));
        // console.log(roadColor)

        return <circle cx={spotSplit[0]!} cy={spotSplit[1]!} r={(roadWidth*0.5) * scale} fill={col} />;
    });

    let bgDisplay = settlement.Art.BG.map((poly, index) => {
        let data = poly.split(":");
        let settingsSplit = data[0]!.split(",");

        let baseColor = parseInt(settingsSplit[0]!) + "," + parseInt(settingsSplit[1]!) + "," + parseInt(settingsSplit[2]!);
        let variance = parseFloat(settingsSplit[3]!)

        let col = RGB_Linear_Shade(variance, baseColor);

        let borders = data[1];
        return <path d={borders} stroke={col} fill={col} />;
    })

    let propDisplay = settlement.Art.Props.map((prop, index) => {
        let propSplit = prop.split(",");

        let x = parseInt(propSplit[1]!);
        let y = parseInt(propSplit[2]!);
        let rot = parseInt(propSplit[3]!);
        let scale = parseFloat(propSplit[4]!);
        let variance = parseFloat(propSplit[5]!);
        let treeColor = parseInt(propSplit[6]!) + "," + parseInt(propSplit[7]!) + "," + parseInt(propSplit[8]!);

        // let baseColor = parseInt(settingsSplit[0]!) + "," + parseInt(settingsSplit[1]!) + "," + parseInt(settingsSplit[2]!);
        // let variance = parseFloat(settingsSplit[3]!)
        //
        // let col = RGB_Linear_Shade(variance, baseColor);

        //return <span className="fakeClass"></span>;
        return Tree(x, y, rot, scale, variance, treeColor);
    });

    let interactiveDistricts = settlement.Interactive.map((districtInfo) => {
        let bordersDisplay = districtInfo.polys.map((polyPoints) => {
            return <path onMouseDown={() => {
                setViewingDistrict(districtInfo);
                SetDistrictHover(DEFAULT_DISTRICT_DISPLAY);
                setShowDistrictOverlays(false);
            }
            } onMouseEnter={() => SetDistrictHover(districtInfo)} onMouseLeave={() => SetDistrictHover(DEFAULT_DISTRICT_DISPLAY)} d={polyPoints}
                         fill={districtInfo.color} opacity={0.5} />; //stroke={"black"} stroke-width={1}
        });

        function TypeToIconType(val: string): IconType {
            if(val.includes("Residence")) {
                return IconType.House;
            }

            if(val.includes("Temple") ||
                val.includes("Library") ||
                val.includes("Library") ||
                val.includes("Museum") ||
                val.includes("School") ||
                val.includes("Guardhouse") ||
                val.includes("Courthouse") ||
                val.includes("Town Hall")
            ) {
                return IconType.Landmark;
            }

            return IconType.Shop;
        }

        let buildingsDisplay = districtInfo.buildings.map((buildingInfo) => {
            let buildingInfoSplit = buildingInfo.split(",");
            let buildingX = parseFloat(buildingInfoSplit[0]!);
            let buildingY = parseFloat(buildingInfoSplit[1]!);
            let buildingId = buildingInfoSplit[2]!;

            let building: Building = GetObject(ReferenceType.Building, buildingId);
            let typeString = ConvertResultsToString(building.locationType);

            return Icon(buildingX, buildingY, () => {
                let types = [...displayTypes!.types!];
                types.push({
                    type: buildingId,
                    data: undefined
                });
                displayTypes!.setTypes!(types);
            },
                TypeToIconType(typeString),
                ConvertResultsToString(building.locationName) + " - " + typeString);
        });


        let centerSplit = districtInfo.center.split(",");
        let centerX = parseFloat(centerSplit[0]!);
        let centerY = parseFloat(centerSplit[1]!);

        return <>
            {
                showDistrictOverlays ?
                    <>
                        {bordersDisplay}
                    </>
                    :
                    <></>
            }
            {
                viewingDistrict.index === districtInfo.index ?
                    <>
                        {buildingsDisplay}
                    </>
                    :
                    <></>
            }
        </>
    });

    let currentZoom = 500;
    let pictureX = 0;
    let pictureY = 0;

    if(viewingDistrict.index !== -1) {
        currentZoom = 150;
        let centerSplit = viewingDistrict.center.split(",");
        let centerX = parseFloat(centerSplit[0]!);
        let centerY = parseFloat(centerSplit[1]!);
        pictureX = centerX - currentZoom/2;
        pictureY = centerY - currentZoom/2 - 10;
        let lowestX = parseFloat(centerSplit[2]!);
        let lowestY = parseFloat(centerSplit[3]!);
        let highestX = parseFloat(centerSplit[4]!) - lowestX;
        let highestY = parseFloat(centerSplit[5]!) - lowestY;

        pictureX = lowestX;
        pictureY = lowestY;
        if(highestX > highestY) {
            currentZoom = highestX;
        }
        else {
            currentZoom = highestY;
        }
        // currentZoom = highestX;

        if(pictureY + currentZoom > 500) {
            pictureY += 500 - (currentZoom + pictureY);
        }

        if(pictureX + currentZoom > 500) {
            pictureX += 500 - (currentZoom + pictureX);
        }

        if(pictureY + currentZoom + 3 < 500 && highestY < currentZoom) {
            pictureY -= (currentZoom - highestY) / 2;
        }
        if(pictureX + currentZoom + 3 < 500 && highestX < currentZoom) {
            pictureX -= (currentZoom - highestX) / 2;
        }

        if(pictureY < 0) {
            pictureY = 0;
        }
        if(pictureX < 0) {
            pictureX = 0;
        }

        if(pictureX + currentZoom > 500) {
            pictureX = 500 - currentZoom;
        }
        if(pictureY + currentZoom > 500) {
            pictureY = 500 - currentZoom;
        }
    }

    function RefreshImage() {
        const map = document.getElementById(mapNodeId)!;

        if(map == null){
            return;
        }

        const $svg = map.querySelector('svg')!
        const $holder = document.getElementById(pngHolderId)!

        const destroyChildren = ($element: any) => {
            while ($element.firstChild) {
                const $lastChild = $element.lastChild ?? false
                if ($lastChild) $element.removeChild($lastChild)
            }
        }

        const loadImage = async (url: string) => {
            const $img = document.createElement('img')
            $img.src = url
            return new Promise((resolve, reject) => {
                $img.onload = () => resolve($img)
                $img.onerror = reject
            })
        }
        const convertSVGtoImg = async () => {
            // const $btn = e.target
            const format = 'png'


            const svgAsXML = (new XMLSerializer()).serializeToString($svg)
            const svgData = `data:image/svg+xml,${encodeURIComponent(svgAsXML)}`

            const img: any = await loadImage(svgData)

            const $canvas = document.createElement('canvas')
            $canvas.width = $svg.clientWidth
            $canvas.height = $svg.clientHeight
            $canvas.getContext('2d')!.drawImage(img, 0, 0, $svg.clientWidth, $svg.clientHeight)

            const dataURL: string = await $canvas.toDataURL(`image/${format}`, 1.0);

            // console.log(dataURL)
            if(dataURL === "data:,") {
                return;
            }
            else {
                setPng(true)
                // setShouldShowSvg(false);
            }
            destroyChildren($holder)

            const $img = document.createElement('img')
            $img.src = dataURL
            $holder.appendChild($img)

            let button = document.getElementById('utilButton2')!;
            if(button != null) {
                // console.log("style " + button.style.display);
                button.style.display = "";
                let buttonClone = button.cloneNode(true)!;
                buttonClone.addEventListener("click", () => {
                    if(viewingDistrict.index === -1) {
                        setShowDistrictOverlays(!showDistrictOverlays);
                    }
                    else {
                        setViewingDistrict(DEFAULT_DISTRICT_DISPLAY);
                    }
                })
                button.style.display = "none";
                $holder.appendChild(buttonClone);
            }
        }


        const timer = setTimeout(() => {
            (async () => {
                await convertSVGtoImg();
            })();

            // setShouldShowSvg(false);
        }, 500);

        return () => {

        };
    }

    let infoButton = <button id="utilButton2" onClick={() => {
        if(viewingDistrict.index === -1) {
            setShowDistrictOverlays(!showDistrictOverlays);
        }
        else {
            setViewingDistrict(DEFAULT_DISTRICT_DISPLAY);
        }
    }} style={{
        position: "relative",
        zIndex: 2,
        width: "25px",
        height: "25px",
        left:  "-25px",
        bottom: "-242px",
        alignContent: "center",
        display: "none"
    }}>
        <svg id="search-icon" className="search-icon" viewBox="0 0 25 25" width={25} height={25} x={-20} style={{
            position: "relative",
            top: "-0.2em",
            right: "0.2em",
            flexShrink: 0,
            height: "1em",
            width: "1em",
        }}>
            {
                viewingDistrict.index !== -1 ?
                    <path d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"/>
                    :
                    <>
                        <path
                            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                        <path d="M0 0h24v24H0z" fill="none"/>
                    </>
            }
        </svg></button>;

    // const [shouldShowSvg, setShouldShowSvg] = useState(true);

    let shouldShowSvg = !pngSet || viewingDistrict.index !== -1 || showDistrictOverlays;

    // console.log(shouldShowSvg)

    let imgSize = screenSizeRatio === ScreenSizeRatio.Full ? "500px" : "300px";

    return(
        <>
            <h1>{settlementNameDisplay}</h1>

            <h5>{SettlementSize[settlement.Size]}</h5>
            <h6>{purposeDisplay}</h6>
            <p><b>Leadership: </b>{governmentDisplay}</p>
            <Container>
                <Row>
                    <Col>
                        <p><b>Population: </b>{populationDisplay}</p>
                        <p><b>Geography: </b>{geographyDisplay}</p>
                    </Col>
                    <Col>
                        <p><b>Exports: </b> {exportsDisplay}<br />
                            <b>Imports: </b> {importsDisplay}
                        </p>
                        <p><b>Hot Spots: </b>{popularDisplay}</p>
                    </Col>
                </Row>
            </Container>
            <Factions factions={settlement.Factions} />
            <MundaneEvents events={settlement.MundaneEvents} />
            <div id={pngHolderId} style={{
                display: shouldShowSvg ? "none" : "block"
            }}>
            </div>
            {infoButton}
            {
                shouldShowSvg ?
                    <div id={mapNodeId} className="container alignImg shutter">
                        <div className="img" ref={svgRef}>
                            <svg width={imgSize} height={imgSize} >
                                <svg className="map" width={imgSize} height={imgSize} viewBox={pictureX + " " + pictureY + " " + currentZoom + " " + currentZoom} style={{
                                    zIndex: 1
                                }}>
                                    <rect x="0" y="0" width="500" height="500" style={{
                                        fill: "#637058",
                                    }} />
                                    {bgDisplay}
                                    {roadsDisplay}
                                    {roadCornerDisplay}
                                    {landmarkDisplay}
                                    {propDisplay}
                                    {interactiveDistricts}
                                </svg>
                                <text filter="drop-shadow(3px 3px 2px rgb(0 0 0 / 0.8)) drop-shadow(-1px -3px 2px rgb(0 0 0 / 0.8))" textAnchor={"start"} x={5} y={25} className="districtText">{textDisplay}</text>
                            </svg>
                            <button id="utilButton" onClick={() => {
                                if(viewingDistrict.index === -1) {
                                    setShowDistrictOverlays(!showDistrictOverlays);
                                }
                                else {
                                    setViewingDistrict(DEFAULT_DISTRICT_DISPLAY);
                                }
                            }} style={{
                                position: "relative",
                                zIndex: 2,
                                width: "25px",
                                height: "25px",
                                left:  "-25px",
                                bottom: "-242px",
                                alignContent: "center"
                            }}>
                                <svg id="search-icon" className="search-icon" viewBox="0 0 25 25" width={25} height={25} x={-20} style={{
                                    position: "relative",
                                    top: "-0.2em",
                                    right: "0.2em",
                                    flexShrink: 0,
                                    height: "1em",
                                    width: "1em",
                                }}>
                                    {
                                        viewingDistrict.index !== -1 ?
                                            <path d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
		c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
		C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
		c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
		C26.18,21.891,26.141,21.891,26.105,21.891z"/>
                                            :
                                            <>
                                                <path
                                                    d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                                                <path d="M0 0h24v24H0z" fill="none"/>
                                            </>
                                    }
                                </svg></button>
                        </div>
                    </div>
                    : <></>
            }
            <Districts districts={settlement.Districts} />
        </>
    );
}

export default SettlementDisplay;
