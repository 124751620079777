import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation'
import Card from 'react-bootstrap/Card';
import MonsterGenerator from "./MonsterGenerator";
import Nav from 'react-bootstrap/Nav';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { JsxElement } from 'typescript';
import {ConvertResults, ConvertResultsDisplay, DEFAULT_RESULT_ARRAY, ResultComponent} from "../utility/resultcomponents/ResultComponent";
import TemplateGenerator from "../utility/TemplateGenerator";
import {Meta} from "../utility/MetaWrapper";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";
import HistoryDisplay, {Historical} from "../simple/HistoryGenerator";

export interface Item extends Historical {
    Name: Array<ResultComponent>,
    Desc: Array<ResultComponent>
}

export const DEFAULT_ITEM: Item = {
    Name: [],
    Desc: [],
    History: []
}

function ItemDisplay(props: { item: Item }) {

    return(
        <div>
            <h2><ConvertResultsDisplay data={props.item.Name} /></h2>
            <p><ConvertResultsDisplay data={props.item.Desc} /></p>
            <HistoryDisplay history={props.item} />
        </div>);
}

export default ItemDisplay;
