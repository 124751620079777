import React, {useEffect, useState} from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation'
import {
    ConvertResultsDisplay,
    DEFAULT_RESULT_ARRAY, DEFAULT_RESULT_COMPONENT_COLLECTION,
    ResultComponent,
    ResultComponentCollection, ResultComponentCollectionDisplay
} from "./resultcomponents/ResultComponent";
import axios from "axios";
import {SaveToProfileButton} from "./saving/SaveToProfile";
import {SetGenericRCData, SetReferencePackage} from "./architecture/ReferenceIds";
import WikiDisplay from "./WikiDisplay";
import * as BS from "react-bootstrap";
import {PopupDisplayHandler} from "./architecture/PopupDisplayHandler";
import {LoadingDisplay} from "../../LoadingDisplay";
import {GenSettings} from "./architecture/GenSettings";
import {ScreenSizeRatio, useIsLoggedIn, useScreenSizes} from "../../hooks/loginHook";
import AdvancedDropdown, {ConvertForDropdown} from "./AdvancedDropdown";

export interface PopupTypeInfo {
    type: string,
    data: any
}

export interface PopupContextInterface {
    types: Array<PopupTypeInfo>,
    setTypes: (data: Array<PopupTypeInfo>) => any
}

export const PopupContext = React.createContext<PopupContextInterface>({
    types: [],
    setTypes: () => {}
});

function TemplateGenerator(props: {
    name: string,
    url: string,
    settingsComponent?: JSX.Element,
    customSaveData?: () => void,
    customSaveDisplay?: Array<ResultComponent>,
    additionalUrl?: string,
    customDataHandling?: (data: any) => void,
    componentReplacement?: JSX.Element,
    extraComponent?: JSX.Element,
    onGenerateButtonClicked?: () => void,
    hideAdjectivesOption?: boolean,
    extraDataForResultsDisplay?: string,
    fetchMethod?: string,
    showRaceSetting?: boolean,
    titleOverride?: string,
    isResultComponentCollection?: boolean,
    multiple?: boolean,
    setMultipleCount?: (val: number) => void,
}) {
    const [resultComponentDisplay, setResultComponentDisplay] = useState([DEFAULT_RESULT_ARRAY]);
    const [resultComponentCollectionDisplay, setResultComponentCollectionDisplay] = useState(DEFAULT_RESULT_COMPONENT_COLLECTION);
    const [multipleCountToGenerate, setMultipleCountToGenerate] = useState(1);

    const [useAdjectives, setUseAdjectives] = useState(true);
    const [raceNames, setRaceNames] = useState(['']);
    const [selectedRace, setSelectedRace] = useState('Random');
    const [selectedRaceOptions, setSelectedRaceOptions] = useState(['Random']);

    const [displayTypes, setDisplayTypes] = useState<Array<PopupTypeInfo>>([]);

    const [showSaveTooltip, setShowSaveTooltip] = useState(false);
    const [loading, setLoading] = useState(false);


    const loggedIn = useIsLoggedIn();
    const [currentSettingsSelection, setCurrentSettingsSelection] = useState('Default');
    const [settingProfiles, setSettingsProfiles] = useState(['Default']);
    const [settingProfilesMapped, setSettingProfilesMapped] = useState<Map<string, GenSettings>>(new Map<string, GenSettings>());

    const screenSizeRatio = useScreenSizes();

    function GetSelectedRace() {
        let selected = selectedRaceOptions[Math.floor(Math.random() * selectedRaceOptions.length)];
        return selected;
    }

    function UpdateSelected() {
        setSelectedRace(GetSelectedRace()!);
    }

    useEffect(() => {
        UpdateSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRaceOptions]);

    function UpdateRaceList() {
        fetch(URL+"/Races",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let names: Array<string> = data;

            setRaceNames(names);

        });
    }

    function UpdateSettingProfiles() {
        if(!loggedIn) {
            return;
        }

        const getSavedCreation = async () => {
            const response = await axios.get(URL + '/GetSavedGenerics', { withCredentials: true, params: { typename: 'settings' } }).catch((e) => {
                console.log(e);
            });

            if(response && response.data){
                let dataMap = new Map<string, GenSettings>();
                let list: Array<string> = ['Default'];
                for(let i = 0; i < response.data.components.length; i++) {
                    let val = JSON.parse(response.data.components[i]);
                    list.push(val.configName);
                    dataMap.set(val.configName, val.data);
                }

                setCurrentSettingsSelection('Default');

                setSettingsProfiles(list);
                setSettingProfilesMapped(dataMap);
            }
        }

        getSavedCreation().catch((e) => {console.log(e);});
    }

    function GetNewDisplay() {
        if(loading) {
            return;
        }

        UpdateSelected();
        if(props.onGenerateButtonClicked !== undefined) {
            props.onGenerateButtonClicked();
        }

        let extraUrl = "";
        if(props.additionalUrl != null) {
            extraUrl = props.additionalUrl;
        }

        setLoading(true);
        fetch(URL + "/" + props.url + "?adjective=" + useAdjectives +
            "&generationCount=" + multipleCountToGenerate +
            (props.showRaceSetting ? "&race=" + selectedRace : "") +
            (loggedIn ? "&settingsProfile=" + currentSettingsSelection : "")
            + extraUrl,
            {
                method: props.fetchMethod == null ? 'GET' : props.fetchMethod,
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            })
            .then(res => res.json()).then((data) => {

                if(props.customDataHandling == null) {
                    SetGenericRCData(data.data);
                }
                else {
                    SetGenericRCData([]);
                }

                if(data.refPackage !== undefined) {
                    SetReferencePackage(data.refPackage);
                }

                if(props.customDataHandling == null) {
                    if(props.isResultComponentCollection) {
                        setResultComponentCollectionDisplay(data.data);
                    }
                    else {
                        if(props.multiple) {
                            setResultComponentDisplay(data.data);
                        }
                        else {
                            setResultComponentDisplay([data.data]);
                        }
                    }
                }
                else {
                    props.customDataHandling(data);
                }

                setLoading(false);
            });
    }

    function setUseAdjectivesInput(selected: React.ChangeEvent<HTMLInputElement>) {
        setUseAdjectives(!useAdjectives);
    }

    function SaveTemplateToProfile(){

        setShowSaveTooltip(true);
        setTimeout(function () {
            setShowSaveTooltip(false);
        }, 3000);

        if(props.customSaveData != null) {
            props.customSaveData();
        }
        else {
            if(props.isResultComponentCollection) {
                const saveTemplate = async () => {
                    const response = await axios.put(URL + '/SaveComponent?typename=collection', { data: resultComponentCollectionDisplay }, {withCredentials: true }).catch((e) => {
                        console.log(e);
                    });
                }

                saveTemplate().catch((e) => {console.log(e);});
            }
            else {
                if(props.multiple && props.customSaveDisplay == null){
                    for (let i = 0; i < resultComponentDisplay.length; i++) {
                        const saveTemplate = async () => {
                            const response = await axios.put(URL + '/SaveResultComponent', { data: { type: props.name, component: resultComponentDisplay[i] } }, {withCredentials: true }).catch((e) => {
                                console.log(e);
                            });
                        }

                        saveTemplate().catch((e) => {console.log(e);});
                    }
                }
                else {
                    const saveTemplate = async () => {
                        const response = await axios.put(URL + '/SaveResultComponent', { data: { type: props.name, component: props.customSaveDisplay != null ? props.customSaveDisplay : resultComponentDisplay } }, {withCredentials: true }).catch((e) => {
                            console.log(e);
                        });
                    }

                    saveTemplate().catch((e) => {console.log(e);});
                }
            }
        }

    }

    useEffect(() => {
        GetNewDisplay();
        UpdateRaceList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if(loggedIn) {
            UpdateSettingProfiles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn]);

    let spacing = (<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>);

    function setProfile(selected: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentSettingsSelection(selected.target.value);

        setUseAdjectives(settingProfilesMapped.get(selected.target.value)!.useAdjectives);
    }
    let settingProfileOptions = settingProfiles.map((name, index) => <option value={name}>{name}</option>);

    // function MainDisplay() {
    //     return ();
    // }

    let rcDisplay = resultComponentDisplay.map((rc) => <li><ConvertResultsDisplay data={rc} extraData={props.extraDataForResultsDisplay} /></li>)

    let mainComp = <div>
        {
            props.titleOverride !== undefined ?
                <h1>{props.titleOverride}</h1> :
                <h1>{props.name} Generator</h1>
        }
        {
            !loggedIn &&
            !props.showRaceSetting &&
            props.hideAdjectivesOption &&
            props.settingsComponent == null
                ?
                <></> :
                <h3>Settings</h3>
        }
        {
            loggedIn ?
                <>
                    <b>Settings Profile </b>
                    <select onChange={setProfile}>
                        {settingProfileOptions}
                    </select>
                    <br />
                    <br />
                </> :
                <></>
        }

        {
            props.showRaceSetting ?
                <>
                    <h4>Heritage</h4>
                    <AdvancedDropdown options={ConvertForDropdown(raceNames)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedRaceOptions} />
                    <br />
                </>
                :
                <></>
        }
        {
            props.settingsComponent == null ?
                <>
                </>
                :
                <>
                    {props.settingsComponent}
                    <br />
                </>
        }
        {
            props.hideAdjectivesOption ? <></> :
                <>
                    <label>
                        Use Adjectives:
                        <input
                            className="checkbox"
                            name="isGoing"
                            type="checkbox"
                            checked={useAdjectives}
                            onChange={setUseAdjectivesInput} />
                    </label>
                    <br />
                    <br />
                </>
        }
        {
            props.multiple ? <>
                    <label>
                        Number to Generate:{' '}
                        <select onChange={(c) =>
                        {
                            let val: number = parseInt(c.target.value)!;
                            if(props.setMultipleCount !== undefined) {
                                props.setMultipleCount(val);
                            }
                            setMultipleCountToGenerate(val)
                        }}>
                            <option key={1} value={1}>1</option>
                            <option key={5} value={5}>5</option>
                            <option key={10} value={10}>10</option>
                            <option key={25} value={25}>25</option>
                        </select>
                    </label>
                    <br />
                    <br />
            </>
                :
                <></>
        }

        <div style={{justifyContent: 'center', display: "flex"}}>
            <div className="templateButtonLeft">
                <Button className="templateButton" onClick={GetNewDisplay}>Generate New {props.name}</Button>
                <br />
                {
                    showSaveTooltip ?
                        <br /> : <></>
                }
            </div>
            <SaveToProfileButton showSaved={showSaveTooltip} className="templateButtonRight" onClick={SaveTemplateToProfile} />
        </div>
        {
            showSaveTooltip ?
                <></> :
                <br />
        }
        <br />
        {
            props.extraComponent != null ?
                <>
                    {props.extraComponent}
                </>
                :
                <></>
        }
        <h3>{props.name}</h3>
        {
            loading ?
                <LoadingDisplay />
                :
                props.componentReplacement != null ?
                    <>{props.componentReplacement}</>
                    :
                    props.isResultComponentCollection ?
                        <ResultComponentCollectionDisplay data={resultComponentCollectionDisplay} />
                        :
                        props.multiple && resultComponentDisplay.length > 1 ?
                            <ol>
                                {rcDisplay}
                            </ol>
                            :
                            <p><ConvertResultsDisplay data={resultComponentDisplay[0]!} extraData={props.extraDataForResultsDisplay} /></p>
        }
    </div>;

    return(
        <>
            <PopupContext.Provider value={{
                types: displayTypes,
                setTypes: setDisplayTypes
            }}>
            <PopupDisplayHandler />
                {
                    screenSizeRatio === ScreenSizeRatio.Full
                    ?
                        <BS.Container fluid>
                            <BS.Row>
                                <BS.Col sm={9}>
                                    {mainComp}
                                </BS.Col>
                                <BS.Col sm={3}>
                                    <WikiDisplay />
                                </BS.Col>
                            </BS.Row>
                        </BS.Container>
                        :
                        <>
                            {mainComp}
                            <WikiDisplay />
                        </>
                }
            </PopupContext.Provider>
        </>
        );
}

export default TemplateGenerator;
