import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation';
import {Meta} from "../utility/MetaWrapper";
import TemplateGenerator from "../utility/TemplateGenerator";

function VoiceGenerator() {

    const [voice, setVoice] = useState('bob');


    function ChangeVoice() {
        fetch(URL+"/GetVoice",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {
                setVoice(data.voice);
            });
    }

    useEffect(() => {
        ChangeVoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div>
            <Meta pageName="Voice Generator" desc="A TTRPG Voice Generator to generate an interesting and unique voice pattern for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/voicegenerator" />
            <TemplateGenerator hideAdjectivesOption={true} name="Voice" url="GetVoice" multiple />
        </div>);
}

export default VoiceGenerator;
