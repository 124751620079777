export function RandomBetweenGeneric<T>(options: Array<T>): T {
    return options[Math.floor(Math.random() * options.length)]!;
}

export function Lerp(a: number, b: number, t: number): number {
    return (1 - t) * a + t * b;
}

export function CapitalizeFirstLetter(val: string) {
    return val.charAt(0).toUpperCase() + val.slice(1);
}
