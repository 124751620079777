import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation'
import Card from 'react-bootstrap/Card';
import MonsterGenerator from "../complex/MonsterGenerator";
import Nav from 'react-bootstrap/Nav';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { JsxElement } from 'typescript';
import {ConvertResults, DEFAULT_RESULT_ARRAY} from "../utility/resultcomponents/ResultComponent";
import TemplateGenerator from "../utility/TemplateGenerator";
import {Meta} from "../utility/MetaWrapper";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";

function FoodOrDrinkGenerator() {
    const [foodDrinkOptions, setFoodDrinkOptions] = useState(['']);
    const [selectedFoodDrinkOptions, setSelectedFoodDrinkOptions] = useState(['Random']);
    const [selectedFoodDrink, setSelectedFoodDrink] = useState('Random');

    function GetSelectedFoodDrink() {
        return selectedFoodDrinkOptions[Math.floor(Math.random() * selectedFoodDrinkOptions.length)];
    }

    function UpdateSelected() {
        setSelectedFoodDrink(GetSelectedFoodDrink()!);
    }

    useEffect(() => {
        UpdateSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFoodDrinkOptions]);

    function UpdateItemList() {
        fetch(URL+"/GetFoodOrDrinkTypes",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let items: Array<string> = data;

            setFoodDrinkOptions(items);

        });
    }

    useEffect(() => {
        UpdateItemList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div>
            <Meta pageName="Food/Drink Generator" desc="A TTRPG Food/Drink Generator that generates unique food and drink combinations for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/foodrinkgenerator" />
            <TemplateGenerator onGenerateButtonClicked={UpdateSelected} hideAdjectivesOption={true} name="Food/Drink" url="GetFoodOrDrink" multiple additionalUrl={"&fooddrink=" + selectedFoodDrink} settingsComponent={
                <div>
                    <h4>Food/Drink Type</h4>
                    <AdvancedDropdown options={ConvertForDropdown(foodDrinkOptions)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedFoodDrinkOptions} />
                </div>
            } />
        </div>);
}

export default FoodOrDrinkGenerator;
