import React, {useState} from "react";
import Table from "react-bootstrap/Table";
import {DisplayStatblock, StatBlockScores} from "../utility/StatBlock"
import {CapitalizeFirstLetter} from "../utility/Utils";

export interface Appearance {
    hair: string;
    eyeColor: string;
    height: string;
    weight: string;
    skinDesc: string;
    age: string;
    clothingStyle: string;
    accessories: string;
    injuries: string;
}

export const DEFAULT_APPEARANCE = {
    hair: '',
    eyeColor: '',
    height: '',
    weight: '',
    skinDesc: '',
    age: '',
    clothingStyle: '',
    accessories: '',
    injuries: '',
};

export function AppearanceToString(props: {currentAppearance: Appearance, race: string}) {

    const currentAppearance = props.currentAppearance;

    return CapitalizeFirstLetter(currentAppearance.height) +
        (currentAppearance.weight === '' ? "" : ", " + currentAppearance.weight) +
        ", " + currentAppearance.skinDesc + " " + props.race.toLowerCase() + ", with " + currentAppearance.eyeColor + "eyes, they seem to be " +
        currentAppearance.age + "." + (currentAppearance.hair === '' ? "" : " They have " + currentAppearance.hair) + " They're wearing " + currentAppearance.clothingStyle +
        (currentAppearance.accessories === '' ? "." : ", and have " + currentAppearance.accessories + ".") +
        (currentAppearance.injuries === '' ? "" : " They also have " + currentAppearance.injuries + ".");
}

function AppearanceDisplay(props: {currentAppearance: Appearance, race: string}) {

    return(<p>
        {AppearanceToString({currentAppearance: props.currentAppearance, race: props.race})}
        </p>);
}

export default AppearanceDisplay;
