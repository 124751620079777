import React, {useEffect, useState} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import {Meta} from "../MetaWrapper";
import axios from "axios";
import {Button, Card, Col, Container, Modal, Row} from "react-bootstrap";
import {ConvertResultsDisplay} from "../resultcomponents/ResultComponent";
import URL from "../../../BackendLocation";

function SavableCreation(props: {
    creationName: string,
    genericComponentTypeName?: string,
    defaultData: any,
    modalDisplay: (display: any) => JSX.Element,
    cardDisplay: <T extends Array<any>>(onDisplayClick: (val: any) => void, x: T) => Array<JSX.Element>,
    handleData: (data: any) => Array<any>,
    onDelete: () => void
}) {

    const [creationList, setCreationList] = useState([props.defaultData]);
    const [showCreation, setShowCreation] = useState(false);
    const [creationDisplay, setCreationDisplay] = useState(props.defaultData);
    const handleCloseCreation = () => setShowCreation(false);
    const handleShowCreation = () => setShowCreation(true);


    useEffect(() => {
        GetSavedCreations();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let extraTypeName = props.genericComponentTypeName ? "?typename=" + props.genericComponentTypeName : "";
    
    function GetSavedCreations() {
        const getSavedCreation = async () => {
            const response = await axios.get(URL + '/GetSaved' + props.creationName + 's' + extraTypeName, {withCredentials: true }).catch((e) => {
                console.log(e);
            });

            if(response && response.data){
                let list: Array<any> = props.handleData(response.data);

                setCreationList(list);
            }
        }

        getSavedCreation().catch((e) => {console.log(e);});
    }
    
    function DeleteDisplayedCreation() {
        const deleteCreation = async () => {
            const response = await axios.put(URL + '/DeleteSaved' + props.creationName + extraTypeName, { creation: creationDisplay }, {withCredentials: true }).catch((e) => {
                console.log(e);
            });
        }

        deleteCreation().catch((e) => {console.log(e);});
        props.onDelete();
    }

    let display = props.cardDisplay((val) => {
        setCreationDisplay(val);
        handleShowCreation();

    }, creationList);

    try {
        return(
            <>
                <Modal
                    dialogClassName="modal-90w" show={showCreation} onHide={handleCloseCreation}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body className="centerBorderless">
                        {props.modalDisplay(creationDisplay)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col>
                                    <Button variant="danger" onClick={ () => {
                                        DeleteDisplayedCreation();
                                        handleCloseCreation();
                                    }}>
                                        Delete from Saved Creations
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        <Button variant="primary" onClick={handleCloseCreation}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {display}
            </>);
    }
    catch (error) {
        console.error("Error while rendering creation ", error)

        return <></>;
    }
}

export default SavableCreation;
