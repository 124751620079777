import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation'
import Card from 'react-bootstrap/Card';
import MonsterGenerator from "../complex/MonsterGenerator";
import Nav from 'react-bootstrap/Nav';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { JsxElement } from 'typescript';
import {ConvertResults, DEFAULT_RESULT_ARRAY} from "../utility/resultcomponents/ResultComponent";
import TemplateGenerator from "../utility/TemplateGenerator";
import {Meta} from "../utility/MetaWrapper";

function CountryNameGenerator() {

    return(
        <div>
            <Meta pageName="Country Name Generator" desc="A TTRPG Country Name Generator that can generate the name of a country, kingdom, empire, and more for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/countrynamegenerator" />
            <TemplateGenerator name="Country Name" url="GetCountryName" multiple />
        </div>);
}

export default CountryNameGenerator;
