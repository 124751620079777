import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation';
import {Meta} from "../utility/MetaWrapper";
import TemplateGenerator from "../utility/TemplateGenerator";
import {ConvertResults, ConvertResultsDisplay, DEFAULT_RESULT_ARRAY, StringToResultComponent} from "../utility/resultcomponents/ResultComponent";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";
import {ResultComponent} from "../../../../dndnode_ass/OptionBuilderPieces/OptionBuilderTools";
import {NPC} from "../../../../dndnode_ass/complex_generators/npcgenerator";
import {DEFAULT_NPC} from "./NPCDisplay";
import OrganizationDisplay, {DEFAULT_GROUP, DEFAULT_GROUP_REF} from "./OrganizationDisplay";
import axios from "axios";


function OrganizationGenerator() {

    const [, updateState] = React.useState();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [organization, setOrganization] = useState(DEFAULT_GROUP_REF);
    
    const [organizationNames, setOrganizationNames] = useState(['']);
    const [generatedOrganizationType, setGeneratedOrganizationType] = useState('Random');
    const [selectedOrganization, setSelectedOrganization] = useState('Random');
    const [selectedOrganizationOptions, setSelectedOrganizationOptions] = useState(['Random']);
    
    const [alignmentNames, setAlignmentNames] = useState(['']);
    const [generatedAlignmentType, setGeneratedAlignmentType] = useState('Random');
    const [selectedAlignment, setSelectedAlignment] = useState('Random');
    const [selectedAlignmentOptions, setSelectedAlignmentOptions] = useState(['Random']);
    const [sigilColorSettingDefault, setSigilColorSettingDefault] = useState('Colorful');

    useEffect(() => {
        UpdateSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrganizationOptions]);

    useEffect(() => {
        UpdateSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAlignmentOptions]);

    function GetSelectedLocation() {
        return selectedOrganizationOptions[Math.floor(Math.random() * selectedOrganizationOptions.length)];
    }

    function GetSelectedAlignment() {
        return selectedAlignmentOptions[Math.floor(Math.random() * selectedAlignmentOptions.length)];
    }

    function UpdateSelected() {
        setSelectedOrganization(GetSelectedLocation()!);
        setSelectedAlignment(GetSelectedAlignment()!);
    }

    function UpdateOrganizationList() {
        fetch(URL+"/GetOrganizationTypes",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

                let types: Array<string> = data;

                setOrganizationNames(types);

            });
    }

    function UpdateAlignmentList() {
        fetch(URL+"/GetAlignmentMoralTypes",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let types: Array<string> = data;

            setAlignmentNames(types);

        });
    }


    function ChangeOrganization(data: any) {
        setOrganization(data.organization);
        setGeneratedOrganizationType(data.organization.Type);
        setGeneratedAlignmentType(data.organization.Alignment);
        forceUpdate();
    }

    useEffect(() => {
        UpdateOrganizationList();
        UpdateAlignmentList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function SaveOrganizationToProfile(){

        const saveTemplate = async () => {
            const response = await axios.put(URL + '/SaveOrganization', { organization: organization }, {withCredentials: true }).catch((e) => {
                console.log(e);
            });
        }

        saveTemplate().catch((e) => {console.log(e);});
    }

    return(
        <div>
            <Meta pageName="Group Generator" desc="A TTRPG Group Generator that creates a faction or organization with an associate sigil/symbol and information about how they operate for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/groupgenerator" />
            <TemplateGenerator onGenerateButtonClicked={UpdateSelected} customSaveData={SaveOrganizationToProfile} name="Group" url="GetOrganization" additionalUrl={"&organizationtype=" + selectedOrganization + "&alignment=" + selectedAlignment} customDataHandling={ChangeOrganization} settingsComponent={
                <div>
                    <h4>Group Type</h4>
                    <AdvancedDropdown options={ConvertForDropdown(organizationNames)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedOrganizationOptions} />
                    <br />
                    <br />
                    <h4>Group Alignment</h4>
                    <AdvancedDropdown options={ConvertForDropdown(alignmentNames)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedAlignmentOptions} />
                </div>
            } extraComponent={
                <>
                    <p>Group Type: {generatedOrganizationType}</p>
                    <p>Alignment: {generatedAlignmentType}</p>
                </>
            } componentReplacement={
                <OrganizationDisplay org={organization} popup={false} setColorSettingDefault={setSigilColorSettingDefault} colorSettingDefault={sigilColorSettingDefault} />
            }
            />
        </div>);
}

export default OrganizationGenerator;
