import {ConvertResults, ConvertResultsDisplay, ResultComponent} from "./ResultComponent";
import React, {useState} from "react";
import Collapse from 'react-bootstrap/Collapse'
import {Button} from "react-bootstrap";

export interface ResultComponentList {
    List: Array<{type: Array<ResultComponent>, result:Array<Array<ResultComponent>>}>
}

export function ResultComponentItemDisplay(props: { itemList: {type: Array<ResultComponent>, result: Array<Array<ResultComponent>>}}) {
    const [open, setOpen] = useState(true);

    let subList = props.itemList.result.map(specificItem =>
        <li>
            <ConvertResultsDisplay data={specificItem} />
        </li>
    );

    let typeDisplay = ConvertResults(props.itemList.type);

    return (
        <li>
            <Button onClick={() => { setOpen(!open); }} size="sm" >{ open ? <>-</> : <>+</> }</Button>
            {'   '}
            {typeDisplay} (x{subList.length})
            <Collapse in={open}>
                <ul>
                    {subList}
                </ul>
            </Collapse>
        </li>
    );
}

export function ResultComponentItemList(props: { itemList: {type: Array<ResultComponent>, result: Array<ResultComponent>}}) {
    const [open, setOpen] = useState(true);

    let subList = props.itemList.result.map(specificItem =>
        <li>
            <ConvertResultsDisplay data={[specificItem]} />
        </li>
    );

    let typeDisplay = ConvertResults(props.itemList.type);

    return (
        <li>
            <Button onClick={() => { setOpen(!open); }} size="sm" >{ open ? <>-</> : <>+</> }</Button>
            {'   '}
            {typeDisplay} (x{subList.length})
            <Collapse in={open}>
                <ul>
                    {subList}
                </ul>
            </Collapse>
        </li>
            );
}
