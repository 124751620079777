import React, {useRef, useState} from 'react';
import {BrowserRouter as Router, Link, Redirect, Route, Switch} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import * as BS from "react-bootstrap";
import {Col, Modal, Row, Tab, Tabs} from "react-bootstrap";
import Stack from 'react-bootstrap/Stack'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import "bootstrap/dist/css/bootstrap.min.css";
import {useAccordionButton} from 'react-bootstrap/AccordionButton';

import NameGenerator from "./dnd/simple/NameGenerator";
import AppearanceGenerator from "./dnd/complex/AppearanceGenerator";
import MonsterGenerator from "./dnd/complex/MonsterGenerator";
import TrueCoinSplitter from "./dnd/tools/TrueCoinSplitter";
import GoalGenerator from "./dnd/simple/GoalGenerator";
import SecretGenerator from "./dnd/simple/SecretGenerator";
import Home from "./Home";
import Changelog from "./Changelog";
import Statistics from "./Statistics";
import Login from "./Login";
import UserPage from "./UserPage";
import URL from "./BackendLocation";
import {ScreenSizeRatio, useIsAdmin, useIsLoggedIn, useScreenSizes} from "./hooks/loginHook"
import Creations from "./dnd/utility/saving/Creations";
import NPCGenerator from "./dnd/complex/NPCGenerator";
import PlannedFeatures from "./PlannedFeatures";
import BuildingNameGenerator from "./dnd/simple/BuildingNameGenerator";
import VoiceGenerator from "./dnd/simple/VoiceGenerator";
import ProblemGenerator from "./dnd/simple/ProblemGenerator";
import FearGenerator from "./dnd/simple/FearGenerator";
import LandmarkGenerator from "./dnd/simple/LandmarkGenerator";
import ItemGenerator from "./dnd/complex/ItemGenerator";
import LootGenerator from "./dnd/simple/LootGenerator";
import BuildingGenerator from "./dnd/complex/BuildingGenerator";
import FoodOrDrinkGenerator from "./dnd/simple/FoodOrDrinkGenerator";
import EncounterGenerator from "./dnd/simple/EncounterGenerator";
import MiscGenerator from "./dnd/simple/MiscGenerator";
import {InitiativeTracker} from "./dnd/tools/InitiativeTracker";
import FlawGenerator from "./dnd/simple/FlawGenerator";
import OrganizationNameGenerator from "./dnd/simple/OrganizationNameGenerator";
import OrganizationGenerator from "./dnd/complex/OrganizationGenerator";
import Contact from "./Contact";
import RumorGenerator from "./dnd/simple/RumorGenerator";
import SpellGenerator from "./dnd/simple/SpellGenerator";
import SettlementGenerator from "./dnd/complex/SettlementGenerator";
import LocationGenerator from "./dnd/complex/LocationGenerator";
import WeatherGenerator from "./dnd/simple/WeatherGenerator";
import MundaneEventGenerator from "./dnd/simple/MundaneEventGenerator";
import DesireGenerator from "./dnd/simple/DesireGenerator";
import CountryNameGenerator from "./dnd/simple/CountryNameGenerator";
import HistoricalEventGenerator from "./dnd/simple/HistoricalEventGenerator";
import FantasyLanguageTranslator from "./dnd/tools/Translator";
import Nav from "react-bootstrap/Nav";
import HolidayGenerator from "./dnd/simple/HolidayGenerator";
import PersonalityTraitGenerator from "./dnd/simple/PersonalityTraitGenerator";

function App() {
    const isAdmin = useIsAdmin();
    const loggedIn = useIsLoggedIn();
    const screenSizeRatio = useScreenSizes();
    const [showContactScreen, setShowContactScreen] = useState(false);
    const [currentMobileTab, setCurrentMobileTab] = useState("main");

    function CustomToggle(props: { children: string, eventKey: string }) {
        const decoratedOnClick = useAccordionButton(props.eventKey);

        return (
            <div className="d-grid gap-2">
                <Button
                    className="text-start"
                    size="lg"
                    variant="dark"
                    onClick={decoratedOnClick}
                >
                    {props.children}
                </Button>
            </div>
        );
    }

    function NavCardFunction(props: { title: string, functionality: () => void, disabled?: boolean }) {
        return (
                <Card bg="dark" className="test">
                    <Card.Header>
                        <div className="d-grid gap-2">
                            <Button
                            disabled={props.disabled}
                            className="text-start"
                            size="lg"
                            variant="dark"
                            onClick={props.functionality}
                            >
                                {props.title}{props.disabled ? ' (Coming Soon?)' : ''}
                            </Button>
                        </div>
                    </Card.Header>
                </Card>
        );
    }

    function NavCard(props: { title: string, link: string, disabled?: boolean }) {

        return (
            <Link to={props.link} className="noblueline" onClick={() => {setCurrentMobileTab("main")}}>
            <Card bg="dark" className="test">
                <Card.Header>
                    <div className="d-grid gap-2">
                        <Button
                            disabled={props.disabled}
                            className="text-start"
                            size="lg"
                            variant="dark"
                        >
                            {props.title}{props.disabled ? ' (Coming Soon?)' : ''}
                        </Button>
                    </div>
                </Card.Header>
                </Card>
                </Link>
            );
    }

    function NavCardExpand(props: { title: string, body: JSX.Element, startsOpen?: boolean }) {

        let activation: string = props.startsOpen ? "0" : "-1";

        return (
            <Accordion defaultActiveKey={activation} flush>
                <Card bg="dark">
                    <Card.Header>
                        <CustomToggle eventKey="0">{props.title + ':'}</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <BS.Container>
                            {props.body}
                        </BS.Container>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            );
    }

    let loginOption = loggedIn ? (
        <NavCardExpand title="User Info" body=
            {
            <Stack>
                <NavCard title="User Page" link="login" />
                <NavCard title="Saved Creations" link="creations" />
                {
                    isAdmin ? <NavCard title="Statistics" link="stats" /> : <></>
                }
                <NavCard title="Log Out" link="/logout/google" />
            </Stack>
            } />) : (<NavCard title="Login" link="login" />);

    function Sidebar() {
        return (
            <Stack>
                <NavCard title="Home" link="" />
                <Stack>
                    <NavCardExpand title="RPG Tools" startsOpen body=
                        {
                            <Stack>
                                <NavCardExpand title="Tools" body={
                                    <Stack>
                                        <NavCard title="Initiative Tracker" link="initiativetracker" />
                                        <NavCard title="True Coin Splitter" link="truecoinsplitter" />
                                        <NavCard title="Fantasy Language Translator" link="fantasylanguagetranslator" />
                                    </Stack>
                                } />
                                <NavCardExpand title="Complex Generators" body={
                                    <Stack>
                                        {/*<NavCard title="Spell Generator" link="spellgenerator" />*/}
                                        <NavCard title="Settlement Generator" link="settlementgenerator" />
                                        <NavCard title="NPC Generator" link="npcgenerator" />
                                        <NavCard title="Building Generator" link="buildinggenerator" />
                                        <NavCard title="Group Generator" link="groupgenerator" />
                                        <NavCard title="Location Generator" link="locationgenerator" />
                                        <NavCard title="Quest Generator" link="questgenerator" />
                                        <NavCard title="Encounter Generator" link="encountergenerator" />
                                        <NavCard title="Rumor Generator" link="rumorgenerator" />
                                        <NavCard title="Monster Generator" link="monstergenerator" />
                                    </Stack>
                                } />
                                <NavCardExpand title="Simple Generators" body= {
                                    <Stack>
                                        <NavCard title="Misc Generators" link="miscgenerator" />
                                        <NavCard title="Personality Trait Generator" link="personalitytraitgenerator" />
                                        <NavCard title="Historical Event Generator" link="historicaleventgenerator" />
                                        <NavCard title="Holiday Generator" link="holidaygenerator" />
                                        <NavCard title="Item Generator" link="itemgenerator" />
                                        <NavCard title="Secret Generator" link="secretgenerator" />
                                        <NavCard title="Goal Generator" link="goalgenerator" />
                                        <NavCard title="Mundane Event Generator" link="mundaneeventgenerator" />
                                        <NavCard title="Weather Generator" link="weathergenerator" />
                                        <NavCard title="Food/Drink Generator" link="fooddrinkgenerator" />
                                        <NavCard title="Landmark Generator" link="landmarkgenerator" />
                                        <NavCard title="Loot Generator" link="lootgenerator" />
                                        <NavCard title="Voice Generator" link="voicegenerator" />
                                        <NavCard title="Appearance Generator" link="appearancegenerator" />
                                        <NavCard title="Flaw Generator" link="flawgenerator" />
                                        <NavCard title="Fear Generator" link="feargenerator" />
                                        <NavCard title="Desire Generator" link="desiregenerator" />
                                    </Stack>
                                } />
                                <NavCardExpand title="Name Generators" body= {
                                    <Stack>
                                        <NavCard title="Country Name Generator" link="countrynamegenerator" />
                                        <NavCard title="Group Name Generator" link="groupnamegenerator" />
                                        <NavCard title="Building Name Generator" link="locationnamegenerator" />
                                        <NavCard title="Name Generator" link="namegenerator" />
                                    </Stack>
                                } />
                            </Stack>
                        } />
                    {/*<NavCardExpand title="Game Development" body=*/}
                    {/*{*/}
                    {/*    <NavCard title="TODO" link="todo" disabled={true} />*/}
                    {/*} />*/}
                </Stack>
                <NavCardExpand title="About" body=
                    {
                        <Stack>
                        <NavCard title="Changelog" link="changelog" />
                            <NavCard title="Planned Features" link="plannedfeatures" />
                        </Stack>
                    } />
                {loginOption}
                <NavCard title="Suggestions & Bug Reports" link="contact" />
            </Stack>
            );
    }

    let loginPages = !loggedIn ? [] : [
        (<Route key="stats" path="/stats">
                <Statistics />
        </Route>),
    ];

    let loginPageDisplay = loggedIn ? (<UserPage />) : (<Login />);

    function CorePage() {
        switch (screenSizeRatio) {
            case ScreenSizeRatio.Full:
                return (
                    <BS.Container fluid>
                        <BS.Row>
                            <BS.Col sm={3}>
                                <Sidebar />
                            </BS.Col>
                            <BS.Col sm="auto" />
                            <BS.Col sm={8} className="center">
                                <Routing />
                            </BS.Col>
                        </BS.Row>
                    </BS.Container>);
            case ScreenSizeRatio.Mobile:
                let comp = <></>;
                switch (currentMobileTab) {
                    case "menu":
                        comp = <Sidebar />;
                        break;
                    case "main":
                        comp = <Routing />;
                        break;
                    // case "wiki":
                    //     break;
                }

                return <div className="mobileCenter">
                    {comp}
                </div>

        }

        return <></>;
    }

    function Routing() {
        return (<Switch>
            {loginPages}
            <Route path="/login/google" component={() => {
                window.location.href = URL + "/login/google";
                return null;
            }} />
            <Route path="/logout/google" component={() => {
                window.location.href = URL + "/logout/google";
                return null;
            }} />
            <Route path="/login">
                {loginPageDisplay}
            </Route>
            <Route path="/creations">
                <Creations />
            </Route>
            <Route path="/contact">
                <Contact />
            </Route>
            <Route path="/problemGenerator">
                <Redirect to="/questgenerator" />
            </Route>
            <Route path="/groupgenerator">
                <OrganizationGenerator />
            </Route>
            <Route path="/spellgenerator">
                <SpellGenerator />
            </Route>
            <Route path="/personalitytraitgenerator">
                <PersonalityTraitGenerator />
            </Route>
            <Route path="/settlementgenerator">
                <SettlementGenerator />
            </Route>
            <Route path="/countrynamegenerator">
                <CountryNameGenerator />
            </Route>
            <Route path="/historicaleventgenerator">
                <HistoricalEventGenerator />
            </Route>
            <Route path="/locationgenerator">
                <LocationGenerator />
            </Route>
            <Route path="/weathergenerator">
                <WeatherGenerator />
            </Route>
            <Route path="/encountergenerator">
                <EncounterGenerator />
            </Route>
            <Route path="/questgenerator">
                <ProblemGenerator />
            </Route>
            <Route path="/rumorgenerator">
                <RumorGenerator />
            </Route>
            <Route path="/holidaygenerator">
                <HolidayGenerator />
            </Route>
            <Route path="/miscgenerator">
                <MiscGenerator />
            </Route>
            <Route path="/initiativetracker">
                <InitiativeTracker />
            </Route>
            <Route path="/locationnamegenerator">
                <BuildingNameGenerator />
            </Route>
            <Route path="/fantasylanguagetranslator">
                <FantasyLanguageTranslator />
            </Route>
            <Route path="/fooddrinkgenerator">
                <FoodOrDrinkGenerator />
            </Route>
            <Route path="/buildinggenerator">
                <BuildingGenerator />
            </Route>
            <Route path="/organizationnamegenerator">
                <Redirect to="/groupnamegenerator" />
            </Route>
            <Route path="/groupnamegenerator">
                <OrganizationNameGenerator />
            </Route>
            <Route path="/lootgenerator">
                <LootGenerator />
            </Route>
            <Route path="/flawgenerator">
                <FlawGenerator />
            </Route>
            <Route path="/itemgenerator">
                <ItemGenerator />
            </Route>
            <Route path="/voicegenerator">
                <VoiceGenerator />
            </Route>
            <Route path="/namegenerator">
                <NameGenerator />
            </Route>
            <Route path="/appearancegenerator">
                <AppearanceGenerator />
            </Route>
            <Route path="/npcgenerator">
                <NPCGenerator />
            </Route>
            <Route path="/monstergenerator">
                <MonsterGenerator />
            </Route>
            <Route path="/truecoinsplitter">
                <TrueCoinSplitter />
            </Route>
            <Route path="/goalgenerator">
                <GoalGenerator />
            </Route>
            <Route path="/landmarkgenerator">
                <LandmarkGenerator />
            </Route>
            <Route path="/mundaneeventgenerator">
                <MundaneEventGenerator />
            </Route>
            <Route path="/feargenerator">
                <FearGenerator />
            </Route>
            <Route path="/desiregenerator">
                <DesireGenerator />
            </Route>
            <Route path="/secretgenerator">
                <SecretGenerator />
            </Route>
            <Route path="/plannedfeatures">
                <PlannedFeatures />
            </Route>
            <Route path="/changelog">
                <Changelog />
            </Route>
            <Route path="/">
                <Home />
            </Route>
        </Switch>);
    }

    function HeaderBar() {
        return (<Navbar sticky="top" bg="dark" variant="dark" className="">
            <BS.Container>
                <Navbar.Brand>
                        <span>
                        <img
                            src="/logo512.png"
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            alt=""
                        />
                            {' '}
                            7Tools
                            {
                                screenSizeRatio === ScreenSizeRatio.Full ?
                                    <>
                                        {' '}| TTRPG Tools |
                                        {' '}
                                        <a target="_blank" href="https://www.patreon.com/bePatron?u=3138136">Become a Patron!</a>
                                    </>
                                    :
                                    <>
                                        {' '}|
                                        {' '}
                                        <a target="_blank" href="https://discord.gg/gRhmq45ZNv">
                                            <img
                                                id="discordBox"
                                                src="/discord.png"
                                                width="30"
                                                height="30"
                                                className="d-inline-block align-top"
                                                alt=""
                                            /></a>
                                        {' '}
                                        |
                                        {' '}
                                        <a target="_blank" href="https://twitter.com/The7ark?ref_src=twsrc%5Etfw">
                                            <img
                                                src="/twitter.png"
                                                width="30"
                                                height="30"
                                                className="d-inline-block align-top"
                                                alt=""
                                            />
                                        </a>
                                        {' '}
                                        |
                                        {' '}
                                        <a target="_blank" href="https://www.patreon.com/bePatron?u=3138136">
                                            <img
                                                id="patreonBox"
                                                src="/patreon.png"
                                                width="30"
                                                height="30"
                                                className="d-inline-block align-top"
                                                alt=""
                                            /></a>
                                    </>
                            }
                        </span>
                </Navbar.Brand>
            </BS.Container>
        </Navbar>);
    }

    function FooterBar() {
        function ChangeTab(key: any) {
            setCurrentMobileTab(key);
        }

        return (<>
            {
                screenSizeRatio === ScreenSizeRatio.Full
                    ?
            <Navbar fixed="bottom" bg="dark" variant="dark">
            <BS.Container>
                <Navbar.Brand>
                            <>
                                <img
                                    src="/logo512.png"
                                    width="30"
                                    height="30"
                                    className="d-inline-block align-top"
                                    alt=""
                                />
                                {' '}
                                7Tools |
                                {' '}
                                <a target="_blank" href="https://discord.gg/gRhmq45ZNv">
                                    <img
                                        id="discordBox"
                                        src="/discord.png"
                                        width="30"
                                        height="30"
                                        className="d-inline-block align-top"
                                        alt=""
                                    /></a>
                                {' '}
                                |
                                {' '}
                                <a target="_blank" href="https://twitter.com/The7ark?ref_src=twsrc%5Etfw">
                                    <img
                                        src="/twitter.png"
                                        width="30"
                                        height="30"
                                        className="d-inline-block align-top"
                                        alt=""
                                    />
                                </a>
                                {' '}
                                |
                                {' '}
                                <a target="_blank" href="https://www.patreon.com/bePatron?u=3138136">
                                    <img
                                        id="patreonBox"
                                        src="/patreon.png"
                                        width="30"
                                        height="30"
                                        className="d-inline-block align-top"
                                        alt=""
                                    /></a>
                                {' '}
                                |
                                {' '}
                                <a onClick={() => setShowContactScreen(true)} className="fakelinkWhite">Contact Me</a>
                            </>
                </Navbar.Brand>
            </BS.Container>
        </Navbar>
                    :
                    <BS.Container fluid style={{
                        position: "fixed",
                        bottom: "0"
                    }}>
                        <Row>
                            <>
                                <Nav justify variant="pills" onSelect={ChangeTab} activeKey={currentMobileTab} defaultActiveKey="main" style={{
                                    background: "#212529",
                                    padding: "0",
                                }} >
                                    <Nav.Item>
                                        <Nav.Link eventKey="menu">Menu</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="main">Tools</Nav.Link>
                                    </Nav.Item>
                                    {/*<Nav.Item>*/}
                                    {/*    <Nav.Link eventKey="wiki">Wiki</Nav.Link>*/}
                                    {/*</Nav.Item>*/}
                                </Nav>
                            </>
                        </Row>
                    </BS.Container>
            }
            <Modal
                dialogClassName="modal-90w" show={showContactScreen} onHide={() => setShowContactScreen(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="centerBorderless">
                    <Contact />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowContactScreen(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>);
    }

    return (
        <Router>
            <div>
                <HeaderBar />
                <CorePage />
                <br />
                <br />
                <br />
                <br />
                <FooterBar />
            </div>
        </Router>
            );
}

export default App;
