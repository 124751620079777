import BuildingGenerator from "./BuildingGenerator";
import React, {useEffect, useState} from "react";
import {Meta} from "../utility/MetaWrapper";
import TemplateGenerator from "../utility/TemplateGenerator";
import BuildingDisplay, {DEFAULT_BUILDING_REF} from "./BuildingDisplay";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";
import {DEFAULT_LOCATION_REF, LocationDisplay} from "./LocationDisplay";
import URL from "../../BackendLocation";
import axios from "axios";


function LocationGenerator() {
    const [, updateState] = React.useState();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [location, setLocation] = useState(DEFAULT_LOCATION_REF);

    const [locationTypes, setLocationTypes] = useState(['']);
    const [selectedLocation, setSelectedLocation] = useState('Random');
    const [selectedLocationOptions, setSelectedLocationOptions] = useState(['Random']);

    useEffect(() => {
        UpdateSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLocationOptions]);

    function GetSelectedLocation() {
        return selectedLocationOptions[Math.floor(Math.random() * selectedLocationOptions.length)];
    }

    function UpdateSelected() {
        setSelectedLocation(GetSelectedLocation()!);
    }

    function SetData(data: any) {
        setLocation(data.location);
        forceUpdate();
    }

    function updateLocationList() {
        fetch(URL+"/GetBroadLocationTypes",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let locations: Array<string> = data;

            setLocationTypes(locations);

        });
    }

    useEffect(() => {
        updateLocationList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function SaveLocationToProfile() {
        const saveTemplate = async () => {
            const response = await axios.put(URL + '/SaveLocation', { location: location }, {withCredentials: true }).catch((e) => {
                console.log(e);
            });
        }

        saveTemplate().catch((e) => {console.log(e);});
    }

    return(
        <div>
            <Meta pageName="Location Generator" desc="A TTRPG Location Generator that randomly generates information about unique and interesting locations for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/locationgenerator" />
            <TemplateGenerator onGenerateButtonClicked={UpdateSelected} name="Location" url="GetLocation"
                               customDataHandling={SetData}
                               customSaveData={SaveLocationToProfile}
                               componentReplacement={
                                   <>
                                       <hr />
                                       <LocationDisplay location={location} />
                                   </>
                               }
                               additionalUrl={"&location=" + selectedLocation} settingsComponent={
                <div>
                    <h4>Location Types</h4>
                    <AdvancedDropdown options={ConvertForDropdown(locationTypes)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedLocationOptions} />
                </div>
            } />
        </div>);
}

export default LocationGenerator;
