import Button from "react-bootstrap/Button";
import React from "react";
import {useIsLoggedIn} from "../../../hooks/loginHook";

export function SaveToProfileButton(props: { className: string, showSaved: boolean, onClick: () => void}) {


    const loggedIn = useIsLoggedIn();

    let button = <></>;
    if(loggedIn) {
        button = <Button className="templateButton" onClick={props.onClick}>Save to Profile</Button>;
    }
    else {
        button = <Button className="templateButton" disabled>Login to Save</Button>;
    }

    return <div className={props.className}>
        {button}
        <br />
        {
            props.showSaved ?
                <>
                    Saved!
                </>
                :
                <></>
        }
    </div>;
}
