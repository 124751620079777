import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import TemplateGenerator from "../utility/TemplateGenerator";
import {Meta} from "../utility/MetaWrapper";

function HolidayGenerator() {

    return(
        <div>
            <Meta pageName="Holiday Generator" desc="A TTRPG Holiday Generator used to generate holidays or festivals used for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/holidaygenerator" />
            <TemplateGenerator name="Holiday" url="GetHoliday"  isResultComponentCollection />
        </div>
    );
}

export default HolidayGenerator;
