import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import "bootstrap/dist/css/bootstrap.min.css";
import {Meta} from "../utility/MetaWrapper";

function TrueCoinSplitter() {

    const [playerCount, setPlayerCount] = useState(4);
    const [conversionRate, setConversionRate] = useState(10);
    const [platinumCount, setPlatinumCount] = useState(0);
    const [goldCount, setGoldCount] = useState(0);
    const [silverCount, setSilverCount] = useState(0);
    const [copperCount, setCopperCount] = useState(0);
    const [coinResults, setCoinResults] = useState('');
    const [leftoverCoins, setLeftoverCoins] = useState('');
    const [highestCoinTypeValue, setHighestCoinTypeValue] = useState(2);

    function UpdatePlayerCount(props: React.ChangeEvent<HTMLInputElement>) {
        setPlayerCount(parseInt(props.target.value, 10) || 2);
    }

    function UpdatePlatinumCount(props: React.ChangeEvent<HTMLInputElement>) {
        setPlatinumCount(parseInt(props.target.value, 10) || 0);
    }

    function UpdateGoldCount(props: React.ChangeEvent<HTMLInputElement>) {
        setGoldCount(parseInt(props.target.value, 10) || 0);
    }

    function UpdateSilverCount(props: React.ChangeEvent<HTMLInputElement>) {
        setSilverCount(parseInt(props.target.value, 10) || 0);
    }

    function UpdateCopperCount(props: React.ChangeEvent<HTMLInputElement>) {
        setCopperCount(parseInt(props.target.value, 10) || 0);
    }

    function UpdateConversionCount(props: React.ChangeEvent<HTMLInputElement>) {
        setConversionRate(parseInt(props.target.value, 10) || 1);
    }

    function UpdateHighestCoinType(props: React.ChangeEvent<HTMLSelectElement>) {
        setHighestCoinTypeValue(parseInt(props.target.value, 10));
    }

    function ResetValuesToDefault() {
        setPlayerCount(4);
        setConversionRate(10);
        setPlatinumCount(0);
        setGoldCount(0);
        setSilverCount(0);
        setCopperCount(0);
        setHighestCoinTypeValue(2);
    }

    useEffect(() => {
        UpdateResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerCount, platinumCount, goldCount, silverCount, copperCount, conversionRate, highestCoinTypeValue])

    function UpdateResults() {
        let cInput: Coins = new Coins(platinumCount, goldCount, silverCount, copperCount, 0);

        let final: Coins = new Coins(0, 0, 0, 0, 0);
        let remaining: Coins = new Coins(0, 0, 0, 0, 0);

        let highestCoinType: number = Math.pow(conversionRate, highestCoinTypeValue);

        do {
            let val: Coins = CreateSplit(cInput, highestCoinType);

            final.Copper += val.Copper;
            final.Silver += val.Silver;
            final.Gold += val.Gold;
            final.Plat += val.Plat;

            remaining.Copper = val.Remaining;

            cInput = remaining;
        }
        while (remaining.Copper >= conversionRate && remaining.Copper >= playerCount);

        setCoinResults(final.Display());
        setLeftoverCoins(remaining.Display());
    }


    function CreateSplit(coinsInput: Coins, highestValue: number) {
        let totalInCopper = 0;

        totalInCopper += Math.pow(conversionRate, 3) * coinsInput.Plat;
        totalInCopper += Math.pow(conversionRate, 2) * coinsInput.Gold;
        totalInCopper += conversionRate * coinsInput.Silver;
        totalInCopper += 1 * coinsInput.Copper;

        let extra = 0;
        let divided: number = totalInCopper / playerCount;
        let split: number = Math.floor(totalInCopper / playerCount);

        if (Math.floor(divided) - divided !== 0) {
            extra = totalInCopper % playerCount;
        }

        let level = 1;
        while (split >= conversionRate && level < highestValue) {
            let result: HigherValueResult = ToHigherValue(split, conversionRate);
            split = result.HighReceived;
            extra += (result.Leftover * playerCount) * level;

            level *= conversionRate;
        }

        return new Coins(level === Math.pow(conversionRate, 3) ? split : 0, level === Math.pow(conversionRate, 2) ? split : 0, level === conversionRate ? split : 0, level === 1 ? split : 0, extra);
    }

    function ToHigherValue(coin: number, conversionRate: number) {
        let divided: number = coin / conversionRate;
        let valueFinal: number = Math.floor(coin / conversionRate);
        let extra = 0;

        if (Math.floor(divided) - divided !== 0) {
            extra = coin % conversionRate;
        }

        return new HigherValueResult(valueFinal, extra);
    }

    class HigherValueResult {

        HighReceived: number;
        Leftover: number;

        constructor(highReceived: number, leftover: number) {
            this.HighReceived = highReceived;
            this.Leftover = leftover;
        }
    }

    class Coins {

        Plat: number;
        Gold: number;
        Silver: number;
        Copper: number;
        Remaining: number;

        constructor(plat: number, gold: number, silver: number, copper: number, remaining:number) {
            this.Plat = plat;
            this.Gold = gold;
            this.Silver = silver;
            this.Copper = copper;
            this.Remaining = remaining;
        }

        Display() {
            let result = "";
            if (this.Plat !== 0) {
                result += this.Plat + " Platinum ";
            }
            if (this.Gold !== 0) {
                result += this.Gold + " Gold ";
            }
            if (this.Silver !== 0) {
                result += this.Silver + " Silver ";
            }
            if (this.Copper !== 0) {
                result += this.Copper + " Copper ";
            }
            if (result === "") {
                result = "Nothing!";
            }
            return result;
        }
    }

    return(
        <div>
            <Meta pageName="Coin Splitter" desc="A TTRPG True Coin Splitter that takes in a coin value and splits it among a number of players to get the true coin amount between them all." address="https://7tools.dev/truecoinsplitter" />
            <h3>Number of Players</h3>
            <input type="number" id="quantity" name="quantity" min="2" defaultValue="4" onChange={UpdatePlayerCount} value={playerCount}/>
            <br />
            <br />
            <p>
                Platinum <input type="number" min="0" defaultValue="0" style={{ width: 75 }} onChange={UpdatePlatinumCount} value={platinumCount} /> Gold <input type="number" min="0" defaultValue="0" style={{ width: 75 }} onChange={UpdateGoldCount} value={goldCount} /> Silver <input type="number" min="0" defaultValue="0" style={{ width: 75 }} onChange={UpdateSilverCount} value={silverCount} /> Copper <input type="number" min="0" defaultValue="0" style={{ width: 75 }} onChange={UpdateCopperCount} value={copperCount} />
            </p>
            <h3>Highest Coin Type</h3>
            <select onChange={UpdateHighestCoinType} value={highestCoinTypeValue}>
                <option value="3">Platinum</option>
                <option value="2" selected>Gold</option>
                <option value="1">Silver</option>
                <option value="0">Copper</option>
            </select>
            <h3>Conversion Rate</h3>
            <p><input type="number" min="1" defaultValue="10" onChange={UpdateConversionCount} value={conversionRate}/><br />1 Gold == {conversionRate} Silver</p>
            <Card>
                <Card.Body>
                    <Card.Title>Coin Results</Card.Title>
                    <ListGroup variant="flush">
                        <ListGroup.Item>Each Receives: {coinResults}</ListGroup.Item>
                        <ListGroup.Item>Leftover Coins: {leftoverCoins}</ListGroup.Item>
                    </ListGroup>
                </Card.Body>
            </Card>
            <br />
            <Button onClick={ResetValuesToDefault}>Reset to Default</Button>
        </div>);
}

export default TrueCoinSplitter;
