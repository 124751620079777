import React, { useState, useEffect } from 'react';
import {ConvertResultsDisplay, ResultComponent} from "../utility/resultcomponents/ResultComponent";
import ItemDisplay from "../complex/ItemDisplay";
import {Button, Modal} from "react-bootstrap";

export interface Historical {
    History: Array<ResultComponent>;
}

function HistoryDisplay(props: { history: Historical }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (<>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                History
            </Modal.Header>
            <Modal.Body>
                <ConvertResultsDisplay data={props.history.History} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        {
            props.history == null ? <></> :
                <Button onClick={handleShow}>View History</Button>
        }
    </>);
}

export default HistoryDisplay;
