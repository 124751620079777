import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation'
import Card from 'react-bootstrap/Card';
import MonsterGenerator from "../complex/MonsterGenerator";
import Nav from 'react-bootstrap/Nav';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { JsxElement } from 'typescript';
import {ConvertResults, DEFAULT_RESULT_ARRAY} from "../utility/resultcomponents/ResultComponent";
import TemplateGenerator from "../utility/TemplateGenerator";
import {Meta} from "../utility/MetaWrapper";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";

function EncounterGenerator() {
    const [encounterTypes, setEncounterTypes] = useState(['']);
    const [selectedEncounter, setSelectedEncounter] = useState('Random');
    const [selectedEncounterOptions, setSelectedEncounterOptions] = useState(['Random']);
    const [environmentTypes, setEnvironmentTypes] = useState(['']);
    const [selectedEnvironmentOptions, setSelectedEnvironmentOptions] = useState(['Random']);
    const [selectedCR, setSelectedCR] = useState(-1);

    function GetSelectedEncounterType() {
        return selectedEncounterOptions[Math.floor(Math.random() * selectedEncounterOptions.length)]
    }

    function UpdateSelected() {
        setSelectedEncounter(GetSelectedEncounterType()!);
    }

    useEffect(() => {
        UpdateSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEncounterOptions]);

    function UpdateEncounterList() {
        fetch(URL+"/GetEncounterTypes",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let items: Array<string> = data;

            setEncounterTypes(items);

        });
    }

    function UpdateEnvironmentList() {
        fetch(URL+"/GetEnvironmentTypes",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let items: Array<string> = data;

            setEnvironmentTypes(items);

        });
    }

    useEffect(() => {
        UpdateEncounterList();
        UpdateEnvironmentList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setCR(selected: React.ChangeEvent<HTMLSelectElement>) {
        setSelectedCR(parseFloat(selected.target.value))
    }

    return(
        <div>
            <Meta pageName="Encounter Generator" desc="A TTRPG Encounter Generator that generates combat, roleplaying, or exploration based encounters for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/encountergenerator" />
            <TemplateGenerator onGenerateButtonClicked={UpdateSelected} name="Encounter" url="GetEncounter" multiple additionalUrl={"&type=" + selectedEncounter + "&cr=" + selectedCR + "&environments=" + selectedEnvironmentOptions} extraDataForResultsDisplay={selectedEnvironmentOptions.toString()} settingsComponent={
                <div>
                    <h4>Encounter Type</h4>
                    <AdvancedDropdown options={ConvertForDropdown(encounterTypes)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedEncounterOptions} />
                    <h4>Environment Type</h4>
                    <AdvancedDropdown options={ConvertForDropdown(environmentTypes)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedEnvironmentOptions} />
                    <br />
                    <br />
                    <h4>Challenge CR</h4>
                    <select onChange={setCR}>
                        <option value="-1">Random</option>
                        <option value="0">0</option>
                        <option value="0.125">1/8</option>
                        <option value="0.25">1/4</option>
                        <option value="0.5">1/2</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                    </select>
                </div>
            } />
        </div>);
}

export default EncounterGenerator;
