import {useEffect, useState} from "react";
import {DEFAULT_NPC, DEFAULT_NPC_REF, NPC, NPCReferenceType} from "../../complex/NPCDisplay";
import {DEFAULT_APPEARANCE} from "../../complex/AppearanceDisplay";
import {forEach} from "react-bootstrap/ElementChildren";
import {Building, BuildingReferenceType, DEFAULT_BUILDING} from "../../complex/BuildingDisplay";
import URL from "../../../BackendLocation";
import {GroupReferenceType} from "../../../../../dndnode_ass/complex_generators/organizationgenerator";
import {DEFAULT_GROUP, Group} from "../../complex/OrganizationDisplay";
import {DEFAULT_LOCATION, LocationReferenceType, Location} from "../../complex/LocationDisplay";
import {ConvertResultsToString, GetObjectsFromResultComponent, ResultComponent} from "../resultcomponents/ResultComponent";
import {DEFAULT_SETTLEMENT, Settlement} from "../../complex/SettlementDisplay";

export enum ReferenceType {
    NPC,
    Building,
    Group,
    Location,
    Settlement,
}

export interface ReferenceObject {
    ReferenceData: {
        Set: boolean,
        Excluding?: Array<string>,
        Require?: string
    }
}

export interface ReferencePackage {
    References: { [key: number]: ReferenceInfo<any>};

    //Deprecated - Only for backwards compatability
    NPCReference?: ReferenceInfo<NPC>;
    BuildingReference?: ReferenceInfo<Building>;
    GroupReference?: ReferenceInfo<Group>;
    LocationReference?: ReferenceInfo<Location>;
    SettlementReference?: ReferenceInfo<Settlement>;
}

export interface ReferenceInfo<type> {
    ReferenceIds: { [key: string]: type};
    Keys: Array<string>;
    CurrentId: number;
}

export function NewReferencePackage(): ReferencePackage {
    let pack: ReferencePackage = {
        References: {}
    };

    const types = Object.values(ReferenceType).filter((v) => !isNaN(Number(v)));

    types.forEach((value, index) => {
        pack.References[index] = {
            ReferenceIds: {},
            Keys: [],
            CurrentId: 0
        };
    });

    return pack;
}

export let referencePackage: ReferencePackage = NewReferencePackage();
export let genericRC: Array<Array<ResultComponent>> = [];

export function GetObject(type: ReferenceType, id: string): any {
    if(id === 'blank') {
        switch (type) {
            case ReferenceType.NPC:
                return DEFAULT_NPC;
            case ReferenceType.Building:
                return DEFAULT_BUILDING;
            case ReferenceType.Group:
                return DEFAULT_GROUP;
            case ReferenceType.Location:
                return DEFAULT_LOCATION;
            case ReferenceType.Settlement:
                return DEFAULT_SETTLEMENT;
        }
    }

    return referencePackage.References[type]!.ReferenceIds[id]!;
}

export function SetGenericRCData(data: Array<Array<ResultComponent>>) {
    if(data == null) {
        genericRC = [];
    }
    else {
        genericRC = data;
    }
}

export function SetReferencePackage(refPack: ReferencePackage) {
    referencePackage = refPack;

    if(referencePackage.References === undefined) {
        referencePackage.References = {};
    }

    if(referencePackage.NPCReference !== undefined) {
        referencePackage.References[ReferenceType.NPC] = referencePackage.NPCReference;
    }
    if(referencePackage.BuildingReference !== undefined) {
        referencePackage.References[ReferenceType.Building] = referencePackage.BuildingReference;
    }
    if(referencePackage.GroupReference !== undefined) {
        referencePackage.References[ReferenceType.Group] = referencePackage.GroupReference;
    }
    if(referencePackage.LocationReference !== undefined) {
        referencePackage.References[ReferenceType.Location] = referencePackage.LocationReference;
    }
    if(referencePackage.SettlementReference !== undefined) {
        referencePackage.References[ReferenceType.Settlement] = referencePackage.SettlementReference;
    }

    for (let i = 0; i < subscribers.length; i++) {
        subscribers[i]!(referencePackage);
    }
}

export function GetDisplayText(type: ReferenceType, id: string) {
    let displayText = "";
    let obj = GetObject(type, id);
    switch (type) {
        case ReferenceType.NPC:
            displayText = (obj as NPC).Name;
            break;
        case ReferenceType.Building:
            displayText = (obj as Building).locationName[0]!.text;
            break;
        case ReferenceType.Group:
            displayText = (obj as Group).OrgName[0]!.text;
            break;
        case ReferenceType.Location:
            displayText = (obj as Location).Name[0]!.text;
            break;
        case ReferenceType.Settlement:
            displayText = ConvertResultsToString((obj as Settlement).Name);
            break;
    }

    return displayText;
}

interface WindowOrWorkerGlobalScope {
    structuredClone(value: any): any;
}
declare function structuredClone( value: any): any;

export function AddToReferencePackage(miniPack: ReferencePackage) {
    let newPack: ReferencePackage = structuredClone(referencePackage);

    const types = Object.values(ReferenceType).filter((v) => !isNaN(Number(v)));

    types.forEach((value, index) => {
        let newRefObject = newPack.References[index]!;
        let miniRefObject = miniPack.References[index]!;

        newRefObject.CurrentId = miniRefObject.CurrentId;
        for (let i = 0; i < miniRefObject.Keys.length; i++) {
            let key = miniRefObject.Keys[i]!;
            if(!newRefObject.Keys.includes(key)) {
                newRefObject.Keys.push(key);
            }
            newRefObject.ReferenceIds[key] = miniRefObject.ReferenceIds[key];
        }
    });

    SetReferencePackage(newPack);
}

let subscribers: Array<(arg: any) => any> = [];

export function useReferencePackage(): ReferencePackage {
    const [refPackage, setRefPackage] = useState(referencePackage);

    useEffect(() => {
        function onChange(newPackage: ReferencePackage) {
            setRefPackage(newPackage);
        }

        subscribers.push(onChange);
        return () => {
            let index = subscribers.indexOf(onChange, 0);
            if(index > -1) {
                subscribers.slice(index, 1);
            }
        }
    });

    return refPackage;
}

function CreateMiniPackage(): ReferencePackage {
    let miniPackage: ReferencePackage = {
        References: {}
    };

    const types = Object.values(ReferenceType).filter((v) => !isNaN(Number(v)));

    types.forEach((value, index) => {
        miniPackage.References[index] = {
            ReferenceIds: {},
            Keys: [],
            CurrentId: referencePackage.References[index]!.CurrentId
        };
    });

    const miniAmount = 10;
    const amountHalf = miniAmount / 2;

    types.forEach((value, index) => {
        let refObject = referencePackage.References[index]!;
        let miniRefObject = miniPackage.References[index]!;
        
        for (let i = 0; i < Math.min(refObject.Keys.length, amountHalf); i++) {
            let key = refObject.Keys[i]!;
            if(!miniRefObject.Keys.includes(key)) {
                miniRefObject.Keys.push(key);
                miniRefObject.ReferenceIds[key] = refObject.ReferenceIds[key]!;
            }
        }
        for (let i = Math.max(0, refObject.Keys.length - amountHalf); i <refObject.Keys.length ; i++) {
            let key = refObject.Keys[i]!;
            if(!miniRefObject.Keys.includes(key)) {
                miniRefObject.Keys.push(key);
                miniRefObject.ReferenceIds[key] = refObject.ReferenceIds[key]!;
            }
        }
    });

    return miniPackage;
}

export function GrabObject(type: ReferenceType, id: NPCReferenceType, complete: () => any) {
    let miniPackage = CreateMiniPackage();
    miniPackage.References[type]!.Keys.push(id);
    miniPackage.References[type]!.ReferenceIds[id] = referencePackage.References[type]!.ReferenceIds[id]!;

    //May change this to allow any type of additional, right now just need NPCs
    let npcAdditionals: Array<string> = [];

    const types = Object.values(ReferenceType).filter((v) => !isNaN(Number(v)));

    switch (type) {
        case ReferenceType.NPC:
            break;
        case ReferenceType.Building:
            let building = GetObject(type, id) as Building;
            npcAdditionals = [building.buildingOwner, ...building.employees];
            break;
        case ReferenceType.Group:
            let group = GetObject(type, id) as Group;
            npcAdditionals = [group.Leader, ...group.Members];
            break;
        case ReferenceType.Location:
            break;
        case ReferenceType.Settlement:
            let settlement = GetObject(type, id) as Settlement;
            npcAdditionals = [...GetObjectsFromResultComponent(ReferenceType.NPC, settlement.Leadership)];
            break;
    }

    types.forEach((value, index) => {
        let refObject = miniPackage.References[index]!;

        for(const obj of Object.values(refObject.ReferenceIds)) {
            switch (index) {
                case ReferenceType.NPC:
                    break;
                case ReferenceType.Building:
                    let building = obj as Building;
                    npcAdditionals = npcAdditionals.concat([building.buildingOwner, ...building.employees]);
                    break;
                case ReferenceType.Group:
                    let group = obj as Group;
                    npcAdditionals = npcAdditionals.concat([group.Leader, ...group.Members]);
                    break;
                case ReferenceType.Location:
                    break;
                case ReferenceType.Settlement:
                    let settlement = obj as Settlement;
                    npcAdditionals = npcAdditionals.concat([...GetObjectsFromResultComponent(ReferenceType.NPC, settlement.Leadership)]);
                    break;
            }
        }
    });

    for (let i = 0; i < npcAdditionals.length; i++) {
        let additionalId = npcAdditionals[i]!;
        let miniNpcObject = miniPackage.References[ReferenceType.NPC]!;
        if(!miniNpcObject.Keys.includes(additionalId)) {
            miniNpcObject.Keys.push(additionalId);
            miniNpcObject.ReferenceIds[additionalId] = referencePackage.References[ReferenceType.NPC]!.ReferenceIds[additionalId]!;
        }
    }

    let stringRef = JSON.stringify(miniPackage);
    fetch(URL+"/GetExistingObject",
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                package: stringRef,
                objId: id,
                type: type
            })
        })
        .then(res => res.json()).then((data) => {
        AddToReferencePackage(data.refPackage);
        complete();
        // forceUpdate();
    });
}
