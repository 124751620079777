import {Button, Modal} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import NPCDisplay from "../../complex/NPCDisplay";
import {PopupContext} from "../TemplateGenerator";
import {
    GetDisplayText,
    GetObject,
    GrabObject,
    ReferenceObject,
    referencePackage,
    ReferenceType,
    SetReferencePackage
} from "./ReferenceIds";
import BuildingDisplay from "../../complex/BuildingDisplay";
import OrganizationDisplay from "../../complex/OrganizationDisplay";
import URL from "../../../BackendLocation";
import {ConvertResultsDisplay, ResultComponentCollectionDisplay} from "../resultcomponents/ResultComponent";
import MonsterDisplay, {Monster} from "../../complex/MonsterDisplay";
import ItemDisplay, {Item} from "../../complex/ItemDisplay";
import {LocationDisplay} from "../../complex/LocationDisplay";
import SettlementDisplay from "../../complex/SettlementDisplay";
import {LoadingDisplay} from "../../../LoadingDisplay";
import {ScreenSizeRatio, useScreenSizes} from "../../../hooks/loginHook";

export function PopupDisplayHandler() {
    const displayTypes = useContext(PopupContext);
    const [element, setElement] = useState<JSX.Element>();
    const types = Object.values(ReferenceType).filter((v) => !isNaN(Number(v)));
    const [, updateState] = React.useState();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const screenSizeRatio = useScreenSizes();

    const handleClose = () => {
        displayTypes?.setTypes([]);
    };
    const handleBack = () => {
        let types = [...displayTypes!.types];
        types.pop();
        displayTypes?.setTypes(types);
    };

    useEffect(() => {
        if(displayTypes!.types!.length > 0) {
            let currentTypeInfo = displayTypes!.types![displayTypes!.types!.length - 1]!;
            let currentType = currentTypeInfo.type;

            types.forEach((value, index) => {
                let typeText = ReferenceType[index]!.toString().toLowerCase();
                if(currentType.includes(typeText)) {
                    let id = currentTypeInfo.data;
                    if(id === undefined || id == null) {
                        id = currentType;
                    }

                    let set: () => void = () => {};
                    switch (index as ReferenceType) {
                        case ReferenceType.NPC:
                            set = () => setElement(<NPCDisplay currentNPC={id} />);
                            break;
                        case ReferenceType.Building:
                            set = () => setElement(<BuildingDisplay building={currentType} />);
                            break;
                        case ReferenceType.Group:
                            set = () => setElement(<OrganizationDisplay org={currentType} popup={true} />);
                            break;
                        case ReferenceType.Location:
                            set = () => setElement(<LocationDisplay location={currentType} />);
                            break;
                        case ReferenceType.Settlement:
                            set = () => setElement(<SettlementDisplay settlement={currentType} popup={true} />);
                            break;
                    }

                    let obj = GetObject(index, id) as ReferenceObject;
                    if(!obj.ReferenceData.Set) {
                        setElement(<LoadingDisplay />);
                        GrabObject(index, currentType, () => {
                            set();
                        });
                    }
                    else {
                        setElement(<LoadingDisplay />);
                        const timer = setTimeout(() => {
                            set();

                            // setShouldShowSvg(false);
                        }, 10);
                    }
                }
            });

            if(currentType.includes("landmark")) {
                let environments = "Forest,Swamp,Coastal,Desert,Grassland,Mountain,Hill,Urban";

                let stringRef = JSON.stringify(referencePackage);
                setElement(<>Generating Data!</>);
                fetch(URL+"/GetLandmark?adjective=true&environments=" + environments,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            package: stringRef,
                        })
                    })
                    .then(res => res.json()).then((data) => {
                    SetReferencePackage(data.refPackage);
                    setElement(<ConvertResultsDisplay data={data.data} />);
                });
            }
            else if(currentType.includes("monster")) {
                let race = currentTypeInfo.data;
                setElement(<>Generating Data!</>);
                fetch(URL+"/GetMonster?cr=-1" + (race === 'Random' ? '' : '&type=' + race),
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(res => res.json()).then((data: Monster) => {
                        setElement(<MonsterDisplay currentMonster={data} />);
                });
            }
            else if(currentType.includes("item")) {
                setElement(<ItemDisplay item={currentTypeInfo.data} />)
            }
            else if(currentType.includes("resultcomponentcollection")) {
                setElement(<ResultComponentCollectionDisplay data={currentTypeInfo.data} />)
            }
        }
        else {
            setElement(<>Generating Data!</>);
        }
    }, [displayTypes]);

    let headerDisplay = displayTypes!.types!.map((type, index) => {
        if(displayTypes!.types!.length - index > 5) {
            return <></>;
        }

        let currentType = type.type;
        let last = index === displayTypes!.types!.length - 1;
        let linkType = last ?
            "fakelinkCurrent" : "fakelink";
        
        let element = <></>;

        function HandleSelection() {
            let indexDiff = (displayTypes!.types!.length - 1) - index;
            let types = [...displayTypes!.types];
            for (let i = 0; i < indexDiff; i++) {
                types.pop();
            }
            displayTypes?.setTypes(types);
        }
        types.forEach((value, index) => {
            let typeText = ReferenceType[index]!.toString().toLowerCase();
            if (currentType.includes(typeText)) {
                let id = type.data;
                if(id === undefined || id == null) {
                    id = currentType;
                }
                let obj = GetObject(index, id);

                element = <a onClick={() => {
                    HandleSelection();
                }} className={linkType}>{GetDisplayText(index, id)}</a >;
            }
        });

        if(currentType.includes("landmark")) {
            element = <a onClick={() => {
                HandleSelection();
            }} className={linkType}>Landmark</a >;
        }
        else if(currentType.includes("monster")) {
            let race: string = type.data;
            let raceNameDisplay = race[0]!.toUpperCase() + race.slice(1, race.length);
            element = <a onClick={() => {
                HandleSelection();
            }} className={linkType}>{raceNameDisplay} Monster</a >;
        }
        else if(currentType.includes("item")) {
            let item: Item = type.data;

            element = <a onClick={() => {
                HandleSelection();
            }} className={linkType}>{item.Name[0]!.text}</a >;
        }

        if(!last) {
            element = <>{element} &nbsp;{'>'}&nbsp; </>
        }

        return element;
    });

    let show = displayTypes!.types!.length !== 0;

    return (
        <Modal
            dialogClassName={screenSizeRatio === ScreenSizeRatio.Full ? "modal-90w" : ""}
            show={show}
            onHide={handleClose}>
            {
                displayTypes!.types!.length > 1 ?
                    <Modal.Header className="modal-header">
                        {headerDisplay}
                    </Modal.Header>
                    :
                    <></>
            }
            <Modal.Body className="centerBorderless">
                {element}
            </Modal.Body>
            <Modal.Footer>
                {
                    displayTypes!.types!.length > 1 ?
                        <Button variant="primary" onClick={handleBack}>
                            Previous
                        </Button>
                        :
                        <></>
                }
                <Button variant={displayTypes!.types!.length <= 1 ? "primary" : "secondary"} onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
