import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation'
import Card from 'react-bootstrap/Card';
import MonsterGenerator from "./MonsterGenerator";
import Nav from 'react-bootstrap/Nav';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { JsxElement } from 'typescript';
import {ConvertResults, DEFAULT_RESULT_ARRAY} from "../utility/resultcomponents/ResultComponent";
import TemplateGenerator from "../utility/TemplateGenerator";
import {Meta} from "../utility/MetaWrapper";
import BuildingDisplay, {DEFAULT_BUILDING_REF} from "./BuildingDisplay";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";
import SettlementDisplay, {DEFAULT_SETTLEMENT, DEFAULT_SETTLEMENT_REF} from "./SettlementDisplay";
import axios from "axios";

function SettlementGenerator() {

    const [, updateState] = React.useState();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [settlement, setSettlement] = useState(DEFAULT_SETTLEMENT_REF);

    const [environmentTypes, setEnvironmentTypes] = useState(['']);
    const [selectedEnvironmentOptions, setSelectedEnvironmentOptions] = useState(['Random']);

    const [settlementSizes, setSettlementSizes] = useState(['']);
    const [selectedSettlementSizes, setSelectedSettlementSizes] = useState(['Random']);

    function UpdateEnvironmentList() {
        fetch(URL+"/GetReasonableEnvironmentTypes",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let items: Array<string> = data;

            setEnvironmentTypes(items);

        });
    }
    function UpdateSettlementSizeList() {
        fetch(URL+"/GetSettlementSizes",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let items: Array<string> = data;

            setSettlementSizes(items);

        });
    }

    useEffect(() => {
        UpdateEnvironmentList();
        UpdateSettlementSizeList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function SetData(data: any) {
        setSettlement(data.data);
        forceUpdate();
    }
    function SaveSettlementToProfile() {
        const saveTemplate = async () => {
            const response = await axios.put(URL + '/SaveSettlement', { settlement: settlement }, {withCredentials: true }).catch((e) => {
                console.log(e);
            });
        }

        saveTemplate().catch((e) => {console.log(e);});
    }

    return(
        <div>
            <Meta pageName="Settlement Generator" desc="A TTRPG Settlement Generator that can generate a hamlet, village, town, city, or metropolis full of buildings and people for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/settlementgenerator" />
            <TemplateGenerator
                // onGenerateButtonClicked={UpdateSelected}
                               name="Settlement" url="GetSettlement"
                               customDataHandling={SetData}
                               customSaveData={SaveSettlementToProfile}
                               componentReplacement={
                                   <>
                                       <hr />
                                       <SettlementDisplay settlement={settlement} popup={false} />
                                   </>
                               }
                               additionalUrl={"&environments=" + selectedEnvironmentOptions + "&size=" + selectedSettlementSizes}
                               settingsComponent={
                <div>
                    <h4>Environment Type</h4>
                    <AdvancedDropdown options={ConvertForDropdown(environmentTypes)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedEnvironmentOptions} />
                    <h4>Settlement Size</h4>
                    <AdvancedDropdown options={ConvertForDropdown(settlementSizes)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedSettlementSizes} />
                </div>
            } />
        </div>
    );
}

export default SettlementGenerator;
