import {useEffect, useState} from "react";
import axios from "axios";
import URL from "../BackendLocation";

export function useIsLoggedIn() {
    const [loggedIn, setLoggedIn] = useState(false);

    function CheckIfLoggedIn(){

        const checkLoginFetch = async () => {
            const response = await axios.get(URL + '/CheckLoggedIn', { withCredentials: true }).catch((e) => {
                console.log(e);
            });

            if(response && response.data){
                setLoggedIn(response.data.isValid);
            }
        }

        checkLoginFetch().catch((e) => {console.log(e);});
    }

    useEffect(() => {
        CheckIfLoggedIn();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loggedIn;
}

export function useIsAdmin() {
    const [isAdmin, setAdminStatus] = useState(false);

    function CheckIfAdmin(){

        const checkAdminFetch = async () => {
            const response = await axios.get(URL + '/GetIsAdmin', { withCredentials: true }).catch((e) => {
                console.log(e);
            });

            if(response && response.data){
                setAdminStatus(response.data.isAdmin);
            }
        }

        checkAdminFetch().catch((e) => {console.log(e);});
    }

    useEffect(() => {
        CheckIfAdmin();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isAdmin;
}

export enum ScreenSizeRatio { Full, Mobile}

export function useScreenSizes(): ScreenSizeRatio {
    const [screenSizeMode, setScreenSizeMode] = useState<ScreenSizeRatio>(window.innerWidth <= 800 ? ScreenSizeRatio.Mobile : ScreenSizeRatio.Full);

    function handleWindowSizeChange() {
        if(window.innerWidth <= 800) {
            setScreenSizeMode(ScreenSizeRatio.Mobile);
        }
        else {
            setScreenSizeMode(ScreenSizeRatio.Full);
        }
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return screenSizeMode;
}
