import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import TemplateGenerator from "../utility/TemplateGenerator";
import {Meta} from "../utility/MetaWrapper";

function PersonalityTraitGenerator() {

    return(
        <div>
            <Meta pageName="Personality Trait Generator" desc="A TTRPG Personality Trait Generator that generates a personality trait for a player or NPC used for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/personalitytraitgenerator" />
            <TemplateGenerator name="Personality Trait" url="GetPersonalityTrait" multiple />
        </div>
    );
}

export default PersonalityTraitGenerator;
