import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Button from "react-bootstrap/Button";
import URL from './BackendLocation';
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {useIsLoggedIn} from "./hooks/loginHook";
import {Meta} from "./dnd/utility/MetaWrapper";


async function LoginUser(credentials: { username: string, password: string }) {
    return fetch(URL + "/Login",
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
        .then(res => res.json());
}

function Login() {

    return (
        <div>
            <Meta pageName="Login" desc="Login Page" address="https://7tools.dev/login" />
            <h1>Login</h1>
            <Link to="/login/google"><Button>Login with Google</Button></Link>
        </div>);
}

export default Login;
