import React, {useEffect, useState} from 'react';
import Nav from 'react-bootstrap/Nav'
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../../BackendLocation';
import {Button, Card, Col, Container, Row, Modal} from "react-bootstrap";

import MonsterDisplay, {DEFAULT_MONSTER, Monster} from "../../complex/MonsterDisplay";
import axios from "axios";
import {Meta} from "../MetaWrapper";
import {
    ConvertResults,
    ConvertResultsDisplay,
    DEFAULT_RESULT_ARRAY,
    DEFAULT_RESULT_COMPONENT_COLLECTION,
    ResultComponent, ResultComponentCollection, ResultComponentCollectionDisplay
} from "../resultcomponents/ResultComponent";
import BuildingDisplay, {
    Building,
    BuildingReferenceType,
    DEFAULT_BUILDING,
    DEFAULT_BUILDING_REF
} from "../../complex/BuildingDisplay";
import SavableCreation from "./SavableCreation";
import NPCDisplay, {DEFAULT_NPC, DEFAULT_NPC_REF, NPC, NPCReferenceType} from "../../complex/NPCDisplay";
import OrganizationDisplay, {DEFAULT_GROUP, DEFAULT_GROUP_REF, Group} from "../../complex/OrganizationDisplay";
import ItemDisplay, {DEFAULT_ITEM, Item} from "../../complex/ItemDisplay";
import {
    GetObject,
    referencePackage,
    ReferencePackage, ReferenceType,
    SetReferencePackage
} from "../architecture/ReferenceIds";
import {GroupReferenceType} from "../../../../../dndnode_ass/complex_generators/organizationgenerator";
import {DEFAULT_LOCATION_REF, LocationDisplay, LocationReferenceType} from "../../complex/LocationDisplay";
import {PopupDisplayHandler} from "../architecture/PopupDisplayHandler";
import {PopupContext, PopupTypeInfo} from '../TemplateGenerator';
import SettlementDisplay, {DEFAULT_SETTLEMENT_REF, Settlement, SettlementSize} from "../../complex/SettlementDisplay";
import HistoricalEventGenerator from "../../simple/HistoricalEventGenerator";

function Creations() {
    const [currentTab, setCurrentTab] = useState('all');

    const [displays, setDisplays] = useState<Array<JSX.Element>>([]);
    const [displayTypes, setDisplayTypes] = useState<Array<PopupTypeInfo>>([]);

    function ChangeTab(key: any) {
        setCurrentTab(key);
    }

    function RefreshLists(){
        ClearLists();

        let tempDisplaySet: Array<JSX.Element> = [];

        try {
            if(currentTab === 'all' || currentTab === 'monsters') {
                tempDisplaySet.push(
                    <SavableCreation
                        creationName={"Monster"}
                        defaultData={DEFAULT_MONSTER}
                        modalDisplay={(display: Monster) => <MonsterDisplay currentMonster={display} />}
                        handleData={(data) => {
                            let list: Array<Monster> = [];

                            data.monsters.forEach((item: any) => {
                                list.push(JSON.parse(item));
                            });

                            return list;
                        }}
                        cardDisplay={(click, list: Array<Monster>) => {return list.map(monster =>
                            <Col md="auto">
                                <Card style={{ width: '12rem' }}>
                                    <Card.Body>
                                        <Card.Title>{monster.Name}</Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted">CR {monster.ChallengeRating}</Card.Subtitle>
                                        <Card.Subtitle className="mb-2 text-muted">{monster.Size} {monster.Type}</Card.Subtitle>
                                        <Button onClick={() => {
                                            click(monster);
                                        }}>View Statblock</Button>
                                    </Card.Body>
                                </Card>
                                <br />
                            </Col>
                        );}}
                        onDelete={RefreshLists}
                    />);
            }

            if(currentTab === 'all' || currentTab === 'npcs') {
                tempDisplaySet.push(
                    <SavableCreation
                        creationName={"NPC"}
                        defaultData={{
                            data: DEFAULT_NPC_REF,
                            refPackage: referencePackage
                        }}
                        modalDisplay={(display: {
                            data: NPCReferenceType,
                            refPackage: ReferencePackage
                        }) => {
                            SetReferencePackage(display.refPackage);
                            return <NPCDisplay currentNPC={display.data} />; }}
                        handleData={(data) => {
                            let list: Array<{
                                data: NPCReferenceType,
                                refPackage: ReferencePackage
                            }> = [];

                            data.npcs.forEach((item: any) => {
                                list.push(JSON.parse(item));
                            });

                            return list;
                        }}
                        cardDisplay={(click, list: Array<{
                            data: NPCReferenceType,
                            refPackage: ReferencePackage
                        }>) => {
                            return list.map(npc =>
                                {
                                    if(npc.data === undefined) {
                                        return <></>;
                                    }

                                    let npcData = GetObject(ReferenceType.NPC,  npc.data);
                                    if(npcData === undefined) {
                                        return <></>;
                                    }

                                    SetReferencePackage(npc.refPackage);

                                    return <Col md="auto">
                                        <Card style={{ width: '12rem' }}>
                                            <Card.Body>
                                                <Card.Title>{npcData.Name}</Card.Title>
                                                <Card.Subtitle className="mb-2 text-muted">{npcData.RaceName}</Card.Subtitle>
                                                <Button onClick={() => {
                                                    SetReferencePackage(npc.refPackage);
                                                    //console.log(npc);
                                                    click(npc);
                                                }}>View Statblock</Button>
                                            </Card.Body>
                                        </Card>
                                        <br />
                                    </Col>;
                                }

                            );}}
                        onDelete={RefreshLists}
                    />);
            }

            if(currentTab === 'all' || currentTab === 'buildings') {
                tempDisplaySet.push(
                    <SavableCreation
                        creationName={"Building"}
                        defaultData={{
                            data: DEFAULT_BUILDING_REF,
                            refPackage: referencePackage
                        }}
                        modalDisplay={(display) => {
                            SetReferencePackage(display.refPackage);
                            return <BuildingDisplay building={display.data} />;
                        }} //FIX load npc ids (if exist)
                        handleData={(data) => {
                            let list: Array<{
                                data: BuildingReferenceType,
                                refPackage: ReferencePackage
                            }> = [];

                            data.buildings.forEach((item: any) => {
                                list.push(JSON.parse(item));
                            });

                            return list;
                        }}
                        cardDisplay={(click, list: Array<{
                            data: BuildingReferenceType,
                            refPackage: ReferencePackage
                        }>) => {return list.map(building =>
                            {
                                if(building.data === undefined){
                                    return <></>;
                                }

                                SetReferencePackage(building.refPackage);

                                return <Col md="auto">
                                    <Card style={{ width: '12rem' }}>
                                        <Card.Body>
                                            <Card.Title><ConvertResultsDisplay data={GetObject(ReferenceType.Building,  building.data).locationName} /></Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted"><ConvertResultsDisplay data={GetObject(ReferenceType.Building,  building.data).locationType} /></Card.Subtitle>
                                            <Button onClick={() => {
                                                SetReferencePackage(building.refPackage);
                                                click(building);
                                            }}>View Building</Button>
                                        </Card.Body>
                                    </Card>
                                    <br />
                                </Col>
                            }
                        );}}
                        onDelete={RefreshLists}
                    />);
            }

            if(currentTab === 'all' || currentTab === 'settlements') {
                tempDisplaySet.push(
                    <SavableCreation
                        creationName={"Settlement"}
                        defaultData={{
                            data: DEFAULT_SETTLEMENT_REF,
                            refPackage: referencePackage
                        }}
                        modalDisplay={(display) => {
                            SetReferencePackage(display.refPackage);
                            return <SettlementDisplay settlement={display.data} popup={true} />;
                        }}
                        handleData={(data) => {
                            let list: Array<{
                                data: string,
                                refPackage: ReferencePackage
                            }> = [];

                            data.settlements.forEach((item: any) => {
                                list.push(JSON.parse(item));
                            });

                            return list;
                        }}
                        cardDisplay={(click, list: Array<{
                            data: string,
                            refPackage: ReferencePackage
                        }>) => {return list.map(settlement =>
                            {
                                if(settlement.data === undefined){
                                    return <></>;
                                }

                                SetReferencePackage(settlement.refPackage);

                                let settlementObj: Settlement = GetObject(ReferenceType.Settlement,  settlement.data);

                                if(settlementObj === undefined) {
                                    return <></>;
                                }

                                return <Col md="auto">
                                    <Card style={{ width: '12rem' }}>
                                        <Card.Body>
                                            <Card.Title><ConvertResultsDisplay data={settlementObj.Name} /></Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted">{SettlementSize[settlementObj.Size]}</Card.Subtitle>
                                            <Button onClick={() => {
                                                SetReferencePackage(settlement.refPackage);
                                                click(settlement);
                                            }}>View Settlement</Button>
                                        </Card.Body>
                                    </Card>
                                    <br />
                                </Col>
                            }
                        );}}
                        onDelete={RefreshLists}
                    />);
            }

            if(currentTab === 'all' || currentTab === 'locations') {
                tempDisplaySet.push(
                    <SavableCreation
                        creationName={"Location"}
                        defaultData={{
                            data: DEFAULT_LOCATION_REF,
                            refPackage: referencePackage
                        }}
                        modalDisplay={(display) => {
                            SetReferencePackage(display.refPackage);
                            return <LocationDisplay location={display.data} />;
                        }}
                        handleData={(data) => {
                            let list: Array<{
                                data: LocationReferenceType,
                                refPackage: ReferencePackage
                            }> = [];

                            data.locations.forEach((item: any) => {
                                list.push(JSON.parse(item));
                            });

                            return list;
                        }}
                        cardDisplay={(click, list: Array<{
                            data: LocationReferenceType,
                            refPackage: ReferencePackage
                        }>) => {return list.map(location =>
                            {
                                if(location.data === undefined){
                                    return <></>;
                                }

                                SetReferencePackage(location.refPackage);

                                let previewText = "";

                                let loc = GetObject(ReferenceType.Location,  location.data);

                                if(loc === undefined){
                                    return <></>;
                                }

                                if(loc !== undefined && loc.Desc !== undefined && loc.Desc.length > 0) {
                                    if(loc.Desc[0]!.beforeText !== "") {
                                        previewText = loc.Desc[0]!.beforeText;
                                    }
                                    else {
                                        previewText = loc.Desc[0]!.text;
                                    }

                                    if(previewText.length > 30) {
                                        previewText = previewText.slice(0, 30);
                                    }

                                    previewText = previewText.trim();
                                }

                                let name = loc === undefined ? <>Name</> : <ConvertResultsDisplay data={loc.Name} />;

                                return <Col md="auto">
                                    <Card style={{ width: '12rem' }}>
                                        <Card.Body>
                                            <Card.Title>{name}</Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted">{previewText}</Card.Subtitle>
                                            <Button onClick={() => {
                                                SetReferencePackage(location.refPackage);
                                                click(location);
                                            }}>View Location</Button>
                                        </Card.Body>
                                    </Card>
                                    <br />
                                </Col>
                            }
                        );}}
                        onDelete={RefreshLists}
                    />);
            }

            if(currentTab === 'all' || currentTab === 'resultcomponents') {
                tempDisplaySet.push(
                    <SavableCreation
                        creationName={"ResultComponent"}
                        defaultData={{
                            data: { type: '', component: DEFAULT_RESULT_ARRAY },
                            refPackage: referencePackage
                        }}
                        modalDisplay={(display) => {
                            SetReferencePackage(display.refPackage);
                            return <ConvertResultsDisplay data={display.data.component} />;
                        }}
                        handleData={(data) => {
                            let list: Array<{
                                data: { type: string, component: Array<ResultComponent> },
                                refPackage: ReferencePackage
                            }> = [];

                            data.components.forEach((item: any) => {
                                list.push(JSON.parse(item));
                            });

                            return list;
                        }}
                        cardDisplay={(click, list: Array<{
                            data: { type: string, component: Array<ResultComponent> },
                            refPackage: ReferencePackage
                        }>) => {return list.map(rc => {
                            if(rc.data === undefined) {
                                return <></>;
                            }

                            SetReferencePackage(rc.refPackage);

                            let previewText = "";

                            if(rc.data.component[0]!.beforeText !== "") {
                                previewText = rc.data.component[0]!.beforeText;
                            }
                            else {
                                previewText = rc.data.component[0]!.text;
                            }

                            if(previewText.length > 30) {
                                previewText = previewText.slice(0, 30);
                            }

                            previewText = previewText.trim();

                            return (
                                <Col md="auto">
                                    <Card style={{ width: '12rem' }}>
                                        <Card.Body>
                                            <Card.Title>{rc.data.type}</Card.Title>
                                            <p>{previewText}...</p>
                                            <Button onClick={() => {
                                                click(rc);
                                            }}>View Full</Button>
                                        </Card.Body>
                                    </Card>
                                    <br />
                                </Col>
                            );
                        })}}
                        onDelete={RefreshLists}
                    />);
            }

            if(currentTab === 'all' || currentTab === 'collections') {
                tempDisplaySet.push(
                    <SavableCreation
                        creationName={"Component"}
                        genericComponentTypeName={"collection"}
                        defaultData={{
                            data: DEFAULT_RESULT_COMPONENT_COLLECTION,
                            refPackage: referencePackage
                        }}
                        modalDisplay={(display) => {
                            SetReferencePackage(display.refPackage);
                            return <ResultComponentCollectionDisplay data={display.data} />;
                        }}
                        handleData={(data) => {
                            let list: Array<{
                                data: ResultComponentCollection,
                                refPackage: ReferencePackage
                            }> = [];

                            data.components.forEach((item: any) => {
                                list.push(JSON.parse(item));
                            });

                            return list;
                        }}
                        cardDisplay={(click, list: Array<{
                            data: ResultComponentCollection,
                            refPackage: ReferencePackage
                        }>) => {return list.map(event => {
                            if(event.data === undefined) {
                                return <></>;
                            }

                            SetReferencePackage(event.refPackage);

                            let title = ConvertResults(event.data.Title);

                            let previewText = "";

                            if(event.data.ResultComponent.length === 0) {
                                return <></>;
                            }

                            if(event.data.ResultComponent[0]!.beforeText !== "") {
                                previewText = event.data.ResultComponent[0]!.beforeText;
                            }
                            else {
                                previewText = event.data.ResultComponent[0]!.text;
                            }

                            if(previewText.length > 30) {
                                previewText = previewText.slice(0, 30);
                            }

                            previewText = previewText.trim();

                            return (
                                <Col md="auto">
                                    <Card style={{ width: '12rem' }}>
                                        <Card.Body>
                                            <Card.Title>{title}</Card.Title>
                                            <p>{previewText}</p>
                                            <Button onClick={() => {
                                                click(event);
                                            }}>View Full</Button>
                                        </Card.Body>
                                    </Card>
                                    <br />
                                </Col>
                            );
                        })}}
                        onDelete={RefreshLists}
                    />);
            }

            if(currentTab === 'all' || currentTab === 'organizations') {
                tempDisplaySet.push(
                    <SavableCreation
                        creationName={"Organization"}
                        defaultData={{
                            data: DEFAULT_GROUP_REF,
                            refPackage: referencePackage
                        }}
                        modalDisplay={(display) => {
                            SetReferencePackage(display.refPackage);
                            return <OrganizationDisplay org={display.data} popup={true} />;
                        }}
                        handleData={(data) => {
                            let list: Array<{
                                data: GroupReferenceType,
                                refPackage: ReferencePackage
                            }> = [];

                            data.organizations.forEach((item: any) => {
                                list.push(JSON.parse(item));
                            });

                            return list;
                        }}
                        cardDisplay={(click, list: Array<{
                            data: GroupReferenceType,
                            refPackage: ReferencePackage
                        }>) => {return list.map(organization =>
                            {
                                if(organization.data === undefined) {
                                    return <></>;
                                }

                                SetReferencePackage(organization.refPackage);

                                return <Col md="auto">
                                    <Card style={{ width: '12rem' }}>
                                        <Card.Body>
                                            <Card.Title><ConvertResultsDisplay data={GetObject(ReferenceType.Group,  organization.data).OrgName} /></Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted">{GetObject(ReferenceType.Group,  organization.data).Alignment}, {GetObject(ReferenceType.Group,  organization.data).Type}</Card.Subtitle>
                                            <Button onClick={() => {
                                                SetReferencePackage(organization.refPackage);
                                                click(organization);
                                            }}>View Group</Button>
                                        </Card.Body>
                                    </Card>
                                    <br />
                                </Col>;
                            }
                        );}}
                        onDelete={RefreshLists}
                    />);
            }

            if(currentTab === 'all' || currentTab === 'items') {
                tempDisplaySet.push(
                    <SavableCreation
                        creationName={"Item"}
                        defaultData={{
                            data: DEFAULT_ITEM,
                            refPackage: referencePackage
                        }}
                        modalDisplay={(display) => {
                            SetReferencePackage(display.refPackage);
                            return <ItemDisplay item={display.data} />;
                        }}
                        handleData={(data) => {
                            let list: Array<{
                                data: Item,
                                refPackage: ReferencePackage
                            }> = [];

                            data.items.forEach((item: any) => {
                                list.push(JSON.parse(item));
                            });

                            return list;
                        }}
                        cardDisplay={(click, list: Array<{
                            data: Item,
                            refPackage: ReferencePackage
                        }>) => {return list.map(item =>
                            {
                                if(item.data === undefined) {
                                    return <></>;
                                }

                                SetReferencePackage(item.refPackage);

                                return <Col md="auto">
                                    <Card style={{ width: '12rem' }}>
                                        <Card.Body>
                                            <Card.Title><ConvertResultsDisplay data={item.data.Name} /></Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted">Item</Card.Subtitle>
                                            <Button onClick={() => {
                                                SetReferencePackage(item.refPackage);
                                                click(item);
                                            }}>View Item</Button>
                                        </Card.Body>
                                    </Card>
                                    <br />
                                </Col>;
                            }
                        );}}
                        onDelete={RefreshLists}
                    />);
            }
        }
        catch (error) {
            console.error("Error on refreshing list ", error);
        }

        setTimeout(function () {
            setDisplays(tempDisplaySet);
        }, 1);
    }

    function ClearLists() {
        setDisplays([]);
    }

    useEffect(() => {
        RefreshLists();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        RefreshLists();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab]);

    return(
        <div>
            <Meta pageName="User Creations" desc="Unique User Creations Page" address="https://7tools.dev/creations" />
            <PopupContext.Provider value={{
                types: displayTypes,
                setTypes: setDisplayTypes
            }}>
            <PopupDisplayHandler />

            <h1>Saved Creations</h1>
            <Nav variant="tabs" defaultActiveKey="all" onSelect={ChangeTab}>
                <Nav.Item>
                    <Nav.Link eventKey="all">All Creations</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="monsters">Monsters</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="npcs">NPCs</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="resultcomponents">General Text</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="collections">Text Collections</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="buildings">Buildings</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="locations">Locations</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="organizations">Groups</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="items">Items</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="settlements">Settlements</Nav.Link>
                </Nav.Item>
            </Nav>
            <br />
            <Container>
                <Row>
                    {displays}
                </Row>
            </Container>
            </PopupContext.Provider>
        </div>);
}

export default Creations;
