import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation'
import Card from 'react-bootstrap/Card';
import MonsterGenerator from "../complex/MonsterGenerator";
import Nav from 'react-bootstrap/Nav';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { JsxElement } from 'typescript';
import {ConvertResults, ConvertResultsDisplay, DEFAULT_RESULT_ARRAY} from "../utility/resultcomponents/ResultComponent";
import TemplateGenerator from "../utility/TemplateGenerator";
import {Meta} from "../utility/MetaWrapper";
import {ResultComponentItemDisplay, ResultComponentItemList, ResultComponentList} from "../utility/resultcomponents/ResultComponentItemList";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";

function ItemGenerator() {
    const [itemsWorth, setItemsWorth] = useState('');
    const [listOfItems, setListOfItems] = useState<ResultComponentList>({
        List: []
    });
    
    const [lootSizeOptions, setLootSizeOptions] = useState(['']);
    const [selectedLootSize, setSelectedLootSize] = useState('Random');
    const [selectedLootSizeOptions, setSelectedLootSizeOptions] = useState(['Random']);

    const [lootTypeOptions, setLootTypeOptions] = useState(['']);
    const [selectedLootType, setSelectedLootType] = useState('Treasure');
    const [selectedLootTypeOptions, setSelectedLootTypeOptions] = useState(['Treasure']);

    useEffect(() => {
        UpdateSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLootSizeOptions, selectedLootTypeOptions]);

    function GetSelectedLootType() {
        let result = '';
        for (let i = 0; i < selectedLootTypeOptions.length; i++) {
            result += selectedLootTypeOptions[i];
            if(i !== selectedLootTypeOptions.length - 1) {
                result += ",";
            }
        }

        if(result === '') {
            result = "Treasure";
        }

        return result;
    }

    function GetSelectedLootSize() {
        return selectedLootSizeOptions[Math.floor(Math.random() * selectedLootSizeOptions.length)];
    }

    function UpdateSelected() {
        setSelectedLootSize(GetSelectedLootSize()!);
        setSelectedLootType(GetSelectedLootType()!);
    }

    function UpdateLootSizeList() {
        fetch(URL+"/GetLootSizes",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let items: Array<string> = data;

            setLootSizeOptions(items);
        });
    }

    function UpdateLootTypeList() {
        fetch(URL+"/GetLootTypes",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let items: Array<string> = data;

            setLootTypeOptions(items);
        });
    }

    useEffect(() => {
        UpdateLootSizeList();
        UpdateLootTypeList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function SetData(data: any) {
        setListOfItems(data.data!.value!);
        setItemsWorth(data.data!.totalWorth!);
    }

    const displayedItems = listOfItems.List.map(itemList =>
    {
        return (
            <>
                <ResultComponentItemDisplay itemList={itemList} />
            </>
        );
    });

    const itemList = lootSizeOptions.map(name =>
        <option key={name} value={name}>{name}</option>
    )

    return(
        <div>
            <Meta pageName="Loot Generator" desc="A TTRPG Loot Generator that generates magical and mundane treasure for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/lootgenerator" />
            <TemplateGenerator onGenerateButtonClicked={UpdateSelected} name="Loot" url="GetLoot"
                               customDataHandling={SetData}
                               componentReplacement={
                                   <>
                                       <h3>Worth: {itemsWorth}</h3>
                                       <ul className="alignLeft">
                                           {displayedItems}
                                       </ul>
                                   </>
                               }
                               additionalUrl={"&size=" + selectedLootSize + "&type=" + selectedLootType} settingsComponent={
                <div>
                    <h3>Loot Size</h3>
                    <AdvancedDropdown options={ConvertForDropdown(lootSizeOptions)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedLootSizeOptions} />
                    <br /><br />
                    <AdvancedDropdown options={ConvertForDropdown(lootTypeOptions)} defaultSelectedValue="Treasure" onChangeValue={setSelectedLootTypeOptions} />
                </div>
            } />
        </div>);
}

export default ItemGenerator;
