import React, { useState, useEffect }  from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import URL from './BackendLocation';
import {Helmet} from "react-helmet";
import {Meta} from "./dnd/utility/MetaWrapper";
import {Button} from "react-bootstrap";
import axios from "axios";

function Contact() {
    const [showSent, setShowSent] = useState(false);

    const [email, setEmail] = useState('');
    function UpdateEmail(props: React.ChangeEvent<HTMLInputElement>) {
        setEmail(props.target.value);
    }

    const [tool, setTool] = useState('');
    function UpdateTool(props: React.ChangeEvent<HTMLInputElement>) {
        setTool(props.target.value);
    }

    const [desc, setDesc] = useState('');
    function UpdateDesc(props: React.ChangeEvent<HTMLTextAreaElement>) {
        setDesc(props.target.value);
    }


    function Clear() {
        setEmail('');
        setTool('');
        setDesc('');
    }

    function Submit(e: any) {
        e.preventDefault();

        if(desc.trim() !== "") {
            const addFeedback = async () => {
                const response = await axios.put(URL + '/AddFeedback', {
                    email: email,
                    toolInfo: tool,
                    feedback: desc
                }, {withCredentials: true }).catch((e) => {
                    console.log(e);
                });
            }

            addFeedback().catch((e) => {console.log(e);});

            Clear();

            setShowSent(true);
            const timer = setTimeout(() => {
                setShowSent(false);
            }, 2000);
        }
    }

    return(
        <div>
            <Meta pageName="Contact" desc="Make a suggestion or bug report" address="https://7tools.dev/contact" />
            <h1>Contact</h1>
            <h5>Have a suggestion for a new generator or tool? An idea for more prompts for existing tools? Or just saw a nasty bug you want to inform me of? Do it here!</h5>
            <br/>
            <form id="contact-form">
                <div>
                    Response Email:{' '}
                    <input name="from_email" onChange={UpdateEmail} value={email} />{' '}(Optional)
                </div>
                <br/>
                <div>
                    Relevant Tool:{' '}
                    <input name="tool" onChange={UpdateTool} value={tool}/>{' '}(Optional)
                </div>
                <br />
                Suggestion / Bug Report / Etc
                <br />
                <textarea required name="message" onChange={UpdateDesc} value={desc} className="fill" style={{minHeight: "150px"}} />
                <br />
                <div>
                    <Button onClick={Clear}>Cancel</Button>
                    {' '}
                    <Button type="submit" onClick={Submit}>Submit</Button>
                </div>
                {
                    showSent ?
                        <>
                            <br />
                            <h4>Feedback submitted!</h4>
                        </>
                        :
                        <></>
                }
            </form>
        </div>);
}

export default Contact;
