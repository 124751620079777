import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation';
import {Meta} from "../utility/MetaWrapper";
import TemplateGenerator from "../utility/TemplateGenerator";
import {ConvertResults, ConvertResultsDisplay, DEFAULT_RESULT_ARRAY, StringToResultComponent} from "../utility/resultcomponents/ResultComponent";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";

function OrganizationNameGenerator() {

    const [name, setName] = useState(DEFAULT_RESULT_ARRAY);
    
    const [organizationNames, setOrganizationNames] = useState(['']);
    const [generatedOrganizationType, setGeneratedOrganizationType] = useState('Random');
    const [selectedOrganization, setSelectedOrganization] = useState('Random');
    const [selectedOrganizationOptions, setSelectedOrganizationOptions] = useState(['Random']);
    
    const [alignmentNames, setAlignmentNames] = useState(['']);
    const [generatedAlignmentType, setGeneratedAlignmentType] = useState('Random');
    const [selectedAlignment, setSelectedAlignment] = useState('Random');
    const [selectedAlignmentOptions, setSelectedAlignmentOptions] = useState(['Random']);

    useEffect(() => {
        UpdateSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrganizationOptions]);

    useEffect(() => {
        UpdateSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAlignmentOptions]);

    function GetSelectedLocation() {
        return selectedOrganizationOptions[Math.floor(Math.random() * selectedOrganizationOptions.length)];
    }

    function GetSelectedAlignment() {
        return selectedAlignmentOptions[Math.floor(Math.random() * selectedAlignmentOptions.length)];
    }

    function UpdateSelected() {
        setSelectedOrganization(GetSelectedLocation()!);
        setSelectedAlignment(GetSelectedAlignment()!);
    }

    function UpdateOrganizationList() {
        fetch(URL+"/GetOrganizationTypes",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

                let types: Array<string> = data;

                setOrganizationNames(types);

            });
    }

    function UpdateAlignmentList() {
        fetch(URL+"/GetAlignmentMoralTypes",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let types: Array<string> = data;

            setAlignmentNames(types);

        });
    }


    function ChangeName(data: any) {
        setName(data.name);
        setGeneratedOrganizationType(data.organizationtype);
        setGeneratedAlignmentType(data.alignment);
    }

    useEffect(() => {
        UpdateOrganizationList();
        UpdateAlignmentList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let nameDisplay = ConvertResults(name);

    return(
        <div>
            <Meta pageName="Group Name Generator" desc="A TTRPG Group Name Generator that can be used to generate names for organizations or factions for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/groupnamegenerator" />
            <TemplateGenerator onGenerateButtonClicked={UpdateSelected} hideAdjectivesOption={true} name="Group Name" url="GetOrganizationName" customSaveDisplay={name} additionalUrl={"&organizationtype=" + selectedOrganization + "&alignment=" + selectedAlignment} customDataHandling={ChangeName} settingsComponent={
                <div>
                    <h4>Group Type</h4>
                    <AdvancedDropdown options={ConvertForDropdown(organizationNames)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedOrganizationOptions} />
                    <br />
                    <br />
                    <h4>Group Alignment</h4>
                    <AdvancedDropdown options={ConvertForDropdown(alignmentNames)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedAlignmentOptions} />
                </div>
            } extraComponent={
                <>
                    <p>Group Type: {generatedOrganizationType}</p>
                    <p>Alignment: {generatedAlignmentType}</p>
                </>
            } componentReplacement={
                <p>{nameDisplay}</p>
            }
            />
        </div>);
}

export default OrganizationNameGenerator;
