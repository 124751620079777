import React, {useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import axios from "axios";
import URL from "./BackendLocation";
import {Button, Col, Container, Row} from "react-bootstrap";

function Statistics() {
    const [statsType, setStatsType] = useState('uniquevisitors');
    const [dateRange, setDateRange] = useState('week');
    const [graphData, setGraphData] = useState([{name: '', data: [0]}]);
    const [feedbackData, setFeedbackData] = useState([
        {
            email: '',
            toolInfo: '',
            feedback: ''
        }
    ]);
    const [users, setUsers] = useState(['']);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        BarElement
    );

    function RefreshLanguages() {
        fetch(URL + "/SetupTranslations",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            })
            .then(res => res.json()).then((data) => {

        });
    }

    function GetStatistics(){
        const findStats = async () => {
            const response = await axios.get(URL + '/GetStatistics?time=' + dateRange + "&type=" + statsType, { withCredentials: true }).catch((e) => {
                console.log(e);
            });

            if(response && response.data){
                if(response.data.stats){

                    for(let i = 0; i < response.data.stats.length; i++) {
                        response.data.stats[i].data.reverse();
                    }

                    setGraphData(response.data.stats);
                }
            }
        }

        const findUsers = async () => {
            const response = await axios.get(URL + '/GetAllUsers', { withCredentials: true }).catch((e) => {
                console.log(e);
            });

            if(response && response.data) {
                if(response.data.users) {
                    let val: Array<string> = [];
                    response.data.users.forEach((user:any) => {
                        val.push(user.username);
                    });
                    setUsers(val);
                }
            }
        }

        const getFeedback = async () => {
            const response = await axios.get(URL + '/GetFeedback', { withCredentials: true }).catch((e) => {
                console.log(e);
            });

            if(response && response.data){
                let val: Array<{
                    email: string,
                    toolInfo: string,
                    feedback: string
                }> = [];
                response.data.feedback.forEach((fb: any) => {
                    val.push({
                        email: fb.email,
                        toolInfo: fb.toolInfo,
                        feedback: fb.feedback
                    })
                });

                setFeedbackData(val);
            }
        }

        findStats().catch((e) => {console.log(e);});
        findUsers().catch((e) => {console.log(e);});
        getFeedback().catch((e) => {console.log(e);});
    }

    useEffect(() => {
        GetStatistics();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        GetStatistics();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange]);

    useEffect(() => {
        GetStatistics();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statsType]);

    function setDateRangeInput(selected: React.ChangeEvent<HTMLSelectElement>) {
        setDateRange(selected.target.value);
    }

    function setStatsTypeInput(selected: React.ChangeEvent<HTMLSelectElement>) {
        setStatsType(selected.target.value);
    }

    let labels = [];

    switch (dateRange) {
        case "day":
        case "week":
            for(let i = 0; i < graphData[0]!.data.length; i++){
                if(i === graphData[0]!.data.length - 1){
                    labels.push('Today');
                }
                else{
                    let date = new Date();
                    date.setDate(date.getDate() - (graphData[0]!.data.length - i - 1));
                    labels.push(date.toLocaleDateString());
                }
            }
            break;
        case "month":
            for(let i = 0; i < graphData[0]!.data.length; i++){
                let inverseI = (graphData[0]!.data.length - 1) - i;
                let weekVal = 7;

                let startDate = new Date();
                let startReduce = (weekVal + (weekVal * inverseI));
                startDate.setDate(startDate.getDate() - startReduce + 1);

                let endDate = new Date();
                let endReduce = (weekVal * inverseI)
                endDate.setDate(endDate.getDate() - endReduce);

                labels.push(startDate.toLocaleDateString() + " - " + endDate.toLocaleDateString());
            }
            break;
        case "year":
            for(let i = 0; i < graphData[0]!.data.length; i++){
                let inverseI = (graphData[0]!.data.length - 1) - i;
                let monthVal = 30;

                let startDate = new Date();
                let startReduce = (monthVal + (monthVal * inverseI));
                startDate.setDate(startDate.getDate() - startReduce + 1);

                let endDate = new Date();
                let endReduce = (monthVal * inverseI)
                endDate.setDate(endDate.getDate() - endReduce);

                labels.push(startDate.toLocaleDateString() + " - " + endDate.toLocaleDateString());
            }
            break;
    }


    let colorOptions: Array<string | undefined> = [
        "#02b844",
        "#0ff1ce",
        "#696969",
        "#c0d6e4",
        "#420420",
        "#ffd700",
        "#407294",
        "#065535",
        "#f7347a",
        "#ffa500",
        "#8a2be2",
        "#5ac18e",
        "#faebd7",
        "#ff7f50",
        "#dcedc1",
        "#ffffff"
    ];
    let graphPiecesCount = 0;
    const graphDataPieces = graphData.map(data => {

        let color = colorOptions[graphPiecesCount++] || "#000000";

        return {
            label: data.name,
            data: data.data,
            backgroundColor: color,//colorOptions[graphPiecesCount++],
            borderWidth: 1,
            borderColor: "#000000",
        }
    });

    const graph: { datasets: Array<{ backgroundColor: string; borderColor: string; data: any; borderWidth: number; label: string }>; labels: Array<any> } = {
        labels: labels,
        datasets: graphDataPieces
    }

    const usersDisplay = users.map(user => {
        return (
            <li>{user}</li>
        );
    });

    const feedbackDisplay = feedbackData.map(feedback => {

        function DeleteFB() {
            const deleteFeedback = async () => {
                const response = await axios.put(URL + '/DeleteFeedback', {
                    email: feedback.email,
                    toolInfo: feedback.toolInfo,
                    feedback: feedback.feedback
                }, {withCredentials: true }).catch((e) => {
                    console.log(e);
                });
            }

            deleteFeedback().catch((e) => {console.log(e);});

            const timer = setTimeout(() => {
                GetStatistics();

            }, 500);
        }

        return (
        <>
            <p><b>Email: </b>{feedback.email}</p>
            <p><b>Tool: </b>{feedback.toolInfo}</p>
            <p><b>Feedback: </b>{feedback.feedback}</p>
            <Button onClick={DeleteFB} >Delete Feedback</Button>
        </>)
    })

    return(
        <div>
            <h1>Statistics</h1>
            <Button onClick={GetStatistics}>Refresh</Button>
            <br />
            <br />
            <select onChange={setStatsTypeInput}>
                <option selected value="uniquevisitors">Unique Visitors</option>
                <option value="usedpages">Times Pages Used</option>
                <option value="usedpagesbreakdown">Pages Popularity</option>
            </select>
            <br />
            <br />
            <select onChange={setDateRangeInput}>
                <option value="day">Today</option>
                <option selected value="week">Week</option>
                <option value="month">Month (4 Weeks)</option>
                <option value="year">Year (12 Months)</option>
            </select>
            <Bar data={graph} />
            <Button onClick={RefreshLanguages}>Setup Languages</Button>
            <Container>
                <Row>
                    <Col>
                        <h1>Feedback</h1>
                        {feedbackDisplay}
                    </Col>
                    <Col>
                        <h1>Registered Users ({usersDisplay.length})</h1>
                        <ul>
                            {usersDisplay}
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>);
}

export default Statistics;
