import React, {useState} from "react";
import Table from "react-bootstrap/Table";
import {DisplayStatblock, StatBlockScores} from "../utility/StatBlock"
import {ConvertResults, ResultComponent} from "../utility/resultcomponents/ResultComponent";

export enum EnvironmentType {
    Arctic,
    Coastal,
    Desert,
    Forest,
    Grassland,
    Hill,
    Mountain,
    Swamp,
    Underground,
    Underwater,
    Urban
}

export interface Monster {
    Name: string;
    ChallengeRating: string;
    ProfBonus: number;

    Size: string;
    Type: string;

    Alignment: string;

    ArmorClass: number;
    HitPoints: string;

    Speed: string;

    SavingThrows: string;
    Skills: string;
    DamageVulnerabilities: string;
    DamageResistances: string;
    DamageImmunities: string;

    Languages: string;

    Senses: string;

    Stats: StatBlockScores;

    PassiveAbilities: Array<{ title: string, desc: string }>;

    ActionAbilities: Array<{ title: string, desc: string }>;

    Environments: Array<EnvironmentType>;
    Appearance: Array<ResultComponent>;
    Description: Array<ResultComponent>;
}

export const DEFAULT_MONSTER: Monster = {
    Name: '',
    ChallengeRating: '',
    ProfBonus: 2,
    Size: '',
    Type: '',
    Alignment: '',
    ArmorClass: 10,
    HitPoints: '',
    SavingThrows: '',
    Skills: '',
    Senses: '',
    DamageVulnerabilities: '',
    DamageResistances: '',
    DamageImmunities: '',
    Languages: '',
    Speed: '30ft.',
    Stats: {
        Stat_Strength: 10,
        Stat_Dexterity: 10,
        Stat_Constitution: 10,
        Stat_Intelligence: 10,
        Stat_Wisdom: 10,
        Stat_Charisma: 10
    },
    PassiveAbilities: [{ title: '', desc: '' }],
    ActionAbilities: [{ title: '', desc: '' }],
    Environments: [],
    Appearance: [],
    Description: [],
};

function MonsterDisplay(props: {currentMonster: Monster}) {

    const currentMonster = props.currentMonster;

    function getOptionals() {
        let result: Array<JSX.Element> = [];

        if (currentMonster.SavingThrows !== '') {
            result.push(<p><b>Saving Throws</b> {currentMonster.SavingThrows}</p>);
        }

        if (currentMonster.Skills !== '') {
            result.push(<p><b>Skills</b> {currentMonster.Skills}</p>);
        }

        if (currentMonster.DamageVulnerabilities !== '') {
            result.push(<p><b>Damage Vulnerabilities</b> {currentMonster.DamageVulnerabilities}</p>);
        }

        if (currentMonster.DamageResistances !== '') {
            result.push(<p><b>Damage Resistances</b> {currentMonster.DamageResistances}</p>);
        }

        if (currentMonster.DamageImmunities !== '') {
            result.push(<p><b>Damage Immunities</b> {currentMonster.DamageImmunities}</p>);
        }

        return result;
    }

    let optionals = getOptionals();

    let passiveAbilities = currentMonster.PassiveAbilities.map(item =>
        <p key={item.title}><b>{item.title}</b> {item.desc}</p>
    );

    let actionAbilities = currentMonster.ActionAbilities.map(item =>
        <p key={item.title}><b>{item.title}</b> {item.desc}</p>
    );

    let environments: Array<JSX.Element> = [];
    if(currentMonster.Environments !== undefined) {
        environments = currentMonster.Environments.map(env =>
            <>{EnvironmentType[env]}, </>
        );
    }

    let appearance = currentMonster.Appearance === undefined ? undefined : ConvertResults(currentMonster.Appearance);
    let desc = currentMonster.Description === undefined ? undefined : ConvertResults(currentMonster.Description);

    return(<div>
            <h1>{currentMonster.Name}</h1>
            <p>{currentMonster.Size} {currentMonster.Type}, {currentMonster.Alignment}</p>
            <hr />
            <p><b>Armor Class</b> {currentMonster.ArmorClass}</p>
            <p><b>Hit Points</b> {currentMonster.HitPoints}</p>
            <p><b>Speed</b> {currentMonster.Speed}</p>
            <hr />
            <DisplayStatblock Stats={currentMonster.Stats} />
            {optionals}
            <p><b>Senses</b> {currentMonster.Senses}</p>
            <p><b>Languages</b> {currentMonster.Languages}</p>
            <p><b>Challenge</b> {currentMonster.ChallengeRating}        <b>Proficiency Bonus</b> +{currentMonster.ProfBonus}</p>
        {
            currentMonster.Environments === undefined ? <></> :
                <p><b>Environments </b>{environments}</p>
        }
            <hr />
            {passiveAbilities}
        {
            passiveAbilities.length > 0 ?
                <hr /> : <></>
        }
            <h2>Actions</h2>
            {actionAbilities}


        {
            appearance === undefined ?
                <></> :
                <>
                    <hr />
                    <h2>Appearance</h2>
                    {appearance}
                </>
        }
        {
            desc === undefined ?
                <></> :
                <>
                    <hr />
                    <h2>Description</h2>
                    {desc}
                </>
        }
        </div>);
}

export default MonsterDisplay;
