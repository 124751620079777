import React, { useState, useEffect }  from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import URL from './BackendLocation';
import {Helmet} from "react-helmet";
import {Meta} from "./dnd/utility/MetaWrapper";

function Changelog(props: { onlyShow?: number, hideTitle?: boolean }) {
    interface ChangelogEntry {
        version: string;
        dateUpdated: string;
        changes: Array<string>;
    }

    const [log, setLog] = useState([{version: '', dateUpdated: '', changes: ['']}]);

    function GetChangelog() {
        fetch(URL + "/Changelog",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

                let logs: Array<ChangelogEntry> = data;

                logs.reverse();

                if(props.onlyShow !== undefined) {
                    while (logs.length > props.onlyShow && logs.length > 0) {
                        logs.pop();
                    }
                }

                setLog(logs);
            });
    }


    useEffect(() => {
        GetChangelog();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changelogValues = log.map(logValue => {
        let changes = logValue.changes.map(change => <ListGroup.Item>{change}</ListGroup.Item>);

        return (<div key={logValue.version}>
            <ListGroup>
                <h2>Version {logValue.version}</h2>
                <p>Date {logValue.dateUpdated}</p>
                {changes}
            </ListGroup>
        </div>);
    })

    return(
        <div>
            <Meta pageName="Changelog" desc="Check out all the recent changes to 7Tools" address="https://7tools.dev/changelog" />
            {
                props.hideTitle !== undefined && props.hideTitle === true ?
                    <></> :
                    <h1>Changelog</h1>
            }
            <hr />
            {changelogValues}
        </div>);
}

export default Changelog;
