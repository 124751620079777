import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import URL from './BackendLocation';
import {Meta} from "./dnd/utility/MetaWrapper";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {useIsLoggedIn} from "./hooks/loginHook";
import axios from "axios";
import AdvancedDropdown, {ConvertForDropdown} from "./dnd/utility/AdvancedDropdown";
import {GenSettings} from "./dnd/utility/architecture/GenSettings";
import Modal from "react-bootstrap/Modal";


function UserPage() {
    const loggedIn = useIsLoggedIn();
    const [userName, setUserName] = useState('');

    const [raceNames, setRaceNames] = useState(['']);
    const [settingProfiles, setSettingsProfiles] = useState(['Default']);
    const [settingProfilesMapped, setSettingProfilesMapped] = useState<Map<string, GenSettings>>(new Map<string, GenSettings>());

    const [currentSelection, setCurrentSelection] = useState('Default');
    const [useAdjectives, setUseAdjectives] = useState(true);
    const [enabledRaces, setEnabledRaces] = useState(raceNames);

    const [saveProfileName, setSavedProfileName] = useState('');
    const [savePromptOpen, setSavePromptOpen] = useState(false);
    const handleShowPrompt = () => {
        setSavedProfileName('');
        setSavePromptOpen(true);
    };
    const handleHidePrompt = () => { setSavedProfileName(''); setSavePromptOpen(false); }

    function setUseAdjectivesInput(selected: React.ChangeEvent<HTMLInputElement>) {
        setUseAdjectives(!useAdjectives);
    }

    useEffect(() => {
        if(loggedIn){
            FindUserName();
        }

    }, [loggedIn]);

    function UpdateSettingProfiles() {
        const getSavedCreation = async () => {
            const response = await axios.get(URL + '/GetSavedGenerics', { withCredentials: true, params: { typename: 'settings' } }).catch((e) => {
                console.log(e);
            });

            if(response && response.data){
                let dataMap = new Map<string, GenSettings>();
                let list: Array<string> = ['Default'];
                for(let i = 0; i < response.data.components.length; i++) {
                    let val = JSON.parse(response.data.components[i]);
                    list.push(val.configName);
                    dataMap.set(val.configName, val.data);
                }

                // setCurrentSelection('Default');

                setSettingsProfiles(list);
                setSettingProfilesMapped(dataMap);
            }
        }

        getSavedCreation().catch((e) => {console.log(e);});
    }

    function FindUserName(){
        const findUserNameFetch = async () => {
            const response = await axios.get(URL + '/GetUserName', { withCredentials: true }).catch((e) => {
                console.log(e);
            });

            if(response && response.data){
                setUserName(response.data.name);
            }
        }

        findUserNameFetch().catch((e) => {console.log(e);});
    }

    function UpdateCurrentSelection() {
        if(currentSelection === 'Default') {
            setUseAdjectives(true);
            setEnabledRaces(raceNames);
        }
        else {
            let settings: GenSettings = settingProfilesMapped.get(currentSelection)!;
            if(settings === undefined) {
                setCurrentSelection("Default");
            }
            else {
                setUseAdjectives(settings.useAdjectives);
                setEnabledRaces(settings.globalRaceOptions);
            }
        }


    }

    function UpdateRaceList() {
        fetch(URL+"/Races",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let names: Array<string> = data;

            setRaceNames(names);
        });
    }

    useEffect(() => {
        UpdateRaceList();
        UpdateSettingProfiles();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        UpdateSettingProfiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelection, raceNames]);

    useEffect(() => {

        UpdateCurrentSelection();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingProfiles]);

    function SetRacesSelected(value: Array<string>) {
        setEnabledRaces(value);
    }

    function RaceDropdown(stringArray: Array<string>): Array<{value: string, displayText: string, defaultValue: boolean}> {
        return stringArray.map(string => { return {value: string, displayText: string, defaultValue: true}});
    }

    function setProfile(selected: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentSelection(selected.target.value);
    }
    let settingProfileOptions = settingProfiles.map((name, index) => <option value={name}>{name}</option>);

    function SetSavedProfileNameInput(props: React.ChangeEvent<HTMLInputElement>) {
        setSavedProfileName(props.target.value);
    }

    function DeleteProfile() {
        const deleteConfig = async () => {
            const response = await axios.put(URL + '/DeleteSavedGeneric?typename=settings', { genericdata: {configName: currentSelection, data: settingProfilesMapped.get(currentSelection)} }, {withCredentials: true }).catch((e) => {
                console.log(e);
            });

            if(response && response.data){

            }
        }

        deleteConfig().catch((e) => {console.log(e);});
        const timeout = setTimeout(() => {
            UpdateSettingProfiles();
            setCurrentSelection('Default');
        }, 100);
    }

    function UpdateCurrentProfile() {
        const saveConfig = async () => {
            let settingsConfig: GenSettings = {
                useAdjectives: useAdjectives,
                globalRaceOptions: enabledRaces,
                globalEnvironmentOptions: []
            }

            const response = await axios.put(URL + '/SaveGeneric?typename=settings', { genericdata: {configName: currentSelection, data: settingsConfig} }, {withCredentials: true }).catch((e) => {
                console.log(e);
            });

            if(response && response.data){

            }
        }

        saveConfig().catch((e) => {console.log(e);});
    }

    function SaveCurrentProfile(){
        if(saveProfileName.trim() === '' || saveProfileName.trim() === 'Default') {
            return;
        }

        const saveConfig = async () => {
            let settingsConfig: GenSettings = {
                useAdjectives: useAdjectives,
                globalRaceOptions: enabledRaces,
                globalEnvironmentOptions: []
            }

            const response = await axios.put(URL + '/SaveGeneric?typename=settings', { genericdata: {configName: saveProfileName, data: settingsConfig} }, {withCredentials: true }).catch((e) => {
                console.log(e);
            });

            if(response && response.data){

            }
        }

        saveConfig().catch((e) => {console.log(e);});

        handleHidePrompt();
        const timeout = setTimeout(() => {
            UpdateSettingProfiles();
        }, 100);
        const timeout2 = setTimeout(() => {
            setCurrentSelection(saveProfileName)
        }, 300);
    }

    return (
        <div>
            <Meta pageName="User Page" desc="User Page" address="https://7tools.dev/login" />
            <Modal show={savePromptOpen} onHide={handleHidePrompt}>
                <Modal.Header closeButton>
                    <Modal.Title>Save Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Insert Profile Name&nbsp;&nbsp;
                    <input value={saveProfileName} onChange={SetSavedProfileNameInput} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleHidePrompt}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={SaveCurrentProfile}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <h1>Hello, {userName}</h1>
            <Link to="/logout/google"><Button>Logout</Button></Link>
            <br />
            <br />
            <br />
            <h1>Custom Settings</h1>
            <br />
            <Button onClick={handleShowPrompt} >Save as New Profile</Button>
            {'  '}
            <Button onClick={UpdateCurrentProfile} disabled={currentSelection === 'Default'} >Update Profile</Button>
            <br />
            <br />
            <b>Current Profile </b>
            <select value={currentSelection} onChange={setProfile}>
                {settingProfileOptions}
            </select>
            <br />
            <br />

            <label>
                Use Adjectives:
                <input
                    className="checkbox"
                    name="isGoing"
                    type="checkbox"
                    checked={useAdjectives}
                    onChange={setUseAdjectivesInput}
                />
            </label>
            <h4>Heritage</h4>
            <AdvancedDropdown options={ConvertForDropdown(raceNames)} forceSetOptions={enabledRaces} onChangeValue={SetRacesSelected} lockOpen />
            <br />
            <Button variant="danger" disabled={currentSelection === 'Default'} onClick={DeleteProfile} >Delete Profile</Button>
        </div>);
}

export default UserPage;
