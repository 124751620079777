import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import URL from '../../BackendLocation';
import Modal from 'react-bootstrap/Modal'
import {useIsLoggedIn} from "../../hooks/loginHook";
import axios from "axios";
import MonsterDisplay, {DEFAULT_MONSTER, Monster} from "./MonsterDisplay";
import {Meta} from "../utility/MetaWrapper";
import {SaveToProfileButton} from "../utility/saving/SaveToProfile";
import TemplateGenerator from "../utility/TemplateGenerator";
import AdvancedDropdown, {ConvertForDropdown} from "../utility/AdvancedDropdown";
import OrganizationDisplay from "./OrganizationDisplay";

function MonsterGenerator() {


    const [currentMonster, setCurrentMonster] = useState<Monster>(
        DEFAULT_MONSTER);

    const [selectedCR, setSelectedCR] = useState(-1);
    const [saveMonsterName, setSavedMonsterName] = useState('');
    const [savePromptOpen, setSavePromptOpen] = useState(false);
    const handleShowPrompt = () => {
        setSavedMonsterName(currentMonster.Name);
        setSavePromptOpen(true);
    };
    const handleHidePrompt = () => { setSavedMonsterName(''); setSavePromptOpen(false); }

    const [monsterTypes, setMonsterTypes] = useState(['']);
    const [selectedMonsterType, setSelectedMonsterType] = useState('Random');
    const [selectedMonsterTypeOptions, setSelectedMonsterTypeOptions] = useState(['Random']);

    function UpdateMonsterTypeList() {
        fetch(URL+"/GetCreatureTypes",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json()).then((data) => {

            let types: Array<string> = data;

            setMonsterTypes(types);

        });
    }

    useEffect(() => {
        UpdateSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonsterTypeOptions]);

    useEffect(() => {
        UpdateMonsterTypeList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function SetData(data: any) {
        setCurrentMonster(data);
    }

    // function GenerateMonster() {
    //     fetch(URL+"/GetMonster?cr=" + selectedCR,
    //         {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         })
    //         .then(res => res.json()).then((data: Monster) => {
    //
    //             setCurrentMonster(data);
    //
    //         });
    // }

    // useEffect(() => {
    //
    //     GenerateMonster();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    function setCR(selected: React.ChangeEvent<HTMLSelectElement>) {
        setSelectedCR(parseFloat(selected.target.value))
    }
    function SetSavedMonsterNameInput(props: React.ChangeEvent<HTMLInputElement>) {
        setSavedMonsterName(props.target.value);
    }

    function SaveCurrentMonsterToProfile(){
        // if(saveMonsterName === '') {
        //     return;
        // }
        //
        // currentMonster.Name = saveMonsterName;

        const saveMonster = async () => {
            const response = await axios.put(URL + '/SaveMonster', { monster: currentMonster }, {withCredentials: true }).catch((e) => {
                console.log(e);
            });

            if(response && response.data){

            }
        }

        saveMonster().catch((e) => {console.log(e);});

        handleHidePrompt();
    }

    function GetSelectedMonsterType() {
        return selectedMonsterTypeOptions[Math.floor(Math.random() * selectedMonsterTypeOptions.length)];
    }

    function UpdateSelected() {
        setSelectedMonsterType(GetSelectedMonsterType()!);
    }


    return(
        <div>
            <Meta pageName="Monster Generator" desc="A TTRPG Monster Generator that creates unique homebrew monsters, including statblocks, descriptions, and information about their activities for inspiration in any tabletop roleplaying games, such as Pathfinder or DnD." address="https://7tools.dev/monstergenerator" />
            <TemplateGenerator onGenerateButtonClicked={UpdateSelected} customSaveData={SaveCurrentMonsterToProfile} name="Monster" url="GetMonster" additionalUrl={"&cr=" + selectedCR + "&type=" + selectedMonsterType} customDataHandling={SetData} settingsComponent={
                <div>
                    <h4>Monster Type</h4>
                    <AdvancedDropdown options={ConvertForDropdown(monsterTypes)} randomOption={{value: "Random", displayText: "Random"}} defaultSelectedValue="Random" onChangeValue={setSelectedMonsterTypeOptions} />

                    <h4>CR</h4>
                    <select onChange={setCR}>
                        <option value="-1">Random</option>
                        <option value="0">0</option>
                        <option value="0.125">1/8</option>
                        <option value="0.25">1/4</option>
                        <option value="0.5">1/2</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                    </select>
                </div>
            }  componentReplacement={
                <MonsterDisplay currentMonster={currentMonster} />
            }
            />
        </div>);

    // return(
    //     <div>
    //         <Modal show={savePromptOpen} onHide={handleHidePrompt}>
    //             <Modal.Header closeButton>
    //                 <Modal.Title>Save Monster</Modal.Title>
    //             </Modal.Header>
    //             <Modal.Body>
    //                 Insert Monster Name&nbsp;&nbsp;
    //                 <input value={saveMonsterName} onChange={SetSavedMonsterNameInput} />
    //             </Modal.Body>
    //             <Modal.Footer>
    //                 <Button variant="secondary" onClick={handleHidePrompt}>
    //                     Cancel
    //                 </Button>
    //                 <Button variant="primary" onClick={SaveCurrentMonsterToProfile}>
    //                     Save
    //                 </Button>
    //             </Modal.Footer>
    //         </Modal>
    //
    //         <h1>Monster Generator</h1>
    //         <br />
    //         <br />
    //         <span>
    //             <Button onClick={GenerateMonster}>Generate New Monster</Button>
    //             <SaveToProfileButton onClick={handleShowPrompt} />
    //         </span>
    //         <br />
    //         <br />
    //     </div>);
}

export default MonsterGenerator;
